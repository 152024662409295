/* comman  font */

:root {
    /* Colors: */
    --unnamed-color-717171: #717171;
    --unnamed-color-ffffff: #ffffff;
    --unnamed-color-5367f9: #5367f9;
    --unnamed-color-000000: #000000;

    /* Font/text values */
    --unnamed-font-family-poppins: Poppins;
    --unnamed-font-family-rubik: Rubik;
    --unnamed-font-style-normal: normal;
    --unnamed-font-weight-normal: normal;
    --unnamed-font-weight-medium: medium;
    --unnamed-font-size-16: 16px;
    --unnamed-font-size-17: 17px;
    --unnamed-font-size-21: 21px;
    --unnamed-font-size-22: 22px;
    --unnamed-character-spacing-0: 0px;
    --unnamed-line-spacing-31: 31px;
    --unnamed-line-spacing-32: 32px;
    --unnamed-line-spacing-38: 38px;
    --unnamed-line-spacing-40: 40px;
}

/* Character Styles */
.unnamed-character-style-1 {
    font-family: var(--unnamed-font-family-rubik);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-medium);
    font-size: var(--unnamed-font-size-22);
    line-height: var(--unnamed-line-spacing-32);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-717171);
}
.unnamed-character-style-2 {
    font-family: var(--unnamed-font-family-poppins);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-17);
    line-height: var(--unnamed-line-spacing-40);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
}
.smallfont {
    font-family: var(--unnamed-font-family-rubik);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-medium);
    font-size: 20px;

    color: var(--unnamed-color-000000);
}
.Btnfont {
    font-family: var(--unnamed-font-family-poppins);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-16);
    line-height: var(--unnamed-line-spacing-38);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var( --unnamed-color-ffffff);

    width: 15%;
    height: 30px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.unnamed-character-style-5 {
    color: var(--unnamed-color-717171);
    text-align: left;
    font: normal normal normal 20px/28px Poppins;
    letter-spacing: 0px;

    opacity: 1;
}

.unnamed-character-style-6 {
    color: var(--unnamed-color-000000);
    text-align: left;
    font: normal normal normal 15px/25px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}












.boxshadowB{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
   
}

.boxshadowmin{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


.custom-gaprow {
    gap: 10px; /* Adjust based on your needs */
  }
  
.font16{
     color: #000000;
     font-weight: 800;
}

.font10{
    color: #000000;
   font-weight: 300;
   font-size: 5px;

}



.arrow{
     color: #5AAE25;
}
.aiheading{
     background-color:  #DBDFFF;
      color: #5891F3;
}



.placeholder-sizexyz::placeholder {
    font-size: 16px; /* Change this value to adjust size */
    color: gray; /* Optional: Change the color */
  }


 .btn123{
background: transparent linear-gradient(73deg, #5367F9 0%, #599DF2 100%) 0% 0% no-repeat padding-box;
border-radius: 17px;
color: #ffffff;
  }


  .bg123{
background: #F5F5F5 0% 0% no-repeat padding-box;
border-radius: 18px;
opacity: 1;
  }