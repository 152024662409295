/* ******* Soft Corner ******* */

/* ***** Onboarding ***** */

/* *** Onboarding User *** */

.onboardingUser {
  height: 100vh;
  width: 100vw;

  /* background-image: url('/images/onboarding-sc.svg'); */
  /* background-size: 875px 886px;
  background-size: 50% 100%; */
  background-repeat: no-repeat;
  overflow: hidden;
}

.mt-100 {
  margin-top: 100px;
}

.fs-15 {
  font-size: 15px !important;
}

.cb-post-head-name {
  font-size: 15px;
  font-weight: bold;
  text-transform: capitalize;
  margin-right: 5px;
  color: black;
}


.confirm_partner {
  background-color: #FF5C7F;
  color: snow;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
}

.trending_info {
  background-color: #f01e4c;
  padding: 10px 30px;
  color: snow;
  font-size: 12px;
  display: flex;
}


.confirm_partner:hover {
  background-color: #ee315a;
}

.bg_sc{
  background-color: #f02b56;
}

.cancel_partner {
  background-color: white;
  padding: 5px 10px;
  color: #FF5C7F;
  border: 1px solid #FF5C7F;
  border-radius: 5px;
}

.onboardingUser-area {
  height: 100%;
  width: 50%;
  float: right;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingUser-area-emilo-logo {
  margin: 75px 0px;
}

.onboardingUser-area-softcorner-logo {
  border-radius: 25px;
  margin: 0px 0px 55px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingUser-area-softcorner-logo img,
h3 {
  margin: 15px;
}

.fp_eye {
  position: absolute;
  right: 65px;
  top: 5px;
  background-color: white;
  padding: 5px;
}

.fg_input input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.fg_input button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}


.onboardingUser-area-data-learn {
  font-size: 13px;
  padding: 10px;
  line-height: 17px;
}

.onboardingUser-area-data-learnMore {
  color: #2676E1;
}

.onboardingUser-area-data-user {
  padding: 5px 10px;
  border-radius: 30px;
  box-shadow: 3px 5px 10px #00000010;
  margin: 30px 0px;
  display: flex;
  /* justify-content: start; */
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.onboardingUser-area-data-userName {
  /* border: 1px solid black; */
  width: 75%;
  margin: 0px 10px 0px 15px;
}

.onboardingUser-area-data-userName h4 {
  text-align: left;
}

.onboardingUser-area-data-userName p {
  text-align: left;
}

.onboardingUser-area-data-uploadImage {
  border: 1px solid #5595e9;
  border-radius: 10px;
  font-size: 13px;
  /* padding: 7px 38px; */
  line-height: 17px;
  margin: 10px 0px;
  text-align: center;
}

.onboardingUser-area-data-termsAndConditions {
  margin: 40px 0px;
}

.onboardingUser-area-data-termsAndConditions label {
  font-size: 13px;
  margin: 0px 0px 0px 10px;
}

.onboardingUser-area-data-tac {
  color: #2676E1;
}

.onboardingUser-area-btn {
  border-radius: 31px;
  color: white;
  background-color: #64A6F0;
  padding: 10px;
}


/* *** Onboarding Mobile *** */

.onboardingMobile {
  height: 100vh;
  width: 100vw;

  background-image: url('/images/onboarding-sc.svg');
  /* background-size: 875px 886px;
  background-size: 50% 100%; */
  background-repeat: no-repeat;
}

.onboardingMobile-area {
  height: 100%;
  width: 50%;
  float: right;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingMobile-area-emilo-logo {
  margin: 75px 0px;
}

.onboardingMobile-area-softcorner-logo {
  box-shadow: 0px 3px 6px #0000001A;
  border-radius: 25px;
  margin: 0px 0px 55px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingMobile-area-softcorner-logo img,
h3 {
  margin: 15px;
}

.onboardingMobile-area-data {
  width: 325px;
  margin: 0px 0px 75px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingMobile-area-data-head {
  text-align: center;
}

.onboardingMobile-area-data-input {
  margin: 75px 0px 30px 0px;
}

.onboardingMobile-area-data-input form {
  display: flex;
}

.onboardingMobile-area-data-input form label {
  margin: 0px;
}

.onboardingMobile-area-data-input form input {
  height: 44px;
  border: 1px solid #EBEBEB;
  padding: 0px 0px 0px 5px;
}

.onboardingMobile-area-data-input-in {
  border: 1px solid #EBEBEB;
  /* padding: 9px 3px; */
  border-radius: 10px 0px 0px 10px;
}

.onboardingMobile-area-data-input-sendOTP {
  border: 1px solid #EBEBEB;
  color: #2676E1;
  padding: 8px 4px;
  border-radius: 0px 10px 10px 0px;
  cursor: pointer;
}

.onboardingMobile-area-data-message {
  font-size: 15px;
}

.onboardingMobile-area-btn {
  height: 55px;
  width: 325px;
  border-radius: 31px;
  color: white;
  background-color: #64A6F0;
}


/* *** Onboarding OTP *** */

.onboardingOTP {
  height: 100vh;
  width: 100vw;

  background-image: url('/images/onboarding-sc.svg');
  /* background-size: 50% 100%; */
  background-repeat: no-repeat;
}

.onboardingOTP-area {
  height: 100%;
  width: 50%;
  float: right;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingOTP-area-emilo-logo {
  margin: 75px 0px;
}

.onboardingOTP-area-softcorner-logo {
  box-shadow: 0px 3px 6px #0000001A;
  border-radius: 25px;
  margin: 0px 0px 55px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingOTP-area-softcorner-logo img,
h3 {
  margin: 15px;
}

.onboardingOTP-area-data {
  width: 325px;
  margin: 0px 0px 60px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingOTP-area-data-head {
  text-align: center;
  margin: 0px 0px 50px 0px;
}

.onboardingOTP-area-data-message {
  font-size: 15px;
  margin: 0px 0px 40px 0px;
}

.onboardingOTP-area-data-inputOTP {
  height: 60px;
  width: 60px;
  border: 1px solid #EBEBEB;
  border-radius: 23px;
  font-size: 25px;
  font-weight: 250;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

}

.onboardingOTP-area-btn {
  height: 55px;
  width: 325px;
  border-radius: 31px;
  color: white;
  background-color: #64A6F0;
  margin: 0px 0px 20px 0px;
}

.onboardingOTP-area-resendOTP {
  text-align: center;
  font-size: 14px
}

.onboardingOTP-area-resendOTP span {
  color: #2676E1;
  cursor: pointer;
}


/* *** Onboarding Image *** */

.onboardingImage {
  height: 100vh;
  width: 100vw;
  background-image: url('/images/onboarding-sc.svg');
  /* background-size: 50% 100%; */
  background-repeat: no-repeat;
}

.onboardingImage-area {
  height: 100%;
  width: 50%;
  float: right;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingImage-area-emilo-logo {
  margin: 75px 0px;
}

.onboardingImage-area-softcorner-logo {
  box-shadow: 0px 3px 6px #0000001A;
  border-radius: 25px;
  margin: 0px 0px 55px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingImage-area-softcorner-logo img,
h3 {
  margin: 15px;
}

.onboardingImage-area-data {
  width: 325px;
  margin: 0px 0px 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingImage-area-data-head {
  text-align: center;
  margin: 0px 0px 25px 0px;
}

.onboardingImage-area-data-message {
  font-size: 13px;
  margin: 0px 0px 20px 0px;
  text-align: center;
}

.onboardingImage-area-btn {
  height: 55px;
  width: 325px;
  border-radius: 31px;
  color: white;
  background-color: #64A6F0;
  margin: 0px 0px 20px 0px;
}


/* *** Onboarding Gender *** */

.onboardingGender {
  height: 100vh;
  width: 100vw;
  background-image: url('/images/onboarding-sc.svg');
  /* background-size: 50% 100%; */
  background-repeat: no-repeat;
}

.onboardingGender-area {
  height: 100%;
  width: 50%;
  float: right;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingGender-area-emilo-logo {
  margin: 75px 0px;
}

.onboardingGender-area-softcorner-logo {
  box-shadow: 0px 3px 6px #0000001A;
  border-radius: 25px;
  margin: 0px 0px 55px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingGender-area-softcorner-logo img,
h3 {
  margin: 15px;
}

.onboardingGender-area-data {
  width: 325px;
  margin: 0px 0px 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingGender-area-data-head {
  text-align: center;
  margin: 0px 0px 25px 0px;
}

.onboardingGender-area-data-message {
  font-size: 13px;
  margin: 0px 0px 20px 0px;
  text-align: center;
}

.onboardingGender-area-data form input {
  margin: 0px 15px 0px 0px;
}

.onboardingGender-area-data-input {
  border: 1px solid #EBEBEB;
  color: #696969;
  width: 300px;
  padding: 10px 0px 5px 30px;
  border-radius: 30px;
  margin: 20px;
}

.onboardingGender-area-btn {
  height: 55px;
  width: 325px;
  border-radius: 31px;
  color: white;
  background-color: #64A6F0;
  margin: 0px 0px 20px 0px;
}


/* *** Onboarding Date of Birth *** */

.onboardingDOB {
  height: 100vh;
  width: 100vw;
  background-image: url('/images/onboarding-sc.svg');
  /* background-size: 50% 100%; */
  background-repeat: no-repeat;
}

.onboardingDOB-area {
  height: 100%;
  width: 50%;
  float: right;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingDOB-area-emilo-logo {
  margin: 100px 0px;
}

.onboardingDOB-area-softcorner-logo {
  box-shadow: 0px 3px 6px #0000001A;
  border-radius: 25px;
  margin: 0px 0px 55px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingDOB-area-softcorner-logo img,
h3 {
  margin: 15px;
}

.onboardingDOB-area-data {
  width: 325px;
  margin: 0px 0px 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingDOB-area-data-head {
  text-align: center;
  margin: 0px 0px 25px 0px;
}

.onboardingDOB-area-data-message {
  font-size: 15px;
  margin: 0px 0px 15px 0px;
  text-align: center;
}

.onboardingDOB-area-data form input {
  padding: 15px 50px;
  border-radius: 35px;
  border: 1px solid #EBEBEB;
  margin: 30px 0px 30px 0px;
}

.onboardingDOB-area-btn {
  height: 55px;
  width: 325px;
  border-radius: 31px;
  color: white;
  background-color: #64A6F0;
  margin: 0px 0px 20px 0px;
}


/* *** Onboarding Create Pin *** */

.onboardingPin {
  height: 100vh;
  width: 100vw;
  background-image: url('/images/onboarding-sc.svg');
  /* background-size: 50% 100%; */
  background-repeat: no-repeat;
}

.onboardingPin-area {
  height: 100%;
  width: 50%;
  float: right;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingPin-area-emilo-logo {
  margin: 100px 0px;
}

.onboardingPin-area-softcorner-logo {
  box-shadow: 0px 3px 6px #0000001A;
  border-radius: 25px;
  margin: 0px 0px 55px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingPin-area-softcorner-logo img,
h3 {
  margin: 15px;
}

.onboardingPin-area-data {
  width: 325px;
  margin: 0px 0px 60px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingPin-area-data-head {
  text-align: center;
  margin: 0px 0px 10px 0px;
}

.onboardingPin-area-data-message {
  font-size: 15px;
  margin: 0px 0px 50px 0px;
}

.onboardingPin-area-data-pin {
  height: 60px;
  width: 60px;
  border: 1px solid #EBEBEB;
  border-radius: 23px;
  font-size: 25px;
  font-weight: 250;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.onboardingPin-area-btn {
  height: 55px;
  width: 325px;
  border-radius: 31px;
  color: white;
  background-color: #64A6F0;
  margin: 0px 0px 20px 0px;
}


/* ***** Check Soft Corner Pin ***** */

.checkSoftCornerPin {
  height: 100vh;
  width: 100vw;

  background-image: url('/Images/SoftCorner/onboarding-1.png'), url('/Images/SoftCorner/onboarding-1.png');
  background-size: 50% 100%;
  background-repeat: no-repeat;
}

.checkSoftCornerPin-area {
  height: 100%;
  width: 50%;
  float: right;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkSoftCornerPin-area-emilo-logo {
  margin: 100px 0px;
}

.checkSoftCornerPin-area-softcorner-logo {
  box-shadow: 0px 3px 6px #0000001A;
  border-radius: 25px;
  margin: 0px 0px 55px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkSoftCornerPin-area-softcorner-logo img,
h3 {
  margin: 15px;
}

.checkSoftCornerPin-area-data {
  width: 325px;
  margin: 0px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkSoftCornerPin-area-data-head {
  text-align: center;
  margin: 0px 0px 10px 0px;
  font-size: 34px;
}

.terms-header {
  color: #fff;
  padding: 40px 15px;
  /* background-image: url("/images/sc_welcome_bg.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px 5px 0 0;
}

.terms-card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.card-body-sc,
.card-footer {
  background-color: #FE2F59 !important;
  /* background-color: lightpink !important; */
}

.create_post_sc_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #FF6969 !important;
  border: none;
  border-radius: 10px;
  font-size: 12px;
  padding: 7px 8px;
  color: #fff;
}

.create_post_sc_btn:hover {
  background-color: #e70533 !important;
}

.btn_sc {
  background-color: transparent;
  outline: none;
  border: none !important;
  letter-spacing: 1px;
}

.checkSoftCornerPin-area-data-message {
  font-size: 15px;
  margin: 0px 0px 50px 0px;
}

.checkSoftCornerPin-area-data-pin {
  height: 60px;
  width: 70px !important;
  border: 1px solid #EBEBEB;
  border-radius: 23px;
  font-size: 37px;
  font-weight: 250;
  text-align: center;
  padding: 0px 0px 10px 0px;
  color: gray;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

}

.checkSoftCornerPin-area-btn {
  height: 55px;
  width: 325px;
  border-radius: 31px;
  color: white;
  background-color: #64A6F0;
  margin: 0px 0px 20px 0px;
}

/* .checkSoftCornerPin-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checkSoftCornerPin-head {
  margin: 15% 0px 50px;
}

.checkSoftCornerPin-area-data-pin {
  height: 60px;
  width: 60px;
  border: 1px solid #EBEBEB;
  border-radius: 23px;
  font-size: 25px;
  font-weight: 250;
  text-align: center;
}

.checkSoftCornerPin-btn {
  color: white;
  background-color: #64A6F0;
  margin: 50px 0px 0px;
  padding: 7px 100px;
  border: none;
  border-radius: 30px;
  font-size: 20px;
} */












/* ***** Home ***** */


/* ***** Left Block ***** */

/* .sc-leftBlock {
  border: 1px solid red;
  width: 100%;
  margin-top: 50px;
  margin-top: 75px;
  padding-top: 1px;
  box-shadow: 0px 0px 5px gray;
} */

.sc_search_list {
  position: absolute;
  min-height: 200px;
  width: 100%;
  margin: auto;
  top: 0px;
  border: 1px solid black;
  border-radius: 15px;
  /* background-color: white; */
  z-index: 2;
}






/* *** Div 1 *** */


.leaf-image1 {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-right-radius: 50%;
  overflow: hidden;
  width: 70px;
  height: 70px;
  /* margin-right: 20px; */
  object-fit: cover;
}

.leaf-image2 {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  overflow: hidden;
  width: 70px;
  height: 70px;
  /* margin-right: 20px; */
  object-fit: cover;
}

.male-box {
  border: 2px solid #74C7F9;
  box-shadow: #74c6f9 6px 2px 16px 0px, #74c6f9 -6px -2px 16px 0px;
}

.female-box {
  border: 2px solid #FFCCCD;
  box-shadow: rgb(255, 122, 124) 6px 2px 16px 0px, #ff9fa0 -6px -2px 16px 0px;
}

.leaf-image3 {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-right-radius: 50%;
  border: 2px solid #FFCCCD;
  overflow: hidden;
  width: 70px;
  height: 70px;
  /* margin-right: 20px; */
  object-fit: cover;
  box-shadow: rgb(255, 122, 124) 6px 2px 16px 0px, #ff9fa0 -6px -2px 16px 0px;
}

.leaf-image4 {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border: 2px solid #74C7F9;
  overflow: hidden;
  width: 70px;
  height: 70px;
  /* margin-right: 20px; */
  object-fit: cover;
  box-shadow: #74c6f9 6px 2px 16px 0px, #74c6f9 -6px -2px 16px 0px;
}




/* *** Div 2 *** */

.sc-lb-div-2t {
  /* border: 1px solid black; */
  margin: 50px 0px;
  margin: 64px 0px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sc-lb-div-2-p1 {
  color: white;
  background-color: #FF6969;
  border-radius: 23px;
  padding: 7px 20px;
  width: 125px;
  text-align: center;
}

.sc-lb-div-2-p2 {
  color: white;
  background-color: #FF6969;
  border-radius: 23px;
  padding: 7px 20px;
  width: 150px;
  text-align: center;
}

.sc-lb-div-2-since {
  margin: 10px;
}

.sc-lb-div-2f {
  /* border: 1px solid black; */
  margin: 75px 0px 0px;
  margin: 80px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc-lb-div-2-pv {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border-radius: 20px;
  box-shadow: 0px 3px 50px #00000019;
  margin: 0px 17px 0px 0px;
}

.sc-lb-div-2-pv-count {
  font-weight: 700;
  margin: 0px 0px 3px 0px;
}

.sc-lb-div-2-pv-name {
  font-size: 13px;
  color: #848484;
}

.sc-lb-div-2f-c {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border-radius: 20px;
  box-shadow: 0px 3px 50px #00000019;
  margin: 0px 0px 0px 17px;
}

.sc-lb-div-2-c-count {
  font-weight: 700;
  margin: 0px 0px 3px 0px;
}

.sc-lb-div-2-c-name {
  font-size: 13px;
  color: #848484;
}


/* *** Div 4 *** */


/* ***** Center Block ***** */

.sc-centerBlock {
  /* border: 1px solid blue; */
  margin-top: 50px;
  width: 65%;
  overflow-y: scroll;
}

.sc-centerBlock::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}


/* ** Div 1 ** */

.sc-cb-div-1 {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  gap: 40px;
  align-items: center;
}

.sc-cb-div-1 input {
  height: 50px;
  width: 500px;
  border-radius: 20px;
  padding: 0px 0px 0px 25px;
  border: 1px solid #F5F5F5;
  margin: 20px 0px 10px 0px;
}

.sc-cb-div-1 img {
  cursor: pointer;
}


/* ** Div 2 ** */

/* .sc-cb-div-2 {
  border: 1px solid green;
} */

.sc-cb-div-2-user-1 {
  width: 90%;
  margin: 20px auto 50px;
}

.sc-cb-div-2-user-1-profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sc-cb-div-2-user-1-profile img {
  cursor: pointer;
  border-radius: 50%;
  width: 100%;
}

.sc-cb-div-2-user-1-profile-image {
  outline: 2px solid #64A6F0;
  outline-offset: 2px;
}

.sc-cb-div-2-user-1-profile-name {
  width: 400px;
}

.sc-cb-div-2-user-1-profile-name h5 {
  cursor: pointer;
  display: inline;
}

.sc-cb-div-2-user-1-post {
  /* border: 1px solid black; */
  margin: 20px auto 0px;
  text-align: center;
}

.sc-cb-div-2-user-1-post img {
  border-radius: 25px;
}

.sc-cb-div-2-user-1-description {
  color: #6E6E6E;
  margin: 20px 0px 0px 0px;
}

.sc-cb-div-2-user-1-description span {
  color: #2E2E2E;
}


/* ** Div 3 ** */

.sc-cb-div-3 {
  /* border: 1px solid black; */
  width: 90%;
  margin: 50px auto 30px;
}

.sc-cb-div-3-heading {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sc-cb-div-3-head {
  display: flex;
  align-items: center;
}

.sc-cb-div-3-d1-img {
  margin: 0px 10px 0px 0px;
}

.sc-cb-div-3-d1-name {
  font-weight: 600;
}

.sc-cb-div-3-d1-seeAll {
  color: #1467EA;
  cursor: pointer;
  font-size: 15px;
}

.sc-cb-div-3-users {
  /* border: 1px solid green; */
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  position: relative;
  margin: 20px 0px 0px;
}

.sc-cb-div-3-user {
  /* width: 150px; */
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px #0000000D;
  position: relative;
  border-radius: 20px;
  padding: 10px;
}

.sc-cb-div-3-user-profile {
  height: 150px;
  width: 100%;
  border-radius: 20px;
  margin: 0px 0px 10px 0px;
  object-fit: cover;
  position: relative;
  bottom: 3px;
}

.sc-cb-div-3-user-logo {
  height: 35px;
  width: 35px;
  /* position: absolute; */
  top: 130px;
  left: 35px;
  background-color: white;
  padding: 5px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 0px 3px 0px gray;
}

.sc-hear-cross {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  top: -40px;
  right: -60px;
  /* left: 15px; */
  z-index: 10;
}

.sc-cb-div-3-user-logo-img {
  height: 20px;
  width: 20px;
}

.sc-cb-div-3-user-cut {
  height: 35px;
  width: 35px;
  /* position: absolute; */
  top: 130px;
  left: 90px;
  background-color: white;
  padding: 2px 6px;
  font-size: 18px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 0px 3px 0px gray;
  font-weight: 900;
}

.sc-cb-div-3-user-name {
  font-weight: 700;
  text-align: center;
  max-height: 25px;
  margin: 0px 10px;
  overflow: hidden;
  text-align: center;
}

.sc-cb-div-3-user-age {
  font-size: 13px;
  margin: 0px 0px 5px 0px;
  color: #757E90;
  text-align: center;
  font-size: 11px
}












/* ***** Right Block ***** */

.sc-rightBlock {
  /* border: 1px solid green; */
  overflow-y: scroll;
  box-shadow: 0px 0px 5px gray;
}

.sc-rightBlock::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}



/* ***** Right Block > User ***** */

.sc-rb-user {
  /* border: 1px solid black; */
  width: 80%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 27px #0000000D;
  border-radius: 30px;
  position: relative;
}

.sc-rb-profile {
  max-height: 300px;
  /* width: 100%; */
  object-fit: cover;
  border-radius: 30px;
  margin: 0px 0px 40px 0px;
}

.sc-rb-name {
  margin-top: 20px;
  font-size: 19px;
  font-weight: 700;
}


.sc-rb-address {
  font-size: 12px;
  color: #616060;
}

.sc-rb-knowMore {
  font-size: 12px;
  color: #2676E1;
  cursor: pointer;
}

.sc-rb-warning {
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  width: 83%;
  margin: 20px 0px;
}

.sc-rb-warning-logo {
  height: 27px;
  width: 27px;
}

.sc-rb-warning-msg {
  margin: 0px 0px 0px 10px;
  font-size: 12px;
}

.sc-rb-btn .scAddRemoveBtn {
  /* border: 1px solid black; */
  color: white;
  background-color: #64A6F0;
  padding: 5px 100px;
  margin: 0px 0px 20px 0px;
  border-radius: 30px;
  cursor: pointer;
}

.sc-rb-btn.scAddRemoveBtn:hover {
  color: white;
  background-color: #64A6F0;
  padding: 5px 100px;
  margin: 0px 0px 20px 0px;
  border-radius: 30px;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.main-btn:hover {
  border: none;
}

.sc-rb-cut {
  position: absolute;
  top: 20px;
  left: 20px;
  /* border: 1px solid black; */
  background-color: rgb(179, 177, 177);
  height: 25px;
  width: 25px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  opacity: 70%;
  padding: 2px;
  font-size: 15px;
}

.sc-rb-cut>svg {
  width: 15px;
  padding-bottom: 2px;
}

.sc-rb-favourite {
  height: 30px;
  width: 30px;
  /* border: 1px solid black; */
  background-color: white;
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 1px;
  border-radius: 50%;
  cursor: pointer;
}

.sc-rb-verify {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 60%;
  top: 270px;
  margin: auto;
}

.sc-rb-verified {
  display: flex;
  flex-direction: column;
  top: 270px;
  left: 18%;
}

.sc-rb-verified-profile {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  border: 1px solid white;
}

.sc-rb-verified-name {
  background-color: white;
  padding: 0px 5px;
  border-radius: 10px;
  font-size: 13px;
  margin: 5px 0px 0px;
  text-align: center;
  box-shadow: 0px 3px 6px #00000019;
}

.sc-rb-logo {
  height: 50px;
  width: 50px;
  /* border: 1px solid black; */
  box-shadow: 0px 0px 3px 0px gray;
  position: absolute;
  top: 270px;
  left: 45%;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc-rb-logo-img {
  height: 30px;
  width: 30px;
}

.sc_card_details {
  width: 100px;
  border-radius: 10px;
  background-color: rgb(243, 241, 241);
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  padding: 2px 10px;
  font-weight: 700;
  font-size: 14px;
}

.sc_card_details>img {
  width: 20px;

}

.sc-rb-lgt {
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-top: 20px;
}

.sc-rb-lt,
.sc-rb-gt {
  /* border: 1px solid black; */
  padding: 8px 15px;
  box-shadow: 0px 3px 6px #00000017;
  font-size: 17px;
  border-radius: 50%;
  cursor: pointer;
}

.spinner-border {
  width: 20px;
  height: 20px;
}







/* ***** Right Block > Area ***** */

.sc-rb-area {
  height: 90vh;
}

.sc-rb-area-add-header {
  width: 85%;
  font-size: 23px;
  font-weight: 550;
}

.sc-rb-area-add {
  width: 85%;
  margin: 0px auto 25px;
  padding: 15px;
  border-radius: 13px;
  box-shadow: 0px 3px 20px #00000019;
}

.sc-rb-area-add-usre {
  display: flex;
  align-items: center;
}

.sc-rb-area-add-usre-profile {
  height: 40px;
  width: 40px;
  margin: 0px 15px 0px 0px;
}

.sc-rb-area-add-usre-sponserd {
  color: #5367F9;
  font-size: 14px;
}

.sc-rb-area-add-usre-msg1 {
  font-size: 12px;
  color: #3E3E3E;
  margin: 10px 0px;
}

.sc-rb-area-add-usre-link {
  font-weight: 700;
  margin: 7px 0px 0px;
}

.sc-rb-area-add-usre-msg2 {
  display: block;
  font-size: 13px;
  color: #3E3E3E;
}

.sc-rb-area-add-usre-btn {
  width: 100%;
  text-align: center;
  color: white;
  background-color: #5367F9;
  padding: 7px 0px;
  margin: 10px 0px 0px;
  border-radius: 10px;
  cursor: pointer;
}







.sc-rb-area-gift {
  width: 85%;
}

.sc-rb-area-gift-header {
  margin: 0px 0px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sc-rb-area-gift-head {
  margin: 0px;
}

.sc-rb-area-gift-seeAll {
  color: #2676E1;
  cursor: pointer;
}

.sc-rb-area-gifts {
  height: 180px;
  display: flex;
  justify-content: space-between;
}

.sc-rb-area-gifts-gift {
  height: 100%;
  width: 135px;
  padding: 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #e9e9e9;
  border-radius: 13px;
  cursor: pointer;
  color: black;
}

.sc-rb-area-gift-img {
  height: 115px;
  padding: 5px;
}

.sc-rb-area-gift-name {
  height: 25px;
  font-weight: 600;
  overflow: hidden;
  font-size: 14px;
}

.sc-rb-area-gift-details {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.sc-rb-area-gift-star {
  color: white;
  background-color: #2676E1;
  padding: 0px 7px;
  border-radius: 20px;
  font-size: 13px;
}

.sc-rb-area-gift-star-img {
  height: 13px;
  margin: 0px 0px 3px 5px;
}










/* *** Trending Feeds *** */

.rb-tf {
  /* border: 1px solid red; */
  width: 80%;
  margin: 15px auto 30px;
}

/* ** Div 1 ** */

.rb-tf-div-1 {
  display: flex;
  justify-content: space-between;
}

.rb-tf-div-1 h6 {
  margin: 10px 0px;
}

.rb-tf-div-1 p {
  color: #1467EA;
  margin: 10px 0px;
  cursor: pointer;
}

/* ** Div 2 ** */

.rb-tf-div-2 {
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-between;
}

.rb-tf-div-2 img {
  border-radius: 10px;
}


/* *** Suggestion *** */

.rb-s {
  /* border: 1px solid green; */
  width: 80%;
  margin: 0px auto;
}

/* ** Div 1 ** */


/* ** Div 2 ** */

.rb-s-div-2-users {
  /* border: 1px solid red; */
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.rb-s-div-2-users img {
  border-radius: 50%;
  outline: 2px solid #64A6F0;
  outline-offset: 2px;
}

.rb-s-div-2-users-name {
  width: 335px;
}

.rb-s-div-2-users-name p {
  text-transform: capitalize;
}

.rb-s-div-2-users-name span {
  color: #BFBFBF;
  font-size: 14px;
}

.rb-s-div-2-button {
  height: 35px;
  width: 100px;
  color: white;
  background-color: #64A6F0;
  border: none;
  border-radius: 5px;
}


/* ***** Gallary ***** */

.gallary-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 200px;

}

.sc-gallary {
  margin: 75px 0px 0px;
  display: flex;
  height: 100vh;
}

.gallary-post {
  display: none;
  position: absolute;
  bottom: 0px;

  color: rgba(255, 250, 250, 0.712);
  padding: 20px 20px;
}

.gallary-post-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.gallary-post-img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.gallary-post-desp {
  font-size: 14px;
  font-style: italic;
  margin: 10px 0px;
}

.gallary-post-body {
  position: relative;
  border-radius: 15px;
}

.gallary-body-img {

  border-radius: 15px;
}


.gallary-post-body:hover .gallary-post {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.gallary-post-desp>span {
  color: rgb(165, 165, 221);
  font-weight: 600;
}

/* *** Gallary > Navbar *** */

.sc-gallery-right {
  overflow-y: scroll;
}

.sc-gallery-right::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.sc-gallary-nav {
  /* border: 1px solid black; */
  width: 1400px;
  margin: 40px 0px 20px 0px;
  display: flex;
  justify-content: space-around;
}

.sc-gallary-nav-div {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sc-gallary-nav-div img {
  margin: 0px 0px 10px 0px;
  cursor: pointer;
}

.sc-gallary-nav-div span {
  cursor: pointer;
  color: black;
}


/* *** Photos *** */

.sc-gallary-today {
  /* border: 1px solid black; */
  margin: 40px 0px 0px 0px;
}

.sc-gallary-today-head {
  color: #848484;
  font-size: 23px;
  margin: 0px 0px 10px 60px;
}

.sc-gallary-today-images {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-evenly;
}

.sc-gallary-yesterday,
.sc-gallary-older {
  /* border: 1px solid black; */
  margin: 40px 0px 0px 0px;
}

.sc-gallary-older .older-posts {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 53px;
  padding: 0px 70px;
}

.older-posts h3 {
  display: flex;
  justify-content: center;
}

.sc-gallary-older .older-posts .older-posts-span {
  height: 200px;
  width: 280px;
  border-radius: 17px;
  background-color: black;
  object-fit: contain;
}

.older-posts-span img,
video {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.sc-gallary-yesterday-head,
.sc-gallary-older-head {
  color: #848484;
  font-size: 23px;
  margin: 0px 0px 10px 60px;
}

/* .sc-gallary-yesterday-images {
  border: 1px solid red;
} */

.sc-gallary-yesterday-images-1 {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-evenly;
  margin: 0px 0px 50px 0px;
}

.sc-gallary-yesterday-images-2 {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-evenly;
}


/* *** Album *** */

.sc-gallary-album-images {
  /* border: 1px solid red; */
  margin: 50px 0px 0px 0px;
  display: flex;
  justify-content: space-evenly;
}

.sc-gallary-album-images-div {
  /* border: 1px solid green; */
  width: auto;
  position: relative;
  box-shadow: 0px 0px 10px #0000000D;
  cursor: pointer;
}

.sc-gallary-album-images-div h3 {
  color: white;
  position: absolute;
  top: 40%;
  left: 30%;
}

.sc-gallary-album-div {
  /* border: 1px solid black; */
  margin: 50px 0px 0px 0px;
}

.sc-gallary-album-div-addAlbum {
  /* border: 1px solid red; */
  border-radius: 20px;
  height: 325px;
  width: 325px;
  box-shadow: 3px 3px 15px #0000001A;
  margin: 0px 0px 0px 105px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sc-gallary-album-div-addAlbum img {
  margin: 0px 0px 10px 0px;
}

/* *** Explore *** */

.sc-gallary-explore-div-1 {
  /* border: 1px solid black; */
  margin: 30px 0px 0px 0px;
  display: flex;
  justify-content: space-evenly;
}

.sc-gallary-explore-div-1 img {
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 0px 0px 10px #0000000D
}

.sc-gallary-memories {
  margin: 40px 0px 0px 0px;
}

.sc-gallary-memories-head {
  margin: 0px 0px 10px 83px;
}

.sc-gallary-memories-images {
  margin: 0px 0px 0px 70px;
}

.sc-gallary-memories-images img {
  margin: 0px 70px 0px 0px;
  box-shadow: 0px 0px 10px #0000000D
}





/* ***** Add To Life Partner ***** */

.addToLifePartner {
  /* border: 1px solid red; */
  margin: 75px 0px 0px;
  height: 888px;

  background-image: url('/images/SoftCorner/atlp-bg.png');
  background-repeat: no-repeat;
  background-size: 1920px 888px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.addToLifePartner-head {
  font-size: 35px;
  color: #2676E1;
}

.addToLifePartner-message {
  /* border: 1px solid black; */
  width: 630px;
  text-align: center;
  padding: 15px 30px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 0px 5px 10px #FFD3D4;
}

.addToLifePartner-tac input {
  margin: 0px 10px 0px 0px;
}

.addToLifePartner-tac span {
  color: #2676E1;
}

.addToLifePartner-button {
  color: #64A6F0;
  background-color: white;
  border: none;
  padding: 15px 75px;
  border-radius: 30px;
  cursor: pointer;
}

.addToLifePartner-button:hover {
  color: #64A6F0;
}


/* ***** Propose And Get Married ***** */

.proposeAndGetMarried {
  /* border: 1px solid red; */
  margin: 75px 0px 0px;
  height: 888px;

  background-image: url('/images/SoftCorner/pagm-bg.png');
  background-repeat: no-repeat;
  background-size: 1920px 888px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.proposeAndGetMarried-head {
  font-size: 35px;
  color: #2676E1;
  height: 425px;
}

.proposeAndGetMarried-message {
  /* border: 1px solid black; */
  width: 630px;
  text-align: center;
  padding: 15px 30px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 0px 5px 10px #64A6F0;
}

.proposeAndGetMarried-tac {
  background-color: white;
  border-radius: 25px;
  padding: 7px 25px 0px 25px;
}

.proposeAndGetMarried-tac input {
  margin: 0px 10px 0px 0px;
}

.proposeAndGetMarried-tac span {
  color: #2676E1;
}

.proposeAndGetMarried-button {
  color: #2676E1;
  background-color: white;
  border: none;
  padding: 15px 100px;
  border-radius: 30px;
  cursor: pointer;
}

.proposeAndGetMarried-button:hover {
  color: #2676E1;
}


/* ***** Propose Option ***** */

.proposeOption {
  /* border: 1px solid red; */
  margin: 75px 0px 0px;
  height: 888px;

  background-image: url('/images/SoftCorner/po-bg.png');
  background-repeat: no-repeat;
  background-size: 1920px 888px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.proposeOption-head {
  font-size: 35px;
  color: white;
}

.proposeOption-couple-male {
  translate: 65px;
}

.proposeOption-couple-female {
  translate: -105px;
}

.proposeOption-options {
  display: flex;
}

.proposeOption-options-all {
  padding: 0px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.proposeOption-options-all-img2 {
  background-color: #E97596;
  margin: 10px 0px;
  padding: 27px;
  border-radius: 23px;
  cursor: pointer;
}

.proposeOption-options-all a {
  color: white;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
}


.proposeOption-button {
  color: #2676E1;
  background-color: white;
  border: none;
  padding: 15px 125px;
  border-radius: 30px;
  cursor: pointer;
}

.proposeOption-button:hover {
  color: #2676E1;
}


/* ***** Propose Option > Message ***** */

.proposeOptionMessage {
  /* border: 1px solid red; */
  margin: 75px 0px 0px;
  height: 888px;

  background-image: url('/images/SoftCorner/pom-bg.png');
  background-repeat: no-repeat;
  background-size: 1920px 888px;
}

.proposeOptionMessage form textarea {
  position: absolute;
  top: 225px;
  left: 725px;
  color: red;
  text-decoration-line: underline;
  text-decoration-color: #AB6F40;
  background-color: transparent;
  border: none;
  resize: none;
  transform: rotate(2deg);
  font-size: 23px;
}

.proposeOptionMessage form input {
  position: absolute;
  top: 625px;
  left: 975px;
  color: red;
  background-color: transparent;
  border: none;
  resize: none;
  font-size: 23px;
  transform: rotate(3deg);
}

.proposeOptionMessage-button {
  color: #2676E1;
  background-color: white;
  border: none;
  padding: 15px 125px;
  border-radius: 30px;
  cursor: pointer;
  position: absolute;
  top: 863px;
  left: 800px;
}


/* ***** Propose Option > Memories ***** */

.proposeOptionMemories-edit {
  display: inline;
  background-color: #a39e9e;
  color: white;
  padding: 7px 10px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  bottom: 130px;
}

.proposeOptionMemories-edit img {
  margin: 0px 10px 5px 0px;
}

.proposeOptionMemories-image {
  margin: 25px 0px 0px 130px;
}

.proposeOptionMemories-album {
  margin: 40px 0px 0px 0px;
}

.proposeOptionMemories-album-head {
  margin: 10px 0px 20px;
}

.proposeOptionMemories-album-img-div {
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
}

.proposeOptionMemories-button {
  color: white;
  background-color: #64A6F0;
  padding: 13px 150px;
  border-radius: 30px;
  margin: 25px 0px 0px 230px;
}


/* ***** Propose Option > Memories > Edit ***** */

/* *** Div 1 *** */

.proposeOptionMemoriesEdit-div-1 {
  display: flex;
  justify-content: space-evenly;
  width: 90%;
  margin: 0px auto;
}

.proposeOptionMemoriesEdit-div-1 img {
  background-color: rgb(153, 145, 145);
  padding: 10px;
  border-radius: 50%;
}


/* *** Div 2 *** */

.proposeOptionMemoriesEdit-div-2 {
  text-align: center;
  margin: 30px 0px;
}


/* *** Div 3 *** */

.proposeOptionMemoriesEdit-div-3 {
  display: flex;
  background-color: rgb(120, 115, 115);
  border-radius: 15px;
  padding: 5px 7px;
}

.proposeOptionMemoriesEdit-div-3-all {
  text-align: center;
  margin: 0px 5px;
}

.proposeOptionMemoriesEdit-div-3-all span {
  font-size: 10px;
  color: white;
}


/* *** Div 4 *** */

.proposeOptionMemoriesEdit-div-4 {
  text-align: center;
}

.proposeOptionMemoriesEdit-div-4 button {
  color: white;
  background-color: #64A6F0;
  padding: 13px 150px;
  border-radius: 30px;
  margin: 30px 0px 0px 0px;
}


/* ***** Propose Option > Gifts ***** */

.sc-pog-1 {
  /* border: 1px solid black; */
  height: 888px;
  margin: 75px 0px 0px 0px;
  overflow: hidden;

  display: flex;
}


/* ***** Propose Option > Gifts 1 > Right Block ***** */

.sc-pog-1-rb {
  /* border: 1px solid black; */
  width: 75%;
  /* background-color: #F8F8F8; */
}


/* *** Search *** */

/* .sc-pog-1-rb-search {
  border: 1px solid red;
} */

.sc-pog-1-rb-search form label {
  /* border: 1px solid black; */
  border-radius: 30px;
  width: 90%;
  margin: 25px auto;
  padding: 10px 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 3px 6px #4B4B4B21;
}

.sc-pog-1-rb-search form label input {
  height: 30px;
  width: 90%;
  font-size: 20px;
  border: none;
}


/* *** Add *** */

.sc-pog-1-rb-add {
  /* border: 1px solid lightseagreen; */
  height: 400px;
  width: 90%;
  margin: 25px auto;
  text-align: center;
}


/* *** Category *** */

.sc-pog-1-rb-category {
  /* border: 1px solid red; */
  width: 90%;
  margin: 30px auto;
  display: flex;
  align-items: center;
  column-gap: 25px;
}

.sc-pog-1-rb-category-head {
  /* border: 1px solid black; */
  border-radius: 30px;
  padding: 7px 20px;
  color: #EBEBEB;
  background-color: #4B4B4B;
  cursor: pointer;
}

.sc-pog-1-rb-category-all img {
  border-radius: 50%;
  position: relative;
  left: 10px;
  box-shadow: 0px 3px 6px #4B4B4B21;
}

.sc-pog-1-rb-category-all span {
  border: 1px solid #EBEBEB;
  padding: 5px 17px;
  border-left: none;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
  font-size: 17px;
  box-shadow: 0px 3px 6px #4B4B4B21;
}


/* *** Items *** */

.sc-pog-1-rb-items {
  /* border: 1px solid red; */
  width: 90%;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
}

.sc-pog-1-rb-item {
  /* border: 1px solid green; */
  border-radius: 30px;
  width: 215px;
  margin: 5px;
  padding: 5px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 12px -7px black;
  cursor: pointer;
  position: relative;
}

.sc-pog-1-rb-item-favorite {
  position: absolute;
  top: 17px;
  right: 17px;
}

.sc-pog-1-rb-item-img {
  /* border: 1px solid black; */
  height: 150px;
  width: 150px;
  margin: 10px;
  object-fit: contain;
}

.sc-pog-1-rb-item-name {
  color: black;
  width: 90%;
  height: 25px;
  overflow: hidden;
}

.sc-pog-1-rb-item-details {
  /* border: 1px solid red; */
  width: 90%;
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sc-pog-1-rb-item-price span {
  font-size: 17px;
  font-weight: 400;
}

.sc-pog-1-rb-item-rating {
  color: white;
  background-color: #2676E1;
  padding: 1px 15px;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
}

.sc-pog-1-rb-item-rating img {
  margin: 0px 0px 5px 7px;
}


/* ***** Propose Option > Gifts 2 > Right Block ***** */

.sc-pog-2 {
  /* border: 1px solid black; */
  height: 888px;
  margin: 75px 0px 0px 0px;
  overflow: hidden;

  display: flex;
}

.sc-pog-2-rb {
  /* border: 1px solid black; */
  width: 75%;
  /* background-color: #F8F8F8; */
}

/* *** Item *** */

.sc-pog-2-rb-item {
  /* border: 1px solid black; */
  display: flex;
  width: 90%;
  margin: 50px auto;
}

/* *** Item > Image *** */

.sc-pog-2-rb-item-img {
  /* border: 1px solid red; */
  width: 50%;
}

.sc-pog-2-rb-item-img-main {
  /* border: 1px solid black; */
  height: 500px;
  width: 90%;
  box-shadow: 3px 3px 21px #00000019;
  border-radius: 25px;
}

.sc-pog-2-rb-item-imgs {
  /* border: 1px solid red; */
  width: 90%;
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
}

.sc-pog-2-rb-item-imgs img {
  /* border: 1px solid red; */
  cursor: pointer;
  box-shadow: 3px 3px 21px #00000019;
  border-radius: 20px;
  object-fit: contain;
}


/* *** Item > Details *** */

.sc-pog-2-rb-item-details {
  /* border: 1px solid green; */
  width: 50%;
}



/* *** Item > Details > Heading *** */

.sc-pog-2-rb-item-details-head {
  font-weight: 400;
  font-size: 30px;
  margin: 0px 0px 17px 0px;
}


/* *** Item > Details > Rating *** */

.sc-pog-2-rb-item-details-rating {
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
}

.sc-pog-2-rb-item-details-rating-star {
  /* border: 1px solid black; */
  color: white;
  background-color: #2676E1;
  margin: 0px 20px 0px 0px;
  padding: 1px 15px;
  border-radius: 20px;
  cursor: pointer;
}

.sc-pog-2-rb-item-details-rating-star span {
  margin: 0px 10px 0px 0px;
}

.sc-pog-2-rb-item-details-rating-count {
  font-size: 20px;
}


/* *** Item > Details > Price And Quantity *** */

.sc-pog-2-rb-item-details-paq {
  /* border: 1px solid black; */
  margin: 15px 0px 30px;
  display: flex;
  align-items: center;
}

.sc-pog-2-rb-item-details-paq-p1 span {
  font-weight: 350;
  font-size: 30px;
}

.sc-pog-2-rb-item-details-paq-p2 {
  margin: 5px 150px 0px 20px;
  font-size: 30px;
  color: #B1B1B1;
  text-decoration-line: line-through;
}

.sc-pog-2-rb-item-details-paq-q {
  border: 1px solid #EBEBEB;
  height: auto;
  width: auto;
  display: flex;
  text-align: center;
  border-radius: 15px;
}

.sc-pog-2-rb-item-details-paq-q1 {
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
  font-size: 25px;
  padding: 5px 20px;
  cursor: pointer;
}

.sc-pog-2-rb-item-details-paq-q2 {
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
  font-size: 25px;
  padding: 5px 10px;
}

.sc-pog-2-rb-item-details-paq-q3 {
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
  font-size: 25px;
  padding: 5px 20px;
  cursor: pointer;
}


/* *** Item > Details > Description *** */

.sc-pog-2-rb-item-details-description {
  /* border: 1px solid black; */
  width: 500px;
}

.sc-pog-2-rb-item-details-description h4 {
  /* border: 1px solid black; */
  margin: 0px 0px 15px 0px;
}

.sc-pog-2-rb-item-details-description p {
  /* border: 1px solid black; */
  margin: 0px 0px 0px 10px;
  width: 600px;
}


/* *** Item > Details > Product Detail *** */

.sc-pog-2-rb-item-details-pd {
  /* border: 1px solid black; */
  margin: 35px 0px;
}


/* *** Item > Details > Add to Cart *** */

.sc-pog-2-rb-item-details-atc span {
  color: white;
  background-color: #64A6F0;
  padding: 15px 175px;
  border-radius: 30px;
  font-size: 20px;
  cursor: pointer;
}






/* *** Search *** */

/* .sc-pog-2-rb-search {
  border: 1px solid red;
} */

.sc-pog-2-rb-search form label {
  /* border: 1px solid black; */
  border-radius: 30px;
  width: 90%;
  margin: 25px auto;
  padding: 10px 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 3px 6px #4B4B4B21;
}

.sc-pog-2-rb-search form label input {
  height: 30px;
  width: 90%;
  font-size: 20px;
  border: none;
}


/* ***** Date Planner ***** */

.sc-datePlanner {
  /* margin: 50px 0px 0px; */
  display: flex;
  height: 100vh;
}



/* ***** Date Planner > Center Block ***** */

.sc-datePlanner-centerBlock {
  /* border: 1px solid black; */
  width: 65%;
  overflow-y: scroll;
}

.sc-datePlanner-centerBlock::-webkit-scrollbar {
  background-color: transparent;
  width: 0;
}

/* *** Div 1 *** */

.sc-datePlanner-centerBlock-div-1 {
  /* border: 1px solid blue; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.sc-datePlanner-centerBlock-div-1 form label {
  /* border: 1px solid green; */
  border-radius: 30px;
  margin: 20px 0px;
  box-shadow: 0px 0px 6px #00000017;
  background-color: white;
}

.sc-datePlanner-centerBlock-div-1 form label img {
  /* border: 1px solid blue; */
  margin: 10px 15px 13px 25px;
}

.sc-datePlanner-centerBlock-div-1 form input {
  border: none;
  height: 35px;
  width: 480px;
  font-size: 20px;
  margin: 5px 10px 0px 0px;
  padding: 3px;
}

.sc-datePlanner-centerBlock-div-1-asd {
  /* border: 1px solid black; */
  background-color: white;
  padding: 10px 30px;
  border-radius: 30px;
  box-shadow: 0px 0px 6px #00000017;
  cursor: pointer;
}

.sc-datePlanner-centerBlock-div-1-asd img {
  margin: 0px 15px 0px 0px;
}



/* ***** Date Planner > New Plan Popup ***** */

/* .newPlanPopup {
  border: 1px solid black;
} */

.newPlanPopup-head {
  text-align: center;
}

.newPlan-Btn {
  width: 100%;
  display: flex;
  padding: 15px 0px;
}

.newPlan-Btn>button {
  width: 70%;
  margin: auto;
  background-color: #FF6969 !important;
  border: none !important;
}


.newPlanPopup-formField {
  /* border: 1px solid red; */
  height: 700px;
  overflow-y: scroll;
  margin: 20px 0px 0px 5px;
}

.newPlanPopup-formField::-webkit-scrollbar {
  display: none;
  width: 0px;
}

.newPlanPopup-formField-label-all {
  color: white;
  background-color: #64A6F0;
  padding: 5px 20px;
  border-radius: 20px;
}

.sc-alerts {
  display: flex;
  justify-content: space-between;
  padding: 5px 30px;
}

.sc-alerts input {
  margin: 0px 5px;
}

.newPlanPopup-formField-label-all span {
  padding: 0px 0px 0px 7px;
}

.newPlanPopup-formField-input-all {
  height: 45px;
  width: 385px;
  border-radius: 20px;
  padding: 0px 20px;
  border: 1px solid #EBEBEB;
  margin: 0px 0px 25px 20px;
  font-size: 17px;
}

.newPlanPopup-formField-label-all {
  color: white;
  background-color: #FF6969;
  padding: 5px 20px;
  border-radius: 20px;
  font-size: 14px;
}

.form-check {
  display: inline;
  float: right;
  margin: 0px 15px 0px 0px;
}

.newPlanPopup-formField-dat-label {
  margin: 10px 0px 10px 15px;
}

.newPlanPopup-formField-dat-date {
  padding: 10px 25px;
  border-radius: 20px;
  border: 1px solid #EBEBEB;
  margin: 0px 27px 0px 25px;
}

.newPlanPopup-formField-dat-time {
  padding: 10px 25px;
  border-radius: 20px;
  border: 1px solid #EBEBEB;
}

.newPlanPopup-formField-notes-textArea {
  resize: none;
  border: 1px solid #EBEBEB;
  border-radius: 20px;
  margin: 0px 0px 20px 20px;
  padding: 10px;
  font-size: 17px;
}

.newPlanPopup-button {
  text-align: center;
  margin: 10px 0px;
}

.newPlanPopup-button span {
  color: white;
  background-color: #64A6F0;
  text-align: center;
  padding: 10px 125px;
  border-radius: 30px;
  cursor: pointer;
}


/* ***** Date Planner > Right Block ***** */

.noupcommingevents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
}

.noupcommingevents img {
  height: 150px;
  width: 150px;
  margin-bottom: 30px;
}

.noupcommingevents>span {
  width: 60%;
  margin: auto;
  text-align: center;

}

.sc-datePlanner-rb::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.sc-datePlanner-rb-head-1 {
  text-align: center;
  margin: 20px 0px 10px 0px;
}

.sc-datePlanner-rb-head-2 {
  text-align: center;
  margin: 0px 0px 20px 0px;
}

.sc-datePlanner-rb-plan {
  /* border: 1px solid black; */
  /* width: 453px; */
  /* margin: 10px 10%; */
}

.sc-datePlanner-rb-plan-date {
  /* border: 1px solid black; */
  color: white;
  background-color: #64A6F0;
  padding: 3px 20px;
  border-radius: 30px;
  position: relative;
  top: 15px;
  left: 15px;
  cursor: pointer;
}

.sc-datePlanner-rb-plan-d1 {
  /* border: 1px solid black; */
  border-radius: 17px;
  box-shadow: 3px 3px 13px #00000010;
  padding: 30px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sc-datePlanner-rb-plan-time {
  margin: 0px 0px 0px 35px;
  color: #2676E1;
}

.sc-datePlanner-rb-plan-img {
  border: 1px solid red;
  padding: 10px;
  border-radius: 50%;
  margin: 0px 30px 0px;
}

.sc-datePlanner-rb-plan-name span {
  font-size: 15px;
  color: #2676E1;
}


/* ***** Date Planner > Right Block ***** */

.sc-datePlanner-rb {
  /* border: 1px solid black; */
  width: 35%;
  box-shadow: 0px 0px 5px gray;
  overflow-y: scroll;
}

/* ***** Date Planner > Right Block > Div ***** */

.sc-datePlanner-rb-div {
  /* border: 1px solid black; */
  width: 85%;
  margin: 40px auto;
}


/* ***** Date Planner > Right Block > Div 1 ***** */

.sc-datePlanner-rb-div-1 {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sc-datePlanner-rb-div-1 h2 {
  color: #64A6F0;
  /* color: #2676E1; */
}

.sc-datePlanner-rb-div-1 h2 span {
  color: black;
  font-size: 30px;
  font-weight: 300;
}

.sc-datePlanner-rb-div-1 div span {
  color: #525252;
  font-size: 15px;
}

.sc-datePlanner-rb-hr {
  background-color: black;
}

/* ***** Date Planner > Right Block > Div 2 ***** */

.sc-datePlanner-rb-div-2 {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  margin: 25px 0px 35px;
}

.sc-datePlanner-rb-div-2-img {
  border: 1px solid #FF6969;
  margin: 0px 20px 0px 0px;
  padding: 10px 15px;
  border-radius: 50%;
}

.sc-datePlanner-rb-div-2-head span {
  color: #2676E1;
  font-size: 15px;
}

/* ***** Date Planner > Right Block > Div 3 ***** */

/* .sc-datePlanner-rb-div-3 {
  border: 1px solid red;
} */

/* *** Location *** */

.sc-datePlanner-rb-div-3-location {
  border: 1px solid #EBEBEB;
  border-radius: 30px;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sc-single-plans {
  border: 1px solid #EBEBEB;
  border-radius: 30px;
  width: 100%;
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sc-datePlanner-rb-div-3-location input {
  border: 1px solid green;
  height: 35px;
  width: 350px;
  border: none;
  font-size: 20px;
}

.sc-datePlanner-rb-div-3-location span {
  /* border: 1px solid green; */
  cursor: pointer;
  color: #2676E1;
  font-size: 15px;
}


/* *** Date And Time *** */

.sc-datePlanner-rb-div-3-dat {
  /* border: 1px solid black; */
  width: 100%;
  margin: 0px 0px 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


/* *** Date *** */

.sc-datePlanner-rb-div-3-date input {
  padding: 13px 50px;
  font-size: 17px;
  border-radius: 30px;
  border: 1px solid #EBEBEB;
}


/* *** Time *** */

.sc-datePlanner-rb-div-3-time input {
  padding: 13px 50px;
  font-size: 17px;
  border-radius: 30px;
  border: 1px solid #EBEBEB;
}


/* *** Repeat *** */

.sc-datePlanner-rb-div-3-repeat {
  border: 1px solid #EBEBEB;
  border-radius: 30px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.sc-datePlanner-rb-div-3-repeat input {
  border: 1px solid green;
  height: 35px;
  width: 350px;
  border: none;
  font-size: 20px;
}

.sc-datePlanner-rb-div-3-repeat span {
  /* border: 1px solid green; */
  cursor: pointer;
  color: #BFBFBF;
  font-size: 15px;
}


/* *** Alert *** */

.sc-datePlanner-rb-div-3-alert {
  border: 1px solid #EBEBEB;
  border-radius: 30px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.sc-datePlanner-rb-div-3-alert input {
  /* border: 1px solid green; */
  height: 35px;
  width: 295px;
  border: none;
  font-size: 20px;
}

.sc-datePlanner-rb-div-3-alert span {
  /* border: 1px solid green; */
  cursor: pointer;
  color: #2676E1;
  font-size: 15px;
}


/* ***** Date Planner > Right Block > Div 4 ***** */

.sc-datePlanner-rb-div-4 {
  text-align: center;
  margin: 5px 0px 0px;
}

.sc-datePlanner-rb-div-4 span {
  /* border: 1px solid black; */
  color: white;
  background-color: #64A6F0;
  padding: 10px 40px;
  border-radius: 30px;
  cursor: pointer;
}

/* ***** Date Planner > Right Block > Div 5 ***** */

.sc-datePlanner-rb-div-5 {
  /* border: 1px solid red; */
  margin: 30px 0px 0px;
}

.sc-datePlanner-rb-div-5-notes {
  /* border: 1px solid red; */
  width: 125px;
  color: white;
  background-color: #64A6F0;
  margin: 0px 0px 10px 0px;
  padding: 7px 0px;
  text-align: center;
  border-radius: 25px;
  cursor: pointer;
}

.sc-datePlanner-rb-div-5-notes img {
  margin: 0px 10px 0px 0px;
}

.sc-datePlanner-rb-div-5-textArea {
  border: 1px solid #EBEBEB;
  border-radius: 15px;
  margin: 20px 0px 0px;
  padding: 10px 15px;
  color: #6E6E6E;
  font-size: 15px;
}














/* ***** Special Days ***** */



.sc-specialDays {
  /* margin: 50px 0px 0px; */
  display: flex;
  height: 100vh;
}

/* ***** Special Days > Center Block ***** */

.sc-specialDays-centerBlock {
  /* border: 1px solid red; */
  width: 65%;
  overflow-y: scroll;
  /* background-color: #F8F8F8; */
}

.sc-specialDays-centerBlock::-webkit-scrollbar {
  background-color: transparent;
  width: 0px;
}

/* *** Div 1 *** */
.sc-specialDays-centerBlock-div-1 {
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  /* margin-top: 40px !important; */
  /* padding: 0px 20px; */
}

.sc-specialDays-centerBlock-div-1 form label {
  /* border: 1px solid green; */
  border-radius: 30px;
  margin: 20px 0px;
  box-shadow: 0px 0px 6px #00000017;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  padding:10px;
}

.add_special_days_btn{
padding: 10px ;
font-size: 14px;
display: flex;
gap: 10px;
justify-content: center;
border-radius:15px;
color: rgba(0, 0, 0, 0.719);
font-weight: 600;
}

.sc-specialDays-centerBlock-div-1 form input {
  border: none;
  outline: none;
  border-radius: 15px;
  
}

.sc-specialDays-centerBlock-div-1-asd {
  /* border: 1px solid black; */
  background-color: white;
  padding: 10px 30px;
  border-radius: 30px;
  box-shadow: 0px 0px 6px #00000017;
  cursor: pointer;
}

.sc-specialDays-centerBlock-div-1-asd img {
  margin: 0px 15px 0px 0px;
}


/* *** Div 2 *** */

.sc-specialDays-centerBlock-div-2 {
  /* border: 1px solid green; */
  margin: 10px 0px 0px 0px;
}

.sc-specialDays-centerBlock-div-2-specialDays {
  /* border: 1px solid red; */
  background-color: #FF6969;
  box-shadow: 0px 0px 6px #00000017;
  width: 90%;
  margin: 17px auto;
  padding: 25px;
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}

.sc-specialDays-centerBlock-div-2-specialDays-icon {
  border: 1px solid red;
  border-radius: 50%;
  padding: 15px;
  background-color: white;
}

.sc-specialDays-centerBlock-div-2-specialDays-head {
  /* border: 1px solid black; */
  color: white;
}

.sc-specialDays-centerBlock-div-2-specialDays-head h5 {
  margin: 0px;
  width: 400px;
  color: white;
  font-weight: 400;
}

.sc-specialDays-centerBlock-div-2-specialDays-head span {
  color: white;
  font-size: 15px;
}

.sc-specialDays-centerBlock-div-2-specialDays-date h4 {
  color: white;
}

.sc-specialDays-centerBlock-div-2-specialDays-date span {
  color: white;
}


/* *** Div 3 *** */

.sc-specialDays-centerBlock-div-3 {
  /* border: 1px solid green; */
  margin: 0px 0px 0px 0px;
}

.sc-specialDays-centerBlock-div-3-specialDays {
  /* border: 1px solid red; */
  background-color: white;
  box-shadow: 0px 0px 6px #00000017;
  margin: 17px auto;
  padding: 25px;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  align-items: center;
  cursor: pointer;
}

.sc-specialDays-centerBlock-div-3-specialDays-icon {
  border: 1px solid red;
  border-radius: 50%;
  padding: 15px;
}

/* .sc-specialDays-centerBlock-div-3-specialDays-head {
  border: 1px solid black;
} */

.sc-specialDays-centerBlock-div-3-specialDays-head p {
  margin: 0px;
  width: 400px;
  font-size: 20px;
  font-weight: 500;
}

.sc-specialDays-centerBlock-div-3-specialDays-date span {
  color: #BFBFBF;
  font-size: 15px;
}


/* ***** Special Days > Right Block ***** */

.sc-specialDays-rightBlock {
  /* border: 1px solid black; */
  width: 35%;
  box-shadow: 0px 0px 5px gray;
}

/* ***** Special Days > Right Block > Div ***** */

.sc-specialDays-rightBlock-div {
  /* border: 1px solid black; */
  width: 85%;
  margin: 40px auto;
}


/* ***** Special Days > Right Block > Div 1 ***** */

.sc-specialDays-rightBlock-div-1 {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sc-specialDays-rightBlock-div-1 h2 {
  color: #FF6969;
}

.sc-specialDays-rightBlock-div-1 h2 span {
  color: black;
  font-size: 30px;
  font-weight: 300;
}

.sc-specialDays-rightBlock-div-1 div span {
  color: #525252;
  font-size: 15px;
}

.sc-specialDays-rightBlock-hr {
  background-color: black;
}

/* ***** Special Days > Right Block > Div 2 ***** */

.sc-specialDays-rightBlock-div-2 {
  /* border: 1px solid red; */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 25px 0px 35px;
}

.sc-specialDays-rightBlock-div-2-img {
  border: 1px solid #FF6969;
  margin: 0px 20px 0px 0px;
  padding: 10px 15px;
  border-radius: 50%;
}

.sc-specialDays-rightBlock-div-2-head span {
  color: #2676E1;
  font-size: 15px;
}

/* ***** Special Days > Right Block > Div 3 ***** */

/* .sc-specialDays-rightBlock-div-3 {
  border: 1px solid red;
} */

/* *** Location *** */

.sc-specialDays-rightBlock-div-3-location {
  border: 1px solid #EBEBEB;
  border-radius: 30px;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sc-specialDays-rightBlock-div-3-location div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid green;
  gap: 10px;
  height: 35px;
  border: none;
  font-size: 20px;
}

.sc-specialDays-rightBlock-div-3-location span {
  /* border: 1px solid green; */
  cursor: pointer;
  color: #FF6969;
  font-size: 15px;
}


/* *** Date And Time *** */

.sc-specialDays-rightBlock-div-3-dat {
  /* border: 1px solid black; */
  width: 100%;
  margin: 0px 0px 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


/* *** Date *** */

.sc-specialDays-rightBlock-div-3-date input {
  padding: 13px 50px;
  font-size: 17px;
  border-radius: 30px;
  border: 1px solid #EBEBEB;
}


/* *** Time *** */

.sc-specialDays-rightBlock-div-3-time input {
  padding: 13px 50px;
  font-size: 17px;
  border-radius: 30px;
  border: 1px solid #EBEBEB;
}


/* *** Repeat *** */

.sc-specialDays-rightBlock-div-3-repeat {
  border: 1px solid #EBEBEB;
  border-radius: 30px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.sc-specialDays-rightBlock-div-3-repeat input {
  border: 1px solid green;
  height: 35px;
  width: 350px;
  border: none;
  font-size: 20px;
}

.sc-specialDays-rightBlock-div-3-repeat span {
  /* border: 1px solid green; */
  cursor: pointer;
  color: #BFBFBF;
  font-size: 15px;
}


/* *** Alert *** */

.sc-specialDays-rightBlock-div-3-alert {
  border: 1px solid #EBEBEB;
  border-radius: 30px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.sc-specialDays-rightBlock-div-3-alert input {
  /* border: 1px solid green; */
  height: 35px;
  width: 295px;
  border: none;
  font-size: 20px;
}

.sc-specialDays-rightBlock-div-3-alert span {
  /* border: 1px solid green; */
  cursor: pointer;
  color: #FF6969;
  font-size: 15px;
}


/* ***** Special Days > Right Block > Div 4 ***** */

.sc-specialDays-rightBlock-div-4 {
  text-align: center;
  margin: 5px 0px 0px;
}

.sc-specialDays-rightBlock-div-4 span {
  /* border: 1px solid black; */
  color: white;
  background-color: #FF6969;
  padding: 10px 40px;
  border-radius: 30px;
  cursor: pointer;
}

/* ***** Special Days > Right Block > Div 5 ***** */

.sc-specialDays-rightBlock-div-5 {
  /* border: 1px solid red; */
  margin: 30px 0px 0px;
}

.sc-specialDays-rightBlock-div-5-notes {
  /* border: 1px solid red; */
  width: 125px;
  color: white;
  background-color: #FF6969;
  margin: 0px 0px 10px 0px;
  padding: 7px 0px;
  text-align: center;
  border-radius: 25px;
  cursor: pointer;
}

.sc-specialDays-rightBlock-div-5-notes img {
  margin: 0px 10px 0px 0px;
}

.sc-specialDays-rightBlock-div-5-textArea {
  border: 1px solid #EBEBEB;
  border-radius: 15px;
  margin: 20px 0px 0px;
  padding: 10px 15px;
  color: #6E6E6E;
  font-size: 15px;
}

.sc-singlePlan-dates {
  font-size: 16px;
}



/* ***** Special Days > Add Special Days Popup ***** */

.addSpecialDays-head {
  text-align: center;
}

.addSpecialDays-formField {
  /* border: 1px solid red; */
  height: 700px;
  overflow-y: scroll;
  margin: 20px 0px 0px 5px;
}

.addSpecialDays-formField-label-all {
  color: white;
  background-color: #FF6969;
  padding: 5px 20px;
  border-radius: 20px;
  font-size: 14px;
}

.addSpecialDays-formField-label-all span {
  padding: 0px 0px 0px 7px;
}


.addSpecialDays-formField-input-all {
  height: 45px;
  width: 385px;
  border-radius: 20px;
  padding: 0px 20px;
  border: 1px solid #EBEBEB;
  margin: 0px 0px 25px 20px;
  font-size: 17px;
}

.form-check {
  display: inline;
  float: right;
  margin: 0px 15px 0px 0px;
}

.addSpecialDays-formField-selectDate-label {
  margin: 10px 0px 10px 15px;
}

.addSpecialDays-formField-selectDate-date {
  height: 45px;
  width: 375px;
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid #EBEBEB;
  margin: 0px 0px 0px 25px;
  display: flex;
  justify-content: space-between;
  align-items: unset;
  flex-direction: row;
}

.addSpecialDays-formField-repeat-label {
  margin: 0px 0px 10px 15px;
}

.addSpecialDays-formField-notes-textArea {
  resize: none;
  border: 1px solid #EBEBEB;
  border-radius: 20px;
  margin: 0px 0px 20px 20px;
  padding: 10px;
  font-size: 17px;
}

.addSpecialDays-button {
  text-align: center;
}

.addSpecialDays-button span {
  color: white;
  background-color: #64A6F0;
  text-align: center;
  padding: 10px 125px;
  border-radius: 30px;
  cursor: pointer;
}


/* ***** Profile ***** */

/* ***** Profile > Add New Address Popup ***** */

.addNewAddress-head {
  text-align: center;
}

.addNewAddress-formField {
  /* border: 1px solid red; */
  /* height: 700px; */
  /* overflow-y: scroll; */
  margin: 30px 0px 15px 10px;
}

.addNewAddress-formField-label-all {
  color: white;
  background-color: #64A6F0;
  padding: 5px 20px;
  border-radius: 20px;
}

.addNewAddress-formField-label-all span {
  padding: 0px 0px 0px 7px;
}

.addNewAddress-formField-input-all {
  height: 45px;
  width: 385px;
  border-radius: 20px;
  padding: 0px 20px;
  border: 1px solid #EBEBEB;
  margin: 0px 0px 25px 20px;
  font-size: 17px;
}

.addNewAddress-formField-cityState {
  /* border: 1px solid black; */
  display: flex;
}

.addNewAddress-formField-city {
  /* border: 1px solid red; */
  margin: 0px 10px 0px 0px;
}

.addNewAddress-formField-city-input {
  height: 45px;
  width: 180px;
  border: 1px solid #EBEBEB;
  border-radius: 20px;
  padding: 0px 20px;
  margin: 0px 0px 25px 15px;
}

/* .addNewAddress-formField-state {
  border: 1px solid green;
} */

.addNewAddress-formField-state-input {
  height: 45px;
  width: 180px;
  border: 1px solid #EBEBEB;
  border-radius: 20px;
  padding: 0px 20px;
  margin: 0px 0px 25px 15px;
}

.addNewAddress-button {
  text-align: center;
}

.addNewAddress-button span {
  color: white;
  background-color: #64A6F0;
  text-align: center;
  padding: 10px 125px;
  border-radius: 30px;
  cursor: pointer;
}


/* ***** Soft Corner > Profile *****  */

.sc-profile {
  /* border: 1px solid black; */
  display: flex;
  margin: 75px 0px 0px;
}


/* ***** Soft Corner > Profile > Center Block *****  */

.sc-profile-cb {
  /* border: 1px solid black; */
  width: 40%;
}

/* *** Div 1 *** */

.sc-profile-cb-d1 {
  /* border: 1px solid red; */
  width: 75%;
  margin: 50px auto;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.sc-profile-cb-d1-profile {
  margin: 0px 25px 0px 0px;
  box-shadow: 0px 0px 10px 1px gray;
  cursor: pointer;
}

.sc-profile-cb-d1-name {
  display: flex;
  flex-direction: column;
  margin: 0px 75px 0px 0px;
}


/* *** Div 2 *** */

.sc-profile-cb-d2 {
  /* border: 1px solid red; */
  width: 75%;
  margin: 50px auto;
}

.sc-profile-cb-d2 h4 {
  font-weight: 500;
  margin: 0px 0px 15px 0px;
}

.sc-profile-cb-d2 span {
  margin: 0px 0px 0px 10px;
  color: #4B4B4B;
}


/* *** Div 3 *** */

.sc-profile-cb-d3 {
  /* border: 1px solid red; */
  width: 75%;
  margin: 50px auto;
}

.sc-profile-cb-d3-head {
  font-weight: 600;
  margin: 0px 0px 20px 0px;
}

.sc-profile-cb-d3-option {
  /* border: 1px solid green; */
  margin: 0px 0px 0px 15px;
}

.sc-profile-cb-d3-option-all {
  /* border: 1px solid red; */
  color: black;
  display: flex;
  align-items: center;
  margin: 0px 0px 25px 0px;
  cursor: pointer;
  padding: 5px 0px;
}

.sc-profile-cb-d3-option-all-img {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin: 0px 20px 0px 0px;
  box-shadow: 0px 3px 6px #0000001A;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc-profile-cb-d3-option-all-head {
  /* border: 1px solid black; */
  width: 333px;
}

.sc-profile-cb-d3-option-all-head h4 {
  font-weight: 500;
  font-size: 20px;
}

.sc-profile-cb-d3-option-all-head span {
  font-size: 13px;
  /* color: #4B4B4B; */
}


/* *** Div 4 *** */

.sc-profile-cb-d4 {
  /* border: 1px solid red; */
  width: 75%;
  margin: 50px auto;
}

.sc-profile-cb-d4-Link {
  /* border: 1px solid black; */
  margin: 30px 0px 0px 10px;
}

.sc-profile-cb-d4-Link a {
  margin: 0px 0px 25px 0px;
  cursor: pointer;
  color: #4B4B4B;
  display: flex;
}

.sc-address {
  height: 27px;
  max-width: 80%;
  word-break: break-all;
  font-size: 12px;
  color: #706f6f;
}


/* ***** Soft Corner > Profile > Verify Identity *****  */

.sc-profile-vi {
  /* border: 1px solid black; */
  height: 100vh;
  /* width: 60%; */
  box-shadow: 0px 0px 5px gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sc-profile-vi-msg {
  /* border: 1px solid black; */
  width: 400px;
  text-align: center;
  margin: 50px 0px;
  color: #4B4B4B;
  font-size: 13px;
}

.sc-profile-vi-btn {
  color: white;
  background-color: #64A6F0;
  border-radius: 25px;
  padding: 10px 75px;
  font-size: 20px;
  margin: 0px 0px 100px 0px;
  cursor: pointer;
}


/* ***** Soft Corner > Profile > Address *****  */

.sc-profile-address {
  /* border: 1px solid black; */
  box-shadow: 0px 0px 5px gray;
}


/* *** Div 1 > Image ***  */

.sc-profile-address-d1 {
  /* border: 1px solid red; */
  width: 700px;
  margin: 50px auto;
}



/* *** Div 2 > Address ***  */

.sc-profile-address-d2 {
  /* border: 1px solid red; */
  width: 700px;
  margin: 50px auto;
}

.sc-profile-address-d2-all-label {
  /* border: 1px solid red; */
  margin: 25px 0px;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  box-shadow: 3px 3px 10px #00000017;
  cursor: pointer;
}

.sc-profile-address-d2-all-input {
  margin: 0px 20px;
}

.sc-profile-address-d2-all-icon {
  /* border: 1px solid pink; */
  text-align: center;
  padding: 0px 10px;
  width: 75px;
}

.sc-profile-address-d2-all-icon p {
  color: #64A6F0;
  margin: 5px 0px 0px 0px;
}

.sc-profile-address-d2-all-add {
  /* border: 1px solid black; */
  margin: 0px 20px 0px 15px;
  height: 40px;
  /* width: 450px; */
  overflow: hidden;
}

.sc-profile-address-d2-all-create {
  /* border: 1px solid black; */
  margin: 0px 10px;
  cursor: pointer;
}

.sc-profile-address-d2-all-delete {
  /* border: 1px solid black; */
  margin: 0px 10px;
  cursor: pointer;
}



/* *** Div 3 > Add New Address ***  */

.sc-profile-address-d3 {
  /* border: 1px solid black; */
  text-align: center;
}

.sc-profile-address-d3 img {
  margin: 0px 0px 10px 0px;
  cursor: pointer;
}





/* ***** Soft Corner > Profile > Remove *****  */

.sc-profile-remove {
  /* border: 1px solid black; */
  box-shadow: 0px 0px 5px gray;
}

.sc-profile-remove-profile {
  text-align: center;
  margin: 50px 0px 40px 0px;
}

.sc-profile-remove-profile img {
  /* border: 1px solid black; */
  border-radius: 50%;
  box-shadow: 0px 0px 10px 1px gray;
  cursor: pointer;
}

.sc-profile-remove-head {
  /* border: 1px solid black; */
  width: 700px;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
}

.sc-profile-remove-head span {
  color: #787C81;
}

.sc-profile-remove-head p {
  /* border: 1px solid black; */
  padding: 7px 15px;
  margin: 5px 0px 0px;
  width: 325px;
  border-radius: 30px;
  box-shadow: 3px 3px 10px #00000017;
}

.sc-profile-remove-rs {
  color: white;
  background-color: #64A6F0;
  padding: 5px 20px;
  margin: 20px 0px 0px 85px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 15px;
}

.sc-profile-remove-msg {
  height: 350px;
  width: 700px;
  /* border: 1px solid red; */
  margin: 30px auto 40px;
  overflow-y: scroll;
  border-radius: 10px;
  padding: 15px 20px;
  box-shadow: 3px 3px 10px 3px #00000017;
}

.sc-profile-remove-msg div {
  margin: 20px 0px;
}

.sc-profile-remove-msg div h5 {
  margin: 10px 0px;
  font-weight: 600;
}

.sc-profile-remove-msg div span {
  margin: 0px 0px 20px 20px;
}


.sc-profile-remove-tac {
  border: 1px solid #2676E1;
  border-radius: 15px;
  width: 700px;
  margin: 30px auto;
  padding: 10px 10px;
}

.sc-profile-remove-tac input {
  margin: 0px 17px 0px 10px;
}

.sc-profile-remove-tac label {
  margin: 0px;
  font-size: 15px;
}

.sc-profile-remove-btn {
  text-align: center;
}

.sc-profile-remove-btn span {
  /* border: 1px solid black; */
  color: white;
  background-color: #64A6F0;
  padding: 10px 100px;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
}

.popover-body {
  background-color: #f3f3f3;
}

.sc_popover_li {
  background-color: #f3f3f3;
}

.sc_popover_li li {
  padding: 2px 4px;
  cursor: pointer;
  margin: 2px 0px;
  border-radius: 5px;
}

.sc_popover_li li:hover {
  background-color: #ff6969;
  color: white;
  transition: 0.2s ease-in;
}

















.Sc-videoCarousel {
  width: 100%;
  margin: auto;
  padding: 10px;
}

.Sc-imgCarousel {
  width: 70%;
  margin: auto;
}

.dhx_cal_tab.active {
  background-color: #FF6969;
}

.dhx_scale_bar {
  background-color: #FF6969;
  color: snow;
  height: 30px !important;
  padding: 10px 0px !important;
}

.dhx_cal_data {
  top: 120px !important;
}

.dhx_cal_header {
  height: 40px !important;
  top: 80px !important;
  border-radius: 10px !important;
}

.dhx_scheduler_month {
  border-radius: 20px !important;
}

.dhx_cal_navline_flex {
  top: 15px !important;
}


.dhx_cal_navline.dhx_cal_navline_flex .dhx_cal_tab {
  border-radius: 15px !important;
  margin: 0px 5px !important;
  box-shadow: none !important;
}

.dhx_cal_tab,
.dhx_cal_tab.active {
  border: none !important;
}


.dhx_now .dhx_month_body,
.dhx_now .dhx_month_head {
  background-color: #ff6969;
  color: snow;
}

.text_post_sc {
  color: #777171;
  margin: 10px 0px;
}

.text_post_sc p {
  font-size: 14px !important;

}


.sc-cb-div-2-user-1-blank {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sc-cb-div-2-user-1-blank-img img {
  width: 200px;
}

.sc-cb-div-2-user-1-blank-head {
  font-weight: 400;
  color: #4B4B4B;
  margin: 25px 0px 10px;
}

.sc-cb-div-2-user-1-blank-msg {
  color: #BFBFBF;
  width: 37%;
  text-align: center;

}













/* softcorner - chat */


.scChatBoxMsg>span {
  position: relative;
}

.scChatBoxMsg>span>small {
  display: none;
}

.scChatBoxMsg:hover>span>small {
  display: block;
  position: absolute;
  right: -20px;
  bottom: 10px;
  z-index: 100;
  cursor: pointer;
  color: #74C0FC;
  font-size: 18px;
}

.scChatHeader {
  padding: 10px 30px;
  background: #ffffff;
  position: fixed;
  width: 75%;
  top: 75px;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ScChatProfile {
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  font-weight: 500;
}

.scChatProfileImage {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.scChatBox {
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  bottom: 65px;
}

.scChatBox span:first-child {
  background: #ff6969;
  padding: 15px 20px;
  border-radius: 12px 12px 12px 0px;
  font-size: 14px;
  max-width: 38%;

}

.sc-selected {
  border-radius: 10px;
  background-image: linear-gradient(to right, #d3d3d3, #f3f3f3);
}

.scPopover {
  position: relative;
  width: fit-content;
  background-color: #f3f3f3;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 102;
}

.scPopover li {
  padding: 5px 15px;
  font-size: 15px;
  cursor: pointer;
}

.scPopover li:hover {
  background-color: #d3d3d35b;
}

.right {
  display: flex;
  justify-content: flex-end !important;
  margin: 20px;
}

.typingLoader {
  position: absolute;
  top: 30px;
  left: 25px;

}

.footerArea {
  position: relative;
}

.footerArea .emoji-select {
  position: absolute;
  top: -450px;
}

.scReplyArea {
  background-color: #f3f3f3fd;
  position: absolute;
  top: -90px;
  width: calc(100% + 5%);
  right: -39px;
  height: 80px;
  max-height: 200px;
  z-index: 100;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.scReplyArea {
  padding: 0px 20px;
}

.scReplyClose {
  display: flex;
  /* justify-content: end; */
  justify-content: flex-end;
  margin-right: 50px;
  padding-top: 10px;
}

.scReplyClose svg {
  cursor: pointer;
}

.replyMessageBox {
  padding: 10px;
  border: 1px solid #d3d3d3;
  border-radius: 15px;
  background-color: #f8f6f6ab;
  color: #4e4c4c85;
  font-size: 14px;
}

.ScChatProfile>div {
  position: relative;
}

.scchatHeader {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
}

.scOnlineStatus-dot {
  position: absolute;
  left: 50px;
  top: 10px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: rgb(164, 248, 38);
  color: red;
}

.scOnline-name {
  display: flex;
  flex-direction: column;
}

.scOnline-check {
  color: rgb(109, 184, 4);
}

.sc-postBox {
  width: 520px;
}

.sc-postBox input {
  padding: 25px 0px;
}

.sc-postBox input::placeholder {
  padding: 50px 30px;
  color: #aaa5a5;
}






/* ***** SoftCorner > Create New Post Popup ***** */

.SC-postCarousel {
  height: 100%;
  width: 100%;
}

.SC-postCarousel .carousel-item {
  width: 100%;
  margin: auto;
  text-align: center;
}





/* -------------------- Notification -------------------- */

.scNotification {
  border: 1px solid black;
  display: flex;
}

.scNotification-lb {
  border: 1px solid red;
  width: 24%;
}

.scNotification-rb {
  border: 1px solid green;
  margin: 75px 0px 0px;
  width: 76%;
}

.scn-rb-notificationArea {
  border: 1px solid black;
  height: 100%;
  width: 40%;
  margin: 0px auto;
}

.sc-loader {

  width: 50px;
  margin: auto;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #eb331b 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, #ec4f27);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}

@keyframes l13 {
  100% {
    transform: rotate(1turn)
  }
}

.sc-loader-head {
  position: absolute;
  height: 100vh;
  background-color: white;
  top: 0px;
  width: 100%;
  margin: auto;
  z-index: 1000;
  margin: 100px;
}


/* ---SC chat--- */

.chatArea {
  height: 85vh;
}