@media only screen and (min-width: 992px) and (max-width:1288px) {

.lbfbp {
  height: 20px;
  width: 20px;
}

.followBox .innnerBox{
height: 65px;
}

.emilo-login-logo {
  width: 30% !important;
}


.set_width{
  max-width: 500px;
}

.section-main .left-block .followBox .innerBox{
          height: 70px;
}

.section-main .left-block .followBox .innerBox .userIcon{
          left: -5px;
}

.section-main .left-block .followBox .innerBox .userIcon b{
          width: 84px;
          height: 20px;
}

.section-main .left-block{
          padding: 100px 26px 0px 26px;
}

.footer-area{
          padding: 5px;
}

.footer-area .copyright a{
          font-size: 12px;
}

.left-icons{
          width: 25px;
          /* max-width: 20px; */
}

.section-main .left-block .main-menu ul li{
          margin-bottom: 20px;
          font-size: 14px;
}

.section-main .left-block .main-menu ul li a img{
          margin-right: 21px;
          max-width: 75%;
}

.section-main .left-block .followBox{
          padding: 27px 0px;
}

.section-main .left-block .profileBox h3{
font-size: 18px;
}

.section-main .left-block .profileBox span{
font-size: 14px;
}

.section-main .left-block .profileBox .imgBox img{
          width: 50%;
}

.cb-story-1{
          width: 11%;
          height: 13%;
}

.cb-story-1-img{
          width: 100%;
          height: 13%;
}

.cb-story-1-profile{
          width: 36%;
    height: 15%;
    bottom: 9px;
}

.section-main .center-block .friendBox .innerBox .imgBox img{
          width: 57%;
    height: 61%;
    margin-bottom: 14px;
}

.section-main .center-block .friendBox .innerBox .imgBox .userIcon img{
          bottom: 4px;
}

.section-main .center-block .headBlock p{
          font-size: 12px;
}

.section-main .center-block .headBlock{
          /* display: inline; */
}

.cbs-1-toggle-fav-head-seeAll{
          margin-left: 0px;
}

.section-main .center-block{
          padding: 75px 17px 0px 16px;
}

.cb-post-head-name{
          font-size: 20px;
}

.followUnfollowBtn{
          font-size: 12px;
          padding: 2px 10px;
          width: 75px;
          height: 30px;
}

.cb-story-2-lius-story{
          width: 72%;
          height: 74%;
}

.cb-story-2-lius-name , .cb-story-2-fus-name{
top: 107px;
font-size: 12px;
word-wrap: normal;
left: 6px;
}

.cb-story-2-fus-story{
          width: 85%;
}

.cb-story-2-fus-story{
          height: 139px;

}

.section-main .center-block .feedBox{
          margin: 10px;
}

.textPost {
  margin: 10px;
}

.main-modal .modal-body .linkBox{
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding: 5px 0px;
}

.main-modal .modal-body h6{
  margin-bottom: 7px;
}

.you_follow{
  font-size: 14px !important;
}

.trendingFeedsImage{
  height: 80px;
}

/* .section-main .right-block .peopleBox .contentBox .blockOne{
  align-items: flex-start;
} */

.section-main .right-block{
  padding: 90px 10px 0px;
}

.nameBox>a{
font-size: 17px;
}

.eam-rb-email-otp,.eam-rb-email-otp-verified  {
  position: absolute;
  right: 15%;
  top: 15px;
  font-size: 12px;
}

.eam-rb-phone-otp,.eam-rb-mobile-otp-verified {
  right: 15%;
  bottom: 27%;
  font-size: 12px;
}

.show1,.show2 {
  right: 18%;
  position: absolute;
  top: 25%;
  cursor: pointer;
}


.section-main .right-block .peopleBox .contentBox .blockOne .nameBox p{
  /* display: none; */
  font-size: 10px;
  
}
.suggestion{
  height: fit-content
}

.section-main .right-block .peopleBox{
  margin-bottom: 10px;
}

.suggestionImage {
  height: 40px;
  width: 40px;
}

.suggestionImage img {
  height: 40px;
  width: 40px;
}

.section-main .center-block .feedBox .userArea .userDetail .nameBox p {
  font-size: 10px;
  color: #727272;
  margin: 0;
}

.password_info{
  width: 70%;
    text-align: start;
    margin: auto;
    font-size: 12px;
}

.address{
  display: inline;
}

.textArea>p{
  font-size: 10px !important;
}

.profile-user-1 {
  width: 100%;
  /* background-color: blue; */
  margin: auto;
  margin-top: 80px;
  margin-bottom: 0px;
}



.PhoneInput .PhoneInputCountry{
  bottom: 6px;
}

}