@media only screen and (min-width: 1200px) {
  /* ---------- Login ---------- */

  .login-logo {
    height: 55px;
  }


  /* ---------- Explore ---------- */

  .explore-sec {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 20px;
    padding-inline: 20px;
  }


  /* ---------- Home ---------- */

  /* ---------- Home > Left Block ---------- */

  .lb-ffp {
    width: 70px;
  }


  /* ---------- Suggested Flix ---------- */

  .suggested-flix-img {
    height: 150px;
  }




  /* ----------- Softcorner > Left Block ----------- */

  .sc-lb-div-1 {
    width: 80%;
  }

  .sc-lb-div-3 {
    width: 50%;
  }




  /* ----------- Softcorner > Notifications ----------- */

  .sc-notifications {
    width: 60%;
  }




  /* ----------- Softcorner > Gift ----------- */

  .sc-products {
    grid-template-columns: auto auto auto auto;
  }



  /* ----------- Business > Home ----------- */

  .business-lb {
    width: 25%;
  }

  .business-rb {
    width: 75%;
  }
  
  .business-home-photos {
    grid-template-columns: auto auto auto;
  }

  .business-home-tagged {
    grid-template-columns: auto auto auto auto;
  }

  .business-home-reviews {
    grid-template-columns: auto auto auto;
  }




  /* ----------- Business > Ads > Create Post ----------- */

  .business-create-post {
    grid-template-columns: auto auto auto;
    gap: 25px;
  }




  /* ----------- Business > Insights ----------- */

  .insights-page-overview {
    grid-template-columns: auto auto auto;
    gap: 20px;
  }





    





}
