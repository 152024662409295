.toggle-button {
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: grey;
    transition: background-color 0.3s ease;
  }
  
  .toggle-button.on {
    background-color: green;
  }
  
  .toggle-button.off {
    background-color: red;
  }
  .pull-right {
    float: right;
  }
  .two_facetor_type {
    cursor: pointer;
  }
  .TwoFactorMobile input {
    border-radius: 0px;
  }