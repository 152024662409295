.gif-head{
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 50px; */
  align-items: flex-end;
}

.gif-head h6{
  cursor: pointer;
}

.gif-input{
  width: 100%;
}

.gif-input input{ 
  width: 80%;
 margin: auto;
 display: flex;
 justify-content: center;
 padding: 6px 10px;
 border-radius: 10px;
 border: 0px;
 outline: none;
 box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.gif-input input::placeholder{
  font-style: italic;
}

.gif-toggle-FA{
  background-color: rgba(26, 22, 22, 0.92);
  color: aliceblue;
  padding: 10px;
  border-radius: 10px;
}


.gif-not-toggle{
  background-color: rgba(246, 244, 244, 0.92);
  color: black;
  padding: 10px;
  border-radius: 10px;
}

.gif-body{
  margin-top: 10px;
 display: grid;
 grid-template-columns: auto auto auto;
 height: 400px;
 overflow: hidden;
 overflow-y: scroll;
 width: 100%;
 justify-content: flex-start;
 align-items: start;
}

.gif-body::-webkit-scrollbar{
  background-color: transparent;
  width: 0px;
}

.gif-boxes{
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  margin: 5px;
  height: 40px;
  padding: 5px;
  background-color: rgba(255, 250, 250, 0.573);
  color: black;
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.317);
  width: 142px;
  font-size: 14px;
 
}