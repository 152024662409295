body {
    background-color: #ddd;
}
.actionElements {
    padding: 20px 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    border: 1px solid #ddd;
    transition: height 0.3s ease;
}
.actionElements:hover {
    overflow: auto;
}
.actionElements .glasses {
    display: inline-block;
    border: 1px solid #f5f5f5;
    padding: 5px;
    width: 33%;
}
.actionElements .glasses img {
    height: 80px;
    width: 100%;
    max-width: 150px;
    cursor: pointer;
    object-fit: contain;
}
.actionElements .masks {
    display: inline-block;
    border: 1px solid #f5f5f5;
    padding: 5px;
    width: 33%;
}
.actionElements .masks img {
    height: 150px;
    width: 100%;
    cursor: pointer;
    object-fit: contain;
}
.bgImg {
    width: 33%;
    text-align: center;
    padding: 5px;
    border: 1px solid #dedede;
    margin-bottom: 5px;
    cursor: pointer;
    display: inline-block;
}
.bgImg img {
    object-fit: cover;
    height: 90px;
    width: 100%;
    max-width: 200px;
}
.bgImg span {
    font-weight: 700;
    letter-spacing: 1px;
    color: #ed1e24;
}

/* For devices with a maximum width of 768px (like tablets and smaller screens) */
@media (max-width: 768px) {
    .actionElements:hover {
        height: auto; /* Set the height on hover */
    }
}
.clearBoth {
    clear: both;
    width: 100%;
}
.skinBtn {
    border: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 5px 10px;
    margin-right: 10px;
}
.lipsBtn {
    border: 0px;
    padding: 5px 10px;
    display: inline-block;
}
.canvasCreateARVR, .LoaderARVR {
    object-fit: cover !important; 
}
.videoCreateARVR {
    object-fit: contain !important;
    transform: scale(0.1);
}
.canvasCreateARVR, .videoCreateARVR, .LoaderARVR {
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh;
  z-index: 999997;
}
.actionMenu {
    z-index: 999998;
    background: #fff;
    bottom: 0px;
    right: 0px;
    left: 0px;
    position: fixed !important;
}
.actionContent {
    z-index: 999999;
    background: #fff;
    top: 0px;
    right: 0px;
    left: 0px;
    max-height: 100vh;
    overflow: auto;
    position: fixed !important;
    transition: width 1s ease; /* Adjust duration and timing function */
}
.ACWidth100 {
    width: 30% !important;
    min-width: 300px !important;
}
.ACWidth0 {
    width: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
}
.actionContentShowBtn {
    z-index: 999999;
    position: fixed;
    top: 15%;
    left: 0px;
    background-attachment: fixed;
    background: black;
    color: #fff;
    font-size: 20px;
    font-weight: 900;
    width: fit-content !important;
}
.actionContentHideBtn {
    position: relative;
    height: 10px;
}
.actionContentHideBtn a {
    z-index: 999999;
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 20px;
    font-weight: 900;
    color: red;
}
.customBgInp {
    background: rgb(96, 31, 31);
    box-shadow: none;
    border: 0px;
    width: 100%;
    border-radius: 0px;
    min-height: 40px;
    cursor: pointer;
}
.tab-content-arvr {
    max-width: none !important;
    max-height: none !important;
    overflow: auto !important;
}
.text-align-right {
    text-align: right;
}