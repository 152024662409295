@media only screen and (min-width: 1400px) {
  /* ---------- Login ---------- */

  .login-logo {
    height: 60px;
  }



  /* ---------- Explore ---------- */

  .explore-sec {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 20px;
    padding-inline: 20px;
  }


  /* ---------- Home > Right Block ---------- */

  .rb-profile {
    height: 45px;
    width: 45px;
  }

  .rb-name {
    font-size: 17px;
    color: black;
  }

  .rb-fby {
    font-size: 13px;
    color: gray;
  }


  /* ***** Follow Unfollow Button ***** */

  .followUnfollowBtn {
    font-size: 10px;
    height: 25px;
    width: 60px;
    color: #fff;
    border-radius: 7px;
    border: none;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: #64A6F0;
    align-items: center;
    transition: all;
  }

  .followUnfollowBtn2{
    font-size: 10px;
    height: 25px;
    width: 60px;
    color: #fff;
    border-radius: 7px;
    border: none;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: #64A6F0;
    align-items: center;
    transition: all;
  }

  .followUnfollowBtn3{
    font-size: 10px;
    height: 25px;
    width: 60px;
    color: #000000;
    border-radius: 7px;
    border: none;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: #b0c0c4cc;
    align-items: center;
    transition: all;
  }

  /* ---------- Suggested Flix ---------- */

  .suggested-flix-img {
    height: 200px;
  }


  /* ----------- Softcorner > Left Block ----------- */

  .sc-lb-div-1 {
    width: 70%;
  }




  /* ----------- Softcorner > Center Block > User Card ----------- */

  .sc-cb-user-cards {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 25px;
    margin-inline: 25px;
  }






  /* ----------- Softcorner > Notifications ----------- */

  .sc-notifications {
    width: 50%;
  }




  /* ----------- Softcorner > Gift ----------- */

  .sc-products {
    grid-template-columns: auto auto auto auto auto;
  }



  /* ----------- Business > Home ----------- */

  .business-lb {
    width: 20%;
  }

  .business-rb {
    width: 80%;
  }

  .business-home-photos {
    grid-template-columns: auto auto auto auto;
  }

  .business-home-tagged {
    grid-template-columns: auto auto auto auto auto;
  }




  /* ----------- Business > Account Setting ----------- */

  .as-gs-pr-users {  /* Account Setting > General Setting > Page Roles > users  */
    grid-template-columns: auto auto auto;
  }





  /* ----------- Business > Ads > Create Post ----------- */

  .business-create-post {
    grid-template-columns: auto auto auto auto;
    gap: 30px;
  }



  /* ----------- Business > Insights ----------- */

  .insights-page-overview {
    grid-template-columns: auto auto auto auto;
    gap: 20px;
  }

  .business-insights-posts {
    grid-template-columns: auto auto auto;
    gap: 20px;
  }





  /* ----------- Business > Events ----------- */

  .business-events {
    grid-template-columns: auto auto auto;
    gap: 20px;
  }







}