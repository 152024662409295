@media only screen and (min-width: 0px) and (max-width: 425px) {


main  /* ***** Emilo ***** */



  /* ***** Login And Signup ***** */

  .login-area {
    /* border: 1px solid black; */
    height: 100%;
  }

  .location_max{
    max-width: 250px;
  }

  .location_name{
    width: 28%;
  }


  .login_left {
    display: none;
  }

  .gap-post{
    gap: 25px;
}


.set_width{
  max-width: 50% !important;
}

.create-post .userDetail{
  /* left: -40px; */
}

.inspiration_list_div {
  max-width: 80%;
  position: absolute;
  top: 81%;
  background-color: white;
  z-index: 10;
  max-height: 200px;
  overflow-y: scroll;
}

.inspiration_list {
  border: 1px solid #f3f3f3;
}

.inspiration_list li {
  margin: 10px 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  font-size: 12px;
}


.inspiration_img {
  max-width: 12%;
}

.inspiration_img img {
  border-radius: 50%;
}

.create-post .iconBox div{
  font-size: 9px;
}

.create-post .iconBox div img{
  margin-bottom: 5px;
    max-width: 18px;
}

.nameBox h6{
  margin: 10px 0px;
  text-align: center;
}
.css-13cymwt-control #react-select-2-placeholder{
  max-width: 200px !important;
  overflow: hidden !important;
}

  .login_right_section>div:first-child {
    /* border: 1px solid black; */
    height: 50px;
    width: 100%;
    padding: 0px;
    /* margin: 75px 0px; */
  }
  

  .nameReg {
    padding: 0px 30px;
  }

  .nameReg-left {
    display: none;
  }

  .nameReg-right {
    padding: 0px;
  }

  .usernameForm {
    /* border: 1px solid green; */
    width: 100%;
    margin: 0px;
  }


  .nameReg-right-header {
    font-size: 23px;
    margin: 20px 0px;
  }

  .btn-box-mob{
    background-color: #64a6f0;
    color: white;
    font-size: 14px;
    border-radius: 20px;
    padding: 2px 5px 0px 5px;
    
}
  /* .nameReg-right-form > div >span> input, .PhoneInputInput {
    padding: 20px;
    width: 375px;
  } */

  .dobForm .dateOfBirth {
    width: 250px;
    padding: 20px;
  }

  .usernameForm > button {
    padding: 10px 0px;
    width: 75%;
    margin: 25px 0px 0px 13%;
  }

  /* .emailAndMobile {
    border: 1px solid black;
  } */

  .emailAndMobile-lb {
    display: none;
  }

  .eam-rb-email-otp,.eam-rb-email-otp-verified  {
    position: absolute;
    right: 5%;
    top: 15px;
    font-size: 12px;
  }

  .eam-rb-phone-otp,.eam-rb-mobile-otp-verified {
    right: 5%;
    bottom: 27%;
    font-size: 12px;
  }

  .eam-rb-header {
    font-size: 23px;
    margin: 0px 0px 25px 0px;
  }

  .eam-rb-email {
    height: 40px;
    width: 375px;
    border-radius: 25px;
    padding: 23px 20px;
  }

  /* .PhoneInput .PhoneInputCountry {
    margin: 0px 10px 13px;
  } */

  .genFormGroup,
.gendRadioBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px;
  border-radius: 30px;

}

  .eam-rb-form > div >span {
    left: 80%;
    bottom: 65px;
    width: 20%;
    font-size: 11px;
  }

  /* .eam-rb-form > div >span> input, .PhoneInputInput {
    padding: 15px 75px;
  } */

  /* .codeVerification {
    border: 1px solid black;
  } */

  .codeVerification-lb {
    display: none;
  }

  .cv-rb-logo {
    height: 45px;
    width: 125px;
    margin: 75px auto;
  }

  .code-verify button {
    width: 70%;
    padding: 10px 100px;
  }

  /* .createPassword {
    border: 1px solid black;
  } */

  .createPassword-lb {
    display: none;
  }

  .emilo-login-logo {
    width: 40%;
  }

  .cp-rb-header {
    font-size: 23px;
    margin: 0px 0px 10px 0px;
  }

  .cp-rb-ul {
    padding: 0px 0px 20px 20px;
    margin: 0px;
  }

  .cp-rb-input {
    margin: 0px 0px 20px 0px;
    border-radius: 30px;
    padding: 20px;
  }

  .show1,.show2 {
    right: 8%;
    position: absolute;
    top: 25%;
    cursor: pointer;
  }

   .password_info{
    width: 90%;
      text-align: start;
      margin: auto;
      font-size: 12px;
  }

    .cp-rb-form > div >span {
    width: 7%;
  }

  .userName {
    /* border: 1px solid black; */
    padding: 0px;
  }

  .userName-lb {
    display: none;
  }

  .emiloLogo-username {
    height: 45px;
    width: 125px;
    margin: 50px auto;
  }

  .signupProfilePic {
    width: 95%;
  }

  .signupProfilePic h6 {
    font-size: 23px;
    margin-bottom: 13px;
  }

  .signupProfilePic > div > span {
    height: 50px;
    width: 50px;
  }

  .usernameForm > h6 {
    font-size: 20px;
    margin: 50px 0px 10px;
  }

  .un-rb-input {
    height: 40px;
    border-radius: 20px;
    padding: 20px;
  }

  .usernameForm .check {
    width: 10px;
    top: 7px;
    left: 90%;
  }

  .text-success {
    padding: 0px 20px;
  }






  /* ***** Home ***** */

  .header-area, .left-block, .right-block {
    display: none;
  }

  .section-main .center-block {
    margin-top: 0px;
    padding: 0px;
  }

  .section-main .center-block .headBlock {
    margin: 0px;
  }


  /* ***** Header ***** */

  .emilo-mobile-header {
    /* border: 1px solid black; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px 5px;
    box-shadow: 0px 0px 3px 0px gray;
    position: fixed;
    top: 0px;
    z-index: 10;
    width: 100vw;
    background-color: white;
    margin: 0px;
  }

  .emilo-mobile-header-profile {
    height: 30px;
    width: 30px;
  }

  .emilo-mobile-header-logos {
    width: 35%;
    text-align: center;
  }

  .emilo-mobile-header-logo {
    height: 30px;
    width: 85px;
  }

  .emilo-mobile-header-search, .emilo-mobile-header-nortification {
    /* border: 1px solid black; */
    padding: 1px 7px;
    border-radius: 50%;
    /* background-color: #F2F2F2; */
  }

  .emilo-mobile-header-search-img, .emilo-mobile-header-nortification-img {
    text-align: center;
    margin-bottom: 3px;
  }

  .offcanvas.offcanvas-end {
    width: 100vw;
    border: none;
  }

  .emilo-mobile-header-search-toggle-logo {
    height: 35px;
    width: 100px;
    margin: 0px 20px 0px 0px;
  }

  .emilo-mobile-header-search-toggle-input {
    /* border: 1px solid red; */
    height: 675px;
    overflow: scroll;
  }

  .emilo-mobile-header-search-toggle-input .input-box {
    border: 1px solid rgb(184, 181, 181);
    margin: 0px 0px 10px 0px;
    position: sticky;
    top: 0px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 0px 3px 0px rgb(201, 199, 199);
  }

  .emilo-mobile-header-search-toggle-input .input-box img {
    border: none;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin: 5px 13px 10px 13px;
  }

  .emilo-mobile-header-search-toggle-input .input-box input {
    border: none;
    height: 40px;
    width: 83%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 0px 5px;
    font-size: 17px;
  }

  .home-outlet {
    margin: 60px 0px 0px;
  }


  /* ***** Footer ***** */

  .emilo-mobile-footer {
    /* border: 1px solid black; */
    width: 100vw;
    background-color: white;
    text-align: center;
    border-radius: 100px;
    position: fixed;
    bottom: 1px;
    z-index: 10;
    box-shadow: 0px 0px 3px 0px #bdbdbd;
    padding: 5px;
    display: block;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .emilo-mobile-footer-div {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
  }

  .emilo-mobile-footer-div:hover {
    background-color: rgb(236, 235, 235);
    padding: 5px;
  }

  .emilo-mobile-footer-img {
    margin: 0px 0px 5px 0px;
  }

  .emilo-mobile-footer-img-flix {
    margin: 0px 0px 7px 0px;
    height: 22px;
  }

  .emilo-mobile-footer-img-bestClip {
    height: 28px;
  }

  .emilo-mobile-footer-img-chat {
    margin: 0px 0px 10px 0px;
  }

  .emilo-mobile-footer-name {
    font-size: 11px;
    color: black;
  }

  .cb-stories-1 {
    width: 95%;
    margin: 0px auto;
  }

  .cb-story-1 {
    /* border: 1px solid green; */
    margin: 0px;
  }

  .section-main .center-block .headBlock button {
    font-size: 13px;
  }

  .cb-story-1-img {
    height: 50px;
    width: 50px;
  }

  .cb-story-1-profile {
    height: 20px;
    width: 20px;
    bottom: 10px;
  }

  .cb-story-1-name {
    font-size: 13px;
  }

  .cbs-1-toggle {
    width: 95%;
    margin: 0px auto;
  }

  .cbs-1-toggle-fav-head-seeAll, .cbs-1-toggle-cf-head-seeAll {
    margin-left: 0px;
  }

  .centerBlockStory1Popup, .centerBlockStory2Popup {
    width: 345px;
  }

  .centerBlockStory1Popup-area-comment input, .centerBlockStory2Popup-area-comment input {
    width: 225px;
  }

  .centerBlockStory1Popup-area-like {
    left: 260px;
  }

  .centerBlockStory1Popup-area-share {
    left: 300px;
  }

  .accordion {
    margin: 10px auto;
  }

  .section-main .center-block .headBlock h5 {
    font-size: 15px;
    font-weight: 500;
  }

  .section-main .center-block .friendBox .innerBox {
    /* border: 1px solid red; */
    margin-right: 5px;
  }

  .section-main .center-block .friendBox .innerBox .imgBox img {
    height: 50px;
    width: 50px;
    margin-bottom: 13px;
  }

  .section-main .center-block .friendBox .innerBox .imgBox .userIcon {
    bottom: -20px;
  }

  .section-main .center-block .friendBox .innerBox .imgBox .userIcon img {
    height: 23px;
    width: 23px;
  }

  .section-main .center-block .friendBox .innerBox span {
    width: 55px;
    text-align: center;
    height: 22px;
    overflow: hidden;
  }

  .cbs-2-seeAll {
    font-size: 13px;
  }

  .cbs-2 {
    /* border: 1px solid black; */
    height: 150px;
    width: 95%;
    margin: 0px auto;
  }

  .cb-story-2-lius {
    /* border: 1px solid black; */
    height: 110px;
    width: 65px;
    margin: 5px 5px 0px 0px;
  }

  .cb-story-2-lius-stories {
    width: 65px;
  }

  .cb-story-2-lius-story {
    height: 110px;
    width: 65px;
  }

  .cb-story-2-lius-add {
    height: 25px;
    width: 25px;
    padding: 5px;
    top: 5px;
    left: 5px;
  }

  .cb-story-2-lius-name {
    font-size: 9px;
    color: white;
    top: 90px;
    left: 3px;
  }

  .cb-story-2-fus {
    /* border: 1px solid red; */
    height: 125px;
  }

  .cb-story-2-fus-story {
    height: 110px;
    width: 65px;
  }

  .cb-story-2-fus-profile {
    height: 20px;
    width: 20px;
    top: 7px;
    left: 7px;
  }

  .cb-story-2-fus-name {
    font-size: 13px;
    left: 5px;
    width: 50px;
    bottom: 5px;
  }

  .cbs-2-hr {
    background-color: gray;
    width: 93%;
    margin: 0px auto 13px;
  }

  /* .cb-post {
    border: 1px solid black;
  } */

  .section-main .center-block .feedBox {
    /* border: 1px solid red; */
    width: 93%;
    margin: 0px auto;
  }

  .section-main .center-block .feedBox .userArea {
    padding: 0px;
  }

  .section-main .center-block .feedBox .userArea .userDetail .imgBox {
    width: 45px;
    margin-right: 15px;
  }

  .section-main .center-block .feedBox .userArea .userDetail .imgBox img {
    height: 45px;
    width: 45px;
  }

  .cb-post-head-name {
    font-size: 15px;
    margin: 0px 10px 0px 0px;
  }

  .cb-post-head-aen, .cb-post-head-fen {
    /* border: 1px solid black; */
    font-size: 13px;
  }

  .section-main .center-block .feedBox .userArea .userDetail .nameBox p span {
    /* border: 1px solid red; */
    margin: 0px 10px 0px 0px;
    padding: 0px;
  }

  .followUnfollowBtn {
    font-size: 13px;
    padding: 0px;
    height: 30px;
    width: 75px;
  }

  .main-btn.small {
    line-height: 0px;
  }

  .section-main .center-block .feedBox .contentArea {
    margin: 13px 0px;
  }

  .viewPost .tagsPost {
    height: 30px;
    width: 30px;
    top: 5px;
    right: 5px;
    padding: 8px;
  }

  .section-main .center-block .feedBox .likeArea {
    padding: 0px;
  }

  .section-main .center-block .feedBox .likeArea .leftBox span {
    margin: 0px 20px 0px 0px;
  }

  .section-main .center-block .feedBox .likeArea .rightBox span {
    margin: 0px 20px 0px 0px;
  }

  .cb-post-hr {
    margin: 10px 0px 13px;
  }

  .main-modal .popupModal-body {
    height: 85vh;
    overflow: auto;
    overflow-y: scroll;
  }
  
  .main-modal .popupModal-body::-webkit-scrollbar {
    width: 0px;
  }


  .viewPostBtn, .viewReelBtn, .viewBestClipBtn, .viewMemeBtn {
    height: 15px;
    /* height: 23px; */
  }

  .likePostBtn, .likeMemeBtn, .likeBestClipBtn, .likeReelBtn {
    height: 25px;
    /* height: 23px; */
  }

  .commentBtn-span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .commentBtn {
    height: 23px;
  }

  .commentBtnWeb {
    display: none;
  }

  .commentBtnMobile {
    display: block;
  }

  .offcanvas.showing, .offcanvas.show:not(.hiding) {
    height: 92vh;
    width: 100% !important;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: white;
  }

  .offcanvas-body::-webkit-scrollbar {
    width: 0px;
  }

  .mc-show {
    /* border: 1px solid black; */
    height: 74vh;
    margin: 0px 0px 15px 0px;
    overflow-y: scroll;
  }

  .mc-show::-webkit-scrollbar {
    width: 0px;
  }

  .mc-show-user {
    /* border: 1px solid green; */
    display: flex;
    /* align-items: center; */
    margin-bottom: 20px;
  }

  .mc-show-user-profile {
    /* border: 1px solid black; */
    border-radius: 50%;
    margin: 0px 13px 0px 0px;
    height: 45px;
    width: 45px;
  }

  .mc-show-user-profile img {
    border-radius: 50%;
  }

  .mc-show-user-name {
    font-weight: 700;
  }

  .mc-show-user-time {
    margin: 0px 13px;
  }

  .mc-show-user-comment {
    /* border: 1px solid black; */
    max-width: 65vw;
    font-size: 15px;
  }

  .mc-show-user-reply {
    font-weight: 500;
    color: rgb(45, 45, 45);
  }

  .mc-create {
    border: 1px solid #c9c7c7;
    width: 92vw;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    background-color: white;
    position: absolute;
    bottom: 10px;
  }

  .mc-create-form input {
    background-color: transparent;
    margin: 10px 0px 0px;
    width: 55vw;
    border: none;
  }

  .mc-create-comment {
    color: #2676e1;
  }

  .shareBtn {
    height: 23px;
    /* height: 23px; */
  }

  .favouritePostBtn, .favouriteReelBtn, .favouriteMemeBtn, .favouriteBestClipBtn {
    height: 23px;
    /* height: 23px; */
  }

  .downloadBtn {
    height: 23px;
    /* height: 23px; */
  }

  .section-main .center-block .feedBox .textArea p {
    font-size: 13px;
    line-height: 17px;
  }

  .profile-user-1 {
    margin: 75px 0px 0px;
  }
  
  .profile-user-2 {
    width: 400px;
    margin: 0px auto;
  }
  
  .profile-user-3 {
    display: block;
    padding: 20px;
  }

  .profile-user-3-back {
    display: none;
  }

  .profile-user-3-profile {
    text-align: center;
  }

  .profile-user-3-profile-img {
    border: 1px solid red !important;
    height: 30%;
    width: 30%;
  }

  .profile-user-3-data {
    text-align: center;
  }

  /* .profile-user-3-edit {
    display: none;
  } */

  .profile-user-3-btn {
    text-align: center;
    margin: 20px 0px 0px;
  }

  .profile-user-hr {
    margin: 0px;
  }

  .profile-nav {
    width: 97%;
    margin: 5px auto;
    padding: 5px;
  }

  .profile-nav img {
    height: 25px;
  }

  .profile-nav p {
    font-size: 10px;
  }

  .profile-nav-ul {
    width: 100%;
  }

  .profile-nav-li-t {
    width: 80px;
    padding: 5px 0px;
  }

  .profile-nav-li-f {
    padding: 5px 0px;
  }

  .profile-nav-ul-div {
    padding: 0px;
  }

  .profilePosts {
    width: 425px;
  }

  .profilePost-post {
    height: 120px;
    width: 120px;
    margin: 0px 10px 20px;
  }

  .profilePosts-post-icon {
    height: 17px;
    width: 23px;
    top: 5px;
    right: 5px;
  }

  .profilePosts-post-icon-img {
    height: 10px;
    width: 13px;
    margin-bottom: 11px;
  }

  .profileReels {
    /* border: 1px solid black; */
    width: 97%;
    margin: 0px auto;
    grid-gap: 0px;
  }

  .profileReels-reel {
    /* border: 1px solid black; */
    height: 150px;
    width: 115px;
    margin: 0px auto 20px;
  }

  .profile-videos {
    /* border: 1px solid black; */
    width: 425px;
  }

  .profile-videos-div {
    height: 120px;
    width: 120px;
    margin: 0px 10px 20px;
  }

  .profileBestClips {
    /* border: 1px solid black; */
    width: 425px;
  }

  .profileBestClips-bestClip {
    height: 120px;
    width: 120px;
    margin: 0px 10px 20px;
  }
  
  .profileBestClips-bestClip-image {
    height: 120px;
    width: 120px;
    margin: 0px 10px 20px;
  }

  .profileMemes {
    /* border: 1px solid black; */
    width: 425px;
  }

  .profileMemes-meme {
    height: 120px;
    width: 120px;
    margin: 0px 10px 20px;
  }

  .profileMemes-image, .profileMemes-video {
    height: 120px;
    width: 120px;
    margin: 0px 10px 20px;
  }


  /* ***** Memes ***** */

  .memes {
    display: none;
  }

  .masonary {
    padding: 5px;
    text-align: center;
  }

  .section-main .center-block .explore-block .imgBox {
    height: 25%;
    width: 90%;
    box-shadow: 0px 0px 3px 0px rgb(209, 207, 207);
  }

  .section-main .center-block .explore-block .imgBox .iconBox {
    top: 7px;
    right: 7px;
    height: 20px;
    width: 25px;
    border-radius: 7px;
    background-color: rgb(171 170 170 / 60%);
  }

  .section-main .center-block .explore-block .imgBox .iconBox img {
    height: 13px;
    width: 13px;
  }




  /* ***** Mobile > Memes ***** */

  .mobile-memes {
    display: block;
    /* border: 1px solid black; */
    height: 90vh;
    overflow-y: scroll;
    padding: 10px;
  }

  .mobile-memes::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }

  /* .mm-meme {
    border: 1px solid green;
  } */

  .mmm-user {
    /* border: 1px solid pink; */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mmm-user-profile {
    /* border: 1px solid red; */
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  .mmm-user-profile img {
    border-radius: 50%;
  }

  .mmm-user-header {
    /* border: 1px solid black; */
    width: 60%;
    font-size: 14px;
  }

  .mmm-user-name {
    font-size: 15px;
    font-weight: 700;
    margin: 0px 10px 0px 0px;
  }

  .mmm-user-location {
    margin: 0px 10px 0px 0px;
  }

  .mmm-user-fubtn {
    font-size: 13px;
    padding: 0px;
    height: 30px;
    width: 75px;
  }

  .mmm-show {
    border: 1px solid rgb(207, 207, 207);
    height: 400px;
    max-height: 400px;
    max-width: 100%;
    text-align: center;
    background-color: whitesmoke;
    border-radius: 5px;
    margin: 10px 0px;
    padding: 7px;
  }

  .mmm-show img {
    height: 100%;
    /* width: 100%; */
  }

  .mmm-area {
    /* border: 1px solid brown;   */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0px 10px;
  }

  .mmm-area-share {
    margin: 0px 40px 0px 0px;
  }

  .mmm-area-view img {
    height: 17px;
    margin: 0px 5px 0px 0px;
  }

  .mmm-area-like img, .mmm-area-comment img, .mmm-area-share img {
    height: 25px;
    margin: 0px 5px 0px 0px;
  } 

  .mmm-area-favourite img, .mmm-area-download img{
    height: 25px;
  } 

  .mmm-description {
    font-size: 13px;
    color: #6E6E6E;
    max-height: 63px;
    overflow-y: scroll;
  }

  .mmm-description::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }














  /* ***** Reels ***** */

  .reel {
    margin: 60px 0px 0px;
  }
  
  .reel-section {
    /* border: 1px solid green; */
    height: 100vh;
    width: 100vw;
  }

  .reel-section::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }

  .reel-section .reelCount {
    height: 100%;
  }

  .carouselSlider .main {
    /* border: 1px solid red; */
    width: 100%;
    margin: 50px auto 65px;
  }

  .reels-carousel {
    width: 100%;
  }

  .reelsVideo {
    margin: 0px auto;
    height: 100%;
    width: 100vw;
    border-radius: 0px;
  }

  .reels-area {
    top: 27px;
    left: 90%;
    z-index: 1;
    color: whitesmoke;
  }

  .leftArea {
    width: 25px;
    gap: 25px;
  }

  .reel-details {
    width: 100vw;
    padding: 5px;
  }

  .reel-details-name {
    z-index: 1;
  }

  .reel-details-name img {
    height: 37px;
    width: 37px;
  }

  .reel-details-name .followUnfollowBtn {
    height: 25px;
    width: 65px;
  }






  


  /* ***** BestClips ***** */

  .bestClips {
    display: none;
  }

  .bestclips {
    margin: 50px 0px 0px;
  }

  .bestClips-categories {
    position: fixed;
    top: 60px;
    z-index: 1;
    background-color: whitesmoke;
  }

  .bestClips-categories-Span {
    /* border: 1px solid black; */
    font-size: 13px;
    padding: 3px 7px;
  }






  /* ***** Mobile > BestClips ***** */

  .mobile-bestClips {
    display: block;
    /* border: 1px solid black; */
  }

  .mbc-cat {
    /* border: 1px solid green; */
    display: flex;
    /* justify-content: space-evenly; */
    align-items: center;
    overflow-x: scroll;
    padding: 10px 5px 0px 0px;
  }

  .mbc-cat::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }

  .mbc-cat-span {
    /* border: 1px solid black; */
    text-align: center;
    color: white;
    background-color: #64A6F0;
    margin: 0px 5px;
    padding: 3px 15px;
    font-size: 13px;
    border-radius: 15px;
    height: 27px;
  }

  .mobile-bestClips {
    display: block;
    /* border: 1px solid black; */
    height: 90vh;
    overflow-y: scroll;
    padding: 0px 10px;
  }

  .mobile-bestClips::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }

  .mbc-user {
    /* border: 1px solid pink; */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mbc-user-profile {
    /* border: 1px solid red; */
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  .mbc-user-profile img {
    border-radius: 50%;
  }

  .mbc-user-header {
    /* border: 1px solid black; */
    width: 60%;
    font-size: 14px;
  }

  .mbc-user-name {
    font-size: 15px;
    font-weight: 700;
    margin: 0px 10px 0px 0px;
  }

  .mbc-user-location {
    margin: 0px 10px 0px 0px;
  }

  .mbc-user-fubtn {
    font-size: 13px;
    padding: 0px;
    height: 30px;
    width: 75px;
  }

  .mbc-show {
    border: 1px solid rgb(207, 207, 207);
    height: 400px;
    max-height: 400px;
    max-width: 100%;
    text-align: center;
    background-color: whitesmoke;
    border-radius: 5px;
    margin: 10px 0px;
    padding: 7px;
  }

  .mbc-show img {
    height: 100%;
    /* width: 100%; */
  }

  .mbc-area {
    /* border: 1px solid brown;   */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0px 10px;
  }

  .mbc-area-share {
    margin: 0px 40px 0px 0px;
  }

  .mbc-area-view img {
    height: 17px;
    margin: 0px 5px 0px 0px;
  }

  .mbc-area-like img, .mbc-area-comment img, .mbc-area-share img {
    height: 25px;
    margin: 0px 5px 0px 0px;
  } 

  .mbc-area-favourite img, .mbc-area-download img{
    height: 25px;
  } 

  .mbc-description {
    font-size: 13px;
    color: #6E6E6E;
    max-height: 63px;
    overflow-y: scroll;
  }

  .mbc-description::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }
















  /* ***** Soft Corner ***** */

  .checkSoftCornerPin {
    /* border: 1px solid black; */
    margin: 50% auto;
  }

  .checkSoftCornerPin-head {
    font-size: 30px;
  }

  .sc-centerBlock {
    width: 100vw;
  }

  .sc-home {
    margin: 0px;
  }

  .sc-home-outlet {
    margin: 25px 0px 0px;
  }

  .sc-cb-div-3-users {
    overflow-x: scroll;
  }














  /* ***** Notification ***** */

  .notifications-block {
    height: 85vh;
    margin: 60px 0px 0px;
  }

  .nb-notifications {
    height: 730px;
    height: 98%;
    width: 100%;
    margin: 7px 0px 0px 0px;
  }

  .nb-n-nortification {
    width: 97%;
    margin: 0px auto 10px;
  }

  .nb-n-nortification-header {
    width: 61%;
  }

  .nb-n-nortification-name {
    font-size: 13px;
  }

  .nb-n-nortification-time {
    font-size: 13px;
  }

  .nb-n-nortification-msg {
    font-size: 13px;
  }

  .nb-n-nortification-image {
    width: 75px;
  }

  .chats-rb-call-t-header-name {
    width: 200px;
  }







  /* ***** Chats ***** */

  .chats-cb {
    margin: 60px 0px 0px;
  }

  .chats-cb-chat-pinned-user-nameArea {
    width: 300px;
  }

  .chats-cb-chat-all-user-nameArea {
    width: 300px;
  }

  .chats-rb-chat-t-box {
    padding: 10px 15px;
  }

  .chats-rb-chat-t-header-name {
    width: 200px;
  }

  .chats-rb-chat-t-footer-area-form input {
    width: 200px;
  }

  .chats-rb-chat-t-footer {
    padding: 15px 20px 75px;
  }







  /* ***** Mobile Comments ***** */

  .mobileComment-spinner {
    /* border: 1px solid red; */
    text-align: center;
    margin-top: 40%;
  }






  /* ***** Profile ***** */

  /* ***** Profile > Share ***** */

  .profile-share-copy-input {
    width: 250px;
  }

  .ps-sml-div {
    margin: 0px 5px;
  }

















































  /* ***** Soft Corner ***** */


  /* ***** Login And Signup ***** */

  .onboardingUser {
    margin: 0px;
    background-image: url('');
  }

  .onboardingUser-area {
    width: 100%;
  }

  .onboardingUser-area-emilo-logo {
    margin: 25px 0px;
  }

  .onboardingUser-area-emilo-logo img {
    height: 40px;
    width: 100px;
  }

  .onboardingUser-area-softcorner-logo {
    margin: 0px 0px 25px 0px;
  }

  .onboardingUser-area-softcorner-logo img {
    height: 35px;
    width: 40px;
    margin: 15px 0px;
  }

  .onboardingUser-area-softcorner-logo h3 {
    font-size: 20px;
    margin: 0px 15px 15px;
  }

  .onboardingUser-area-data {
    width: 75%;
  }

  .onboardingUser-area-data-learn {
    padding: 5px;
    margin: 0px 0px 35px 0px;
  }

  .onboardingUser-area-data-user {
    margin: 0px 0px 40px 0px;
  }

  .onboardingUser-area-data-user img {
    height: 40px;
    width: 40px;
  }

  .onboardingUser-area-data-userName h4 {
    font-size: 17px;
  }

  .onboardingUser-area-data-userName p {
    font-size: 13px;
  }

  .onboardingUser-area-data-uploadImage {
    padding: 5px 20px;
    margin: 0px 25px 30px;
  }

  .onboardingUser-area-data-termsAndConditions {
    margin: 30px 0px;
  }

  .onboardingUser-area-data-termsAndConditions label {
    font-size: 11px;
  }

  .onboardingUser-area-btn {
    height: 50px;
    width: 250px;
  }









  .onboardingMobile { 
    margin: 0px;
    background-image: url('');
  }

  .onboardingMobile-area {
    width: 100%;
  }

  .onboardingMobile-area-emilo-logo {
    margin: 25px 0px;
  }

  .onboardingMobile-area-emilo-logo img {
    height: 40px;
    width: 100px;
  }

  .onboardingMobile-area-softcorner-logo {
    margin: 0px 0px 25px 0px;
  }

  .onboardingMobile-area-softcorner-logo img {
    height: 35px;
    width: 40px;
    margin: 15px 0px;
  }

  .onboardingMobile-area-softcorner-logo h3 {
    font-size: 20px;
    margin: 0px 15px 15px;
  }

  .onboardingMobile-area-data-head {
    font-size: 25px;
    margin: 50px 0px 0px 0px;
  }

  .onboardingMobile-area-data-input { 
    margin: 30px 0px;
  }

  .onboardingMobile-area-data-message {
    font-size: 11px;
  }

  .onboardingMobile-area-btn {
    height: 50px;
    width: 250px;
  }














  .onboardingOTP {
    margin: 0px;
    background-image: url('');
  }

  .onboardingOTP-area {
    width: 100%;
  }

  .onboardingOTP-area-emilo-logo {
    margin: 25px 0px;
  }

  .onboardingOTP-area-emilo-logo img {
    height: 40px;
    width: 100px;
  }

  .onboardingOTP-area-softcorner-logo {
    margin: 0px 0px 25px 0px;
  }

  .onboardingOTP-area-softcorner-logo img {
    height: 35px;
    width: 40px;
    margin: 15px 0px;
  }

  .onboardingOTP-area-softcorner-logo h3 {
    font-size: 20px;
    margin: 0px 15px 15px;
  }

  .onboardingOTP-area-data {
    margin: 50px 0px;
  }

  .onboardingOTP-area-data-head {
    font-size: 27px;
    margin: 0px 0px 13px 0px;
  }

  .onboardingOTP-area-data-message {
    /* border: 1px solid black; */
    font-size: 12px;
    margin: 0px 10px 40px;
    text-align: center;
  }
  
  .onboardingOTP-area-data-inputOTP {
    height: 50px;
    width: 50px !important;
    border-radius: 15px;
    font-size: 30px;
    padding: 0px 0px 7px 0px;
    color: gray;
  }

  .onboardingOTP-area-btn {
    height: 50px;
    width: 250px;
  }

  .onboardingOTP-area-resendOTP {
     font-size: 12px;
  }











  .onboardingImage {
    margin: 0px;
    background-image: url('');
  }

  .onboardingImage-area {
    width: 100%;
  }

  .onboardingImage-area-emilo-logo {
    margin: 25px 0px;
  }

  .onboardingImage-area-emilo-logo img {
    height: 40px;
    width: 100px;
  }

  .onboardingImage-area-softcorner-logo {
    margin: 0px 0px 25px 0px;
  }

  .onboardingImage-area-softcorner-logo img {
    height: 35px;
    width: 40px;
    margin: 15px 0px;
  }

  .onboardingImage-area-softcorner-logo h3 {
    font-size: 20px;
    margin: 0px 15px 15px;
  }

  .onboardingImage-area-data {
    /* border: 1px solid black; */
    margin: 20px 0px 30px;
  }

  .onboardingImage-area-data-head {
    font-size: 25px;
    margin: 0px 0px 10px 0px;
  }

  .onboardingImage-area-data-message {
    font-size: 12px;
    margin: 0px 35px;
    line-height: 20px;
  }

  .onboardingImage-area-data img {
    height: 150px;
    width: 150px;
    margin: 25px 0px 0px;
  }

  .onboardingImage-area-btn {
    height: 50px;
    width: 250px;
  }







  .onboardingGender {
    margin: 0px;
    background-image: url('');
  }

  .onboardingGender-area {
    width: 100%;
  }

  .onboardingDOB {
    margin: 0px;
    background-image: url('');
  }

  .onboardingDOB-area {
    width: 100%;
  }






  .onboardingPin {
    margin: 0px;
    background-image: url('');
  }

  .onboardingPin-area {
    width: 100%;
  }

  .onboardingPin-area-emilo-logo {
    margin: 25px 0px;
  }

  .onboardingPin-area-emilo-logo img {
    height: 40px;
    width: 100px;
  }

  .onboardingPin-area-softcorner-logo {
    margin: 0px 0px 25px 0px;
  }

  .onboardingPin-area-softcorner-logo img {
    height: 35px;
    width: 40px;
    margin: 15px 0px;
  }

  .onboardingPin-area-softcorner-logo h3 {
    font-size: 20px;
    margin: 0px 15px 15px;
  }

  .onboardingPin-area-data {
    margin: 50px 0px;
  }

  .onboardingPin-area-data-head {
    font-size: 25px;
  }

  .onboardingPin-area-data-message {
    font-size: 12px;
    margin: 0px 0px 35px 0px;
  }

  .onboardingPin-area-data-pin {
    height: 50px;
    width: 50px !important;
    border-radius: 15px;
    font-size: 30px;
    padding: 0px 0px 7px 0px;
    color: gray;
  }

  .onboardingPin-area-btn {
    height: 50px;
    width: 250px;
  }











  .checkSoftCornerPin {
    margin: 0px;
    background-image: url('');
  }

  .checkSoftCornerPin-area {
    width: 100%;
  }

  .checkSoftCornerPin-area-emilo-logo {
    margin: 25px 0px;
  }

  .checkSoftCornerPin-area-emilo-logo img {
    height: 40px;
    width: 100px;
  }

  .checkSoftCornerPin-area-softcorner-logo {
    margin: 0px 0px 25px 0px;
  }

  .checkSoftCornerPin-area-softcorner-logo img {
    height: 35px;
    width: 40px;
    margin: 15px 0px;
  }

  .checkSoftCornerPin-area-softcorner-logo h3 {
    font-size: 20px;
    margin: 0px 15px 15px;
  }

  .checkSoftCornerPin-area-data-head {
    font-size: 25px;
  }

  .checkSoftCornerPin-area-data-pin {
    height: 50px;
    width: 50px !important;
    border-radius: 15px;
    font-size: 30px;
    padding: 0px 0px 7px 0px;
    color: gray;
  }

  .checkSoftCornerPin-area-btn {
    height: 50px;
    width: 250px;
  }


  







}