  @media only screen and (min-width: 768px) {
  /* ---------- Login ---------- */

  .login-logo {
    height: 45px;
  }


  /* ---------- Explore ---------- */

  .explore-sec {
    grid-template-columns: auto auto auto;
    gap: 20px;
    padding-inline: 20px;
  }



  /* ---------- Home ---------- */

  /* ---------- Home > Left Block ---------- */

  .lb-ffp {
    width: 70px;
  }

  .lb-ffp-text {
    font-size: 10px;
  }

  .lb-f-img {
    height: 12px;
    width: 12px;
  }

  .lb-f-count {
    border-radius: 50%;
    padding-inline: 5px;
    font-size: 10px;
    color: white;
    background: transparent linear-gradient(150deg, #F369FF 0%, #6AC6FC 100%) 0% 0% no-repeat padding-box;
  }


  /* ---------- Home > Right Block ---------- */

  .rb-profile {
    height: 45px;
    width: 45px;
  }

  .rb-name {
    font-size: 15px;
    color: black;
  }

  .rb-fby {
    font-size: 11px;
    color: gray;
  }



  /* ---------- Notifications ---------- */

  .notifications {
    /* border: 1px solid red; */
    height: 80vh;
    overflow-y: scroll;
    width: 50%;
    margin: 10px auto 0px;
  }




  /* ***** Follow Unfollow Button ***** */

  .followUnfollowBtn {
    font-size: 10px;
    height: 25px;
    width: 60px;
    color: white;
    background-color: #64A6F0;
    border-radius: 7px;
    border: none;
  }



  /* ---------- Profile ---------- */

  /* .profile {
    margin-top: 70px;
  } */

  .pu-p-img {
    height: 150px;
    width: 150px;
  }

  .pu-name {
    font-size: 25px;
  }

  .pn-img {
    height: 25px;
    width: 25px;
  }

  .pn-text {
    font-size: 13px;
  }



  /* ---------- Profile > Share ---------- */

  .ps-form-div {
    width: 50%;
  }







  /* ---------- Chat ---------- */

  .chat{
    margin-top: 10px;
  }

  .chat-box {
    padding-top: 60px;
  }



  /* ---------- Suggested Flix ---------- */

  .suggested-flix-img {
    height: 175px;
  }

/*----------- check-in Popup----------- */

.current_location{
  height: 30px;
  width: 30px;
}

  
.place_ht{
  height: 71px;
  overflow: hidden;
}





  /* ----------- Softcorner > Navbar ----------- */

  .sc-nav-logo {
    width: 100px;
  }



  /* ----------- Softcorner > Center Block > User Card ----------- */

  .sc-cb-user-cards {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 25px;
    margin-inline: 25px;
  }




  /* ----------- Softcorner > Notifications ----------- */

  .sc-notifications {
    width: 80%;
  }



  /* ----------- Softcorner > Gift ----------- */

  .sc-products {
    grid-template-columns: auto auto auto auto;
    gap: 20px;
  }

  .product-image-selected {
    height: 45vh;
  }



  /* ----------- Business > Home ----------- */

  .business-banner-profile {
    top: -50px;
    left: 50px;
  }

  .business-banner-profile-img {
    height: 125px;
    width: 125px;
  }

  .business-home-main {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
  }

  .business-home-photos {
    display: grid;
    grid-template-columns: auto auto auto;
  }

  .business-home-tagged {
    grid-template-columns: auto auto auto auto;
  }

  .business-home-reviews {
    grid-template-columns: auto auto auto;
  }

  .listing-job-slick .slick-slide {
    padding-right: 13px;
  }

  .listing-franchise-slick .slick-slide {
    padding-right: 13px;
  }
  
  .listing-frenchise-slick .slick-slide {
    padding-right: 13px;
  }

  .business-home-product-service-slick .slick-slide {
    padding-right: 13px;
  }
  



  /* ----------- Business > Dashboard ----------- */
  
  .dashboard-main {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
  }





  /* ----------- Business > Ads > Create Post ----------- */

  .business-create-post {
    grid-template-columns: auto auto auto;
    gap: 20px;
  }




  /* ----------- Business > Insights ----------- */

  .insights-page-overview {
    grid-template-columns: auto auto auto;
    gap: 20px;
  }

  .business-insights-main {
    grid-template-columns: auto auto;
    gap: 20px;
  }

  .business-insights-posts {
    grid-template-columns: auto auto;
    gap: 20px;
  }







  /* ----------- Business > Event ----------- */

  .business-event {
    grid-template-columns: auto auto;
    gap: 20px;
  }





  




}
