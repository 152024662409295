.faqQus {
    font-weight: 600;
}
body {
    background: #f5f5f5 !important;
}
label {
    font-size: 15px;
}
.ReportAnythingType {
    max-height: 220px;
    overflow: auto;
    border: 1px solid #f5f5f5;
    padding: 10px 20px 0px 20px;
}