@media only screen and (min-width: 0px) {

  /* ---------- Login ---------- */

  .login-logo {
    height: 35px;
  }

  .continue-right-select {
    width: 75%;
  }





  /* ---------- Notifications ---------- */

  .notifications {
    /* border: 1px solid red; */
    height: 80vh;
    overflow-y: scroll;
    width: 100%;
  }

  .notifications::-webkit-scrollbar {
    width: 0px;
  }

  .oldNotification-follow-1-img {
    height: 35px;
    width: 35px;
  }

  .oldNotification-like-1-profile {
    height: 35px;
    width: 35px;
  }

  .oldNotification-like-1-post {
    height: 40px;
    width: 40px;
  }

  .oldNotification-comment-1-profile {
    height: 35px;
    width: 35px;
  }

  .oldNotification-comment-1-post {
    height: 40px;
    width: 40px;
  }



  /* ***** Follow Unfollow Button ***** */

  .followUnfollowBtn {
    font-size: 10px;
    height: 25px;
    width: 60px;
    color: #fff;
    border-radius: 7px;
    border: none;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: #64A6F0;
    align-items: center;
    transition: all;
  }

  .followUnfollowBtn2{
    font-size: 10px;
    height: 25px;
    width: 60px;
    color: #fff;
    border-radius: 7px;
    border: none;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: #64A6F0;
    align-items: center;
    transition: all;
  }
 
  .followUnfollowBtn3{
    font-size: 10px;
    height: 25px;
    width: 60px;
    color: #000000;
    border-radius: 7px;
    border: none;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: #b0c0c4cc;
    align-items: center;
    transition: all;
  
  }
  




    
  /* ---------- Explore ---------- */

  .explore-sec {
    height: 200px;
    display: grid;
    grid-template-columns: auto;
    gap: 20px;
    padding-inline: 10px;
  }
  
  .position-relative {
    position: relative;
  }
  
  .hover-info {
    display: block; /* Changed to block for transitions */
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Smooth transition for opacity */
  }
  
  .position-relative:hover .hover-info {
    opacity: 1; /* Show on hover */
    background: rgb(149 149 149 / 43%);
    padding: 5px;
    border-radius: 5px;
  }
  
  .hover-info span {
    display: inline-block;
    margin: 0 5px;
  }

    
    
  /* ---------- Home  ---------- */

  /* ---------- Home > Stories ---------- */

  .story-text-editor {
    
  }

  .stories-insights-slick div {
    height: 175px !important;
    margin-left: 0px;
  }

  .stories-insights-slick .slick-arrow {
    display: none !important;
  }

  .stories-insights-slick .slick-active {
    padding-inline: 10px;
  }

  .stories-slider .slick-track {
    margin-left: 0;
  }

  .show-stories-popup .modal-header .btn-close {
    z-index: 1051;
    cursor: pointer;
    position: relative;
  }

  .story-view-popup > div:first-child > div:nth-child(2) {
    justify-content: center;
  }

  .story-view-popup > div:first-child > div:nth-child(2) > div {
    height: 100%;
    width: 100%;
  }

  .story-view-popup > div:first-child > div:nth-child(2) > div > img {
    height: 100%;
    width: 100% !important;
    object-fit: contain;
  }

  .story-view-popup > div:first-child > div:nth-child(2) > div > video {
    object-fit: contain;
    width: 100% !important;
    border-radius: 0;
  }


  /* ---------- Home > Center Block ---------- */

  .cb-p-name {
    font-size: 17px;
  }

  .cb-p-afn {
    font-size: 13px;
  }

  .cb-p-location {
    font-size: 13px;
  }




  /* ---------- Profile ---------- */

  /* .profile {
    margin-top: 60px;
  } */

  .pu-p-img {
    height: 100px;
    width: 100px;
  }

  .pu-p-icon {
    left: 60%;
    bottom: 3%;
  }

  .pu-name {
    font-size: 20px;
  }

  .pn-img {
    height: 20px;
    width: 20px;
  }

  .pn-text {
    font-size: 10px;
  }

  /* .pu-ffp-box {
    max-width: 70px;
  } */

  .pu-f-img {
    height: 13px;
    width: 13px;
  }

  .pu-f-count {
    border-radius: 50%;
    padding-inline: 4px;
    font-size: 10px;
    color: white;
    background: transparent linear-gradient(150deg, #F369FF 0%, #6AC6FC 100%) 0% 0% no-repeat padding-box;
  }


  /* ---------- Profile > Share ---------- */

  .ps-qrCode-img {
    height: 200px;
    width: 200px;
  }

  .ps-form-div {
    width: 75%;
  }

  .ps-sm-icon {
    height: 25px;
    width: 25px;
  }



  /* ---------- Profile > Follower > Popup ---------- */

  .pfp-profile {
    height: 30px;
    width: 30px;
  }

  .pfp-name {
    font-size: 13px;
  }



  /* ---------- Mobile Footer ---------- */

  .mf-img {
    height: 17px;
    width: 17px;
  }

  .mf-text {
    font-size: 10px;
  }




  /* ---------- Chat ---------- */

  .chat-cb-profile {
    height: 35px;
    width: 35px;
  }

  .ch-profile {
    height: 35px;
    width: 35px;
  }

  .ch-vc-icon {
    height: 30px;
    width: 30px;
  }

  .chat-rb-box {
    height: 65vh;
  }

  .call-rb-box-arrow-img {
    height: 10px;
  }

  .call-rb-box-call-img {
    height: 25px;
    width: 25px;
    padding: 7px;
  }

  .chat-rb-header-previous {
    height: 20px;
  }

  .online-status {
    height: 9px;
    width: 9px;
    left: -17px;
    top: 3px;
    border-radius: 50%;
    position: absolute;
    background-color: #a6ddaa;
  }

  .chat-header {
    top: 0px;
  }

  .chat-box {
    padding-top: 55px;
    padding-bottom: 45px;
  }

  .chat-footer {
    bottom: 1px;
    height: 50px;
    width: 100%;
  }

  .resize-none {
    resize: none;
  }





  /* ---------- Center Block > Story 2  ---------- */

  .cb-s2-img {
    height: 150px;
    width: 75px;
    object-fit: cover;
  }



  /* ---------- Profile > Setting  ---------- */

  .ps-profile {
    height: 75px;
    width: 75px;
  }

  .block-profile {
    height: 50px;
    width: 50px;
  }



  /* ---------- Accordion  ---------- */

  .accordion-level-1 {
    /* border: 1px solid black; */
    padding: 10px 0px 10px 20px;
    border-radius: 10px;
    color: #3A7CE6;
    background-color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .accordion-level-1:not(.collapsed) {
    color: #3A7CE6;
    background-color: white;
  }

  .accordion-level-2 {
    padding: 10px 0px 10px 20px;
    border-radius: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .accordion-level-2:not(.collapsed) {
    color: black;
    background-color: white;
  }

  .accordion-level-3 {
    padding: 10px 0px 10px 20px;
    border-radius: 10px;
    background-color: #F0F0F0;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .accordion-level-3:not(.collapsed) {
    color: black;
    background-color: #F0F0F0;
  }

  .accordion-level-4 {
    /* border: 1px solid black; */
    display: inline-block;
    padding: 5px 20px;
    border-radius: 10px;
    background-color: #E5EFFF;
    cursor: pointer;
  }







  /* ---------- Home > Center Block > Stories ---------- */

  .story-img {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    border: 1px solid white;
    outline: 2px solid #1467EA;
    outline-offset: 3px;
  }

  .story-add {
    border: 2px solid white;
    padding: 3px;
    border-radius: 50%;
    bottom: 10px;
    height: 25px;
    padding: 6px;
    cursor: pointer;
  }

  .story-profile {
    height: 25px;
    width: 25px;
    border: 1px solid white;
    outline: 1px solid red; 
    outline-offset: 2px;
    border-radius: 50%;
    bottom: 10px;
  }

  .story-name {
    text-wrap: nowrap;
    font-size: 13px;
    margin-top: 5px;
    width: 100%;
    overflow: hidden;
    text-wrap: nowrap;
    text-align: center;
  }








  /* ---------- Suggested Flix ---------- */

  .suggested-flix-img {
    height: 100px;
    width: 18%;
  }



  /* ---------- Emoji Popup ---------- */
  .feeling:hover {
    background-color: lightblue;
  }

  .feeling-img {
    height: 25px;
    width: 25px;
  }




  /*----------- check-in Popup----------- */

  .current_location{
    height: 30px;
    width: 30px;
  }


  .place_ht{
    height: 71px;
    overflow: hidden;
  }





  
  /*----------- Breadcrumb ----------- */

  .breadcrumb-item+.breadcrumb-item::before {
    content: ">";
  }


  /* ----------- Accordion ----------- */

  /* .accordion-button:not(.collapsed) {
    color: #3A7CE6;
    background-color: white;
  } */






  /* ----------- Softcorner > Navbar ----------- */

  .sc-nav-logo {
    width: 75px;
  }





  /* ----------- Softcorner > Center Block > User Card ----------- */

  .sc-cb-user-cards {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 15px;
    margin-inline: 15px;
  }

  .sc-cb-user-card-profile {
    height: 175px;
  }

  .sc-cb-user-card-icon-1 {
    left: 25%;
  }

  .sc-cb-user-card-icon-2 {
    left: 75%;
  }

  .giftSettings .slick-prev, .giftSettings .slick-next {
    display: none !important;
  }





  /* ----------- Softcorner > Profile ----------- */

  .selected-sc {
    color: black !important;
  }




  /* ----------- Softcorner > Notifications ----------- */

  .sc-notifications {
    width: 100%;
  }



  /* ----------- Softcorner > Gift ----------- */

  .sc-products {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
  }

  .sc-gift-ads .slick-arrow, 
  .sc-gift-categories .slick-arrow, 
  .productSlider .slick-arrow, 
  .relatedSettings .slick-arrow,
  .reviewSettings .slick-arrow {
    display: none !important;
  }

  .productSlider .slick-dots {
    position: static;
  }

  .productSlider .slick-dots li {
    height: 7vh;
    width: 23%;
    margin: 0px;
  }

  .product-image-selected {
    height: 25vh;
  }

  .product-image-option {
    height: 7vh;
  }





  /* ----------- Softcorner > Chat ----------- */

  .sc-chat-header {
    top: 0;
    width: 100%;
  }

  .sc-chat-box {
    padding-top: 65px;
    padding-bottom: 55px;
  }

  .sc-chat-footer {
    width: 100%;
    bottom: 1px;
  }






  /* ----------- Business > Basic Info ----------- */

  .business-basicInfo-lb {
    width: 0%;
  }

  .business-basicInfo-rb {
    width: 100%;
  }


  /* ----------- Business > Home ----------- */

  .business-lb {
    width: 0%;
    display: none;
  }

  .business-rb {
    width: 100%;
  }

  .business-banner-slider {
    height: 300px;
  }

  .business-banner-slider .slick-prev {
    display: none !important;
    /* left: 0px; */
  }

  .business-banner-slider .slick-next {
    display: none !important;
    /* right: 0px; */
  }

  .business-banner-slider .slick-dots {
    bottom: 20px;
  }

  .business-banner-slider .slick-dots li {
    height: 31px;
    width: 31px;
  }

  .business-banner-slider .slick-dots .slick-active {
    /* border: 2px solid red; */
    border-radius: 30px;
    box-shadow: 2px 2px 5px black;
  }

  .business-banner-profile {
    top: -35px;
    left: 15px;
  }

  .business-banner-profile-img {
    height: 75px;
    width: 75px;
  }

  .business-home-main {
    display: grid;
    grid-template-columns: auto;
  }

  .business-home-photos {
    display: grid;
    grid-template-columns: auto auto;
    gap: 13px;
  }

  .business-home-ads-slick .slick-slide {
    padding-right: 10px;
  }

  .business-home-tagged {
    display: grid;
    grid-template-columns: auto auto;
    gap: 15px;
  }

  .business-home-reviews {
    display: grid;
    grid-template-columns: auto;
    gap: 15px;
  }

  .listing-job-slick .slick-prev, .slick-next {
    display: none !important;
  }

  .listing-frenchise-slick .slick-prev, .slick-next {
    display: none !important;
  }

  .business-home-product-service-slick .slick-prev, .slick-next {
    display: none !important;
  }

  .business-home-product-service-slick .slick-list {
    padding-bottom: 7px;
  }

  .business-home-product-service-slick-other .slick-slide {
    padding-bottom: 7px;
  }

  .business-home-product-service-slick .slick-track {
    margin-inline: 0px;
  }

  .listing-franchise-slick .slick-track {
    margin-inline: 0px;
  }

  .business-milestone-slider .slick-track {
    margin-inline: 0px;
    padding-bottom: 10px;
    display: flex; 
  }

  .business-milestone-slider .slick-active {
    margin-right: 10px;
  }

  .business-milestone-slider .slick-dots {
    bottom: -17px;
  }

  .milestone-view-image {
    height: 25vh;
  }

  .listing-frenchise-slick .slick-track, .listing-job-slick .slick-track {
    margin-left: 0px;
  }

  .searchPages {
    max-height: 50vh;
    width: 95%;
  }

  .product-images-slider .slick-dots {
    bottom: 20px;
  }

  .job-images-slider .slick-dots {
    bottom: 20px;
  }






  /* ----------- Business > Account Setting ----------- */

  .as-gs-pr-users {  /* Account Setting > General Setting > Page Roles > users  */
    display: grid;
    grid-template-columns: auto;
    gap: 15px;
  }




  /* ----------- Business > Dashboard ----------- */

  .dashboard-overview-slider .slick-track {
    display: flex;
    padding-bottom: 10px;
  }

  .dashboard-overview-slider .slick-dots {
    bottom: -15px;
  }

  .dashboard-main {
    display: grid;
    grid-template-columns: auto;
  }



  /* ----------- Business > Ads > Create Post ----------- */

  .business-create-post {
    display: grid;
    grid-template-columns: auto;
    gap: 20px;
  }





  /* ----------- Business > Insights ----------- */

  .insights-page-overview {
    display: grid;
    grid-template-columns: auto;
    gap: 20px;
  }

  .business-insights-main {
    display: grid;
    grid-template-columns: auto;
    gap: 20px;
  }

  .business-insights-posts {
    display: grid;
    grid-template-columns: auto;
    gap: 20px;
  }





  /* ----------- Business > Events ----------- */

  .business-events {
    display: grid;
    grid-template-columns: auto;
    gap: 20px;
  }




  /* ----------- Business > Event ----------- */

  .business-event {
    display: grid;
    grid-template-columns: auto;
    gap: 20px;
  }





  .pac-container {
    z-index: 1100;
  }



}
