.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  opacity: 0;
}

.trending_head {
  font-weight: bold;
  background-color: white;
  padding: 10px 30px;
  z-index: 100;
  text-align: center;
}

.selected-users {
  transition: 0.2s ease-out;
  font-weight: bold;
  box-shadow: #93c0f3 0px 3px 8px !important;
}

.welcome_sc {
  animation: welcome_sc 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@keyframes welcome_sc {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
    transform: translateZ(-700px);
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}


.bus_right,
.bus_left {
  height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
}

.follow_pop {
  min-height: 400px;
  max-height: 400px;
  padding: 0px 10xp;
  /* overflow: hidden; */
  overflow-y: scroll !important;
}

.follow_pop::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}

.follow_pop::-webkit-scrollbar-thumb {
  background-color: #91bbeb;
  border-radius: 10px;
}

.pu-f-count {
  padding: 2px 4px !important;
  position: relative;
  left: -35px;
  z-index: 6;
}

.follow_img {
  top: -5px;
  left: 62% !important;
}

.follow_img img {
  height: 20px !important;
  width: 20px !important;
  object-fit: cover;
  border-radius: 50%;
  /* border: 1px solid #d4d4d4; */
  background-color: white;
  padding: 2px;
}

.follow_img img:nth-child(1) {
  position: relative;
  z-index: 1;
}

.follow_img img:nth-child(2) {
  left: -10px;
  z-index: 2;
  position: relative;
}

.follow_img img:nth-child(3) {
  position: relative;
  left: -19px;
  z-index: 3;
}

.follow_img img:nth-child(4) {
  position: relative;
  left: -29px;
  z-index: 4;
}


.bus_left::-webkit-scrollbar,
.bus_right::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}

.bus_left::-webkit-scrollbar-thumb,
.bus_right::-webkit-scrollbar-thumb {
  background-color: #91bbeb;
  border-radius: 10px;
}


.selected_music {
  background: transparent linear-gradient(90deg, #2676E100 0%, #2676E19A 15%, #2676E1 32%, #2676E1 71%, #b9d8f5 87%, #2676E100 100%) 0% 0% no-repeat padding-box;
  color: snow;
}

/* .suggested-flix .slick-track{
  width: 110%  !important;
} */

.suggested-flix .slick-track {
  left: -55px !important;
}

.text_editor .jodit-container {
  max-height: 350px !important;
  height: 300px !important;
  background-color: red;
}

.likepopup {
  min-height: 350px;
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.story_tag_btn {
  position: absolute;
  top: 5px;
  left: 10px;
  font-size: 18px;
  color: black;
  background-color: #d3d3d38a;
  border-radius: 50%;
  cursor: pointer;
  padding: 0px 5px;
}

.likepopup::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}

.likepopup::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
  border-radius: 20px;
}

.msg_spin {
  height: 20px !important;
  width: 20px !important;
  font-size: 10px;
  margin: 10px 0px;
}



.highlight {
  background: rgba(9, 94, 163, 0.171) !important;
  border-radius: 10px;

}

.del-cancel {
  border: 1px solid #d3d3d3 !important;
  color: #696767;
  background: snow;
  padding: 10px 0px;
  font-weight: 500;
}

.del-confim {
  background: #FF2F2F;
  color: snow;
  border: none;
  font-weight: 500;
  padding: 10px 0px;
}


.modal_body_1 {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.meme_section {}

.meme_close {
  position: absolute;
  right: 0px;
  color: white;
  top: 5%;
  font-size: 25px;
  cursor: pointer;
}

.meme_img_vid {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-bottom: 10px;
}


.meme_img_vid div {
  border: 1px solid snow;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 5px;
  color: snow !important;
}

.del-confim:hover {
  background: #c50808;
}

.msg_contact a {
  text-decoration: none !important;
  color: inherit !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
}

.msg_contact a img {
  border-radius: 50%;
}

.reply-box-1 .msg_contact_relpy {
  display: flex;
  justify-content: flex-start;
}

.msg_contact_relpy {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
}

.msg_contact_relpy img {
  width: 30px;
  border-radius: 50%;
}


.location_close {
  cursor: pointer;
  background-color: #d3d3d363;
  color: rgb(247, 139, 139);
  font-size: 14px;
  padding: 2px;
  border-radius: 15px;
}

.sf_div>img {

  height: 100%;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.user_data {
  font-size: 14px;
  color: gray;
}

.relation_div {
  min-height: 300px;
  max-height: 300px;
  position: relative;
  width: 80%;
  margin: auto;
  overflow: scroll;
  border: 1px solid #d3d3d3;
  padding: 10px;
  padding-left: 22px;
  margin-top: 20px;
  background-color: white;
  border-radius: 15px;
}

.relation_action1,
.relation_action2 {
  cursor: pointer;
  padding: 2px 10px;
  border: 2px solid rgba(0, 128, 0, 0.274);
  border-radius: 5px;
  font-size: 12px;
  margin: 2px 0px;
}

.relation_action2 {
  border: 2px solid rgba(255, 0, 0, 0.247);
}

.hobby_list {
  margin: 10px;
  padding: 5px;
  cursor: pointer;
}

.hobby-icon {
  max-width: 15px;
}

.hobby_pop {
  height: 40vh;
  max-height: 40vh;
}

.hobby_pop input {
  padding: 5px 10px;
  border-radius: 10px;
  outline: none;
  border: 1px solid #d3d3d3;
  width: 80%;
}

.phone_input_contact input {
  font-size: 16px;
  padding: 15px 0px 15px 76px;
}

.del_pop {
  width: 53px;
}

.del_pop .spinner-border {
  height: 1rem !important;
  width: 1rem !important;
}

.change_no_otp {
  position: absolute;
  right: 11px;
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
  background-color: #64A6F0;
  color: snow;
  top: 1px;
  padding: 15px;
  cursor: pointer;
}

.change_no_otp:hover {
  background-color: #3f79bb;
}

.work_location .css-b62m3t-container {
  width: 80% !important;
  margin: auto;
  text-align: start;
  margin: 10px auto;
}

.profile_div {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.profile_user_name {
  width: 100%;
  border: 2px solid #f3f3f3;
  position: relative;
  padding: 10px;
  border-radius: 15px;
  margin-top: 10px;
}

.profile_user_name>span:nth-child(2) {
  position: absolute;
  color: rgb(136, 116, 228);
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.hobby_conatiner {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 12px 14px;
}

.date_select {
  background-color: transparent;
  border: none;
  font-size: 12px;
  font-weight: bold;
}

.inspiration_list_div {
  max-width: 42%;
  position: absolute;
  top: 81%;
  background-color: white;
  z-index: 10;
  max-height: 221px;
  overflow-y: scroll;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border-radius: 15px;
}

.inspiration_list_div::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
}

.inspiration_list_div::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
}

.inspiration_list {
  border: 1px solid #f3f3f3;
}

.inspiration_list li {
  margin: 10px 20px;
  cursor: pointer;
  font-size: 12px;
  padding: 5px 0px 0px 5px;
}

.inspiration_list li p {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

.inspiration_list li:hover {
  background-color: #f4f4f4;
}

.inspiration_img {
  max-width: 9%;
}

.inspiration_img img {
  border-radius: 50%;
}

.profile_search {
  margin: 10px;
  border: none;
  border-bottom: 1px solid #d3d3d3;
  padding: 0px 18px;
  color: #6c757d !important;
  display: block;
}

.profile_search::placeholder {
  font-size: 12px;
}

.work_location .css-b62m3t-container {
  border: none;
  border-bottom: 2px solid #f3f3f3;
}

.work_location .location-added {
  display: flex;
  justify-content: space-between;
  /* max-width: 80%; */
  margin: 18px auto;
  font-size: 13px;
}

.work_location .location-added p {
  color: rgba(0, 0, 0, 0.849);
  font-size: 14px;

}

.ph_verify_otp {
  background-color: #45c06a;
  color: snow;
  border: none;
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  cursor: pointer;
}


.scale_in {
  -webkit-animation: scale_in 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale_in 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale_in {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale_in {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}


.hobby_li {
  list-style: none;
  background: #f3f3f3;
  border: 1px solid #e6e3e3;
  text-align: center;
  padding: 5px 10px;
  border-radius: 10px;
  margin: 10px;
}

.hobby_other_li {
  max-height: 32vh;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
}

.hobby_other_li::-webkit-scrollbar {
  background-color: transparent;
}

.hobby_li span {
  margin: 0px 10px;
}

.hobby_add {
  padding: 5px 10px;
  color: #fff;
  border-radius: 30px;
  outline: none;
  font-size: 12px;
  background-color: #494848;
}

.relation_ul {
  position: fixed;
  max-height: 237px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.relation_ul li {
  margin: 10px 0px;
  cursor: pointer;
}


.relation_div::-webkit-scrollbar,
.relation_ul::-webkit-scrollbar {
  background-color: transparent;
}

.css-1nmdiq5-menu {
  max-width: 100%;
  overflow-x: hidden;
}

.addSpecialDays-formField::-webkit-scrollbar {
  display: none;
}


/* .addLoaction {
  max-width: 96% !important;
} */


.addLoaction .location-added {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  color: #848484;
  text-align: start !important;
  font-size: 12px;
}

.addLoaction .location-added p {
  font-size: 12px;
  padding: 2px 5px;

}

.addLoaction .location-added span {
  cursor: pointer;
  margin-left: 10px;
}

.map-input {
  width: 87%;
  margin: auto;
  position: relative;
}

.map-input input {
  width: 100%;
  margin: auto;
  border: 1px solid #d3d3d3 !important;
  border-radius: 15px;
  padding: 10px;
  position: relative;
  z-index: 11;
}

.map-input-list {
  position: absolute;
  border-radius: 15px;
  padding-top: 50px;
  border: 1px solid #d3d3d3;
  width: 100%;
  z-index: 10;
  background-color: #fff;
  top: 0px;
}

.map-input-list li {
  padding: 5px 20px;
  cursor: pointer;
  font-size: 15px;
}

.map-input-list li:hover {
  background-color: #d3d3d362;
}

.map-input input::placeholder {
  font-size: 13px;
}

.map-body {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  z-index: 9;
}

.hr-line {
  height: 1px;
  background-color: #d3d3d3;
  opacity: 0.5;
  margin-top: 5px;
}

.map-center-btn {
  position: absolute;
  top: 10px;
  left: 25px;
  cursor: pointer;
  color: #F2F2F2;
  padding: 10px 15px;
  background-color: #f3f3f3;
  border: none;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.map-center-btn span {
  color: #b1adad;
}

.map-center-btn span:hover {
  color: black;
  rotate: -40deg;
}

.map-input-clear {
  position: absolute;
  right: 1px;
  cursor: pointer;
  top: 1px;
  z-index: 12;
  background-color: #f4f4f4;
  padding: 10px 12px;
  border: none;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  color: #929090;
}

.camera-modal .modal-dialog .modal-body {
  background-color: #000 !important;
}

.camera-btn {
  border: none;
  background-color: #ff474c;
  color: white;
  font-size: 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.camera_start_loader {
  position: absolute;
  top: 50%;
  left: 40%;
  color: white;
  display: flex;
  justify-content: center;
  align-self: center;
  gap: 10px;
  z-index: 2;
}

/* HTML: <div class="loader"></div> */
.loader-camera {
  width: 25px;
  padding: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #eef5f4;
  --_m:
    conic-gradient(#0000 10%, #000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn)
  }
}

.camera-btn img {
  width: 25px;
  animation: leaves .5s ease-in-out infinite alternate;
  ;
}

@keyframes leaves {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

.camera_bg {
  padding: 10px;
  border-radius: 15px;
}

.camera_bg img {
  border-radius: 15px;
}

.back-icon {
  font-size: 20px;
  position: relative;
  /* left: 20px; */
  /* top: -5px; */
}

.slick-next:before,
.slick-prev:before {
  display: none;
}

.video-container {
  height: 400px;
  width: 425px;
}

.delete-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-right: 20px;
}

.delete-header .del {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.delete-header>div>span {
  font-size: 25px;
  color: #63A4ED;
  cursor: pointer;
  margin: 0px 8px;
}

.loader.active {
  opacity: 100;
}

.visible {
  transition: all 0.5s ease-in-out;
}

.visible {
  opacity: 0;
  display: none;
}

.hangout-location .css-t3ipsp-control {
  width: 400px !important;
}

.hangout-location .css-13cymwt-control {
  width: 400px !important;
}

.hangout-location .css-1fdsijx-ValueContainer {
  width: 400px !important;
}

.hangout-input {
  position: relative;
}

.hangout-close {
  position: relative;
  right: -100px;
  color: black;
}

.css-1jqq78o-placeholder {
  font-size: 12px;
}

#react-select-2-placeholder {
  width: 400px;
}

.css-1nmdiq5-menu {
  width: 400px !important;
}

.visible.active {
  opacity: 100;
  display: block;
  transition: all 0.5s ease-in-out;
}

/* .profile>img {
  margin-right: -7px;
  border: 3px solid snow;
  padding: 2;
  border-radius: 100%;
} */

/* Profile user followed by profile  */

.pufbp {
  border: 2px solid white;
  border-radius: 50%;
  margin: 0px -5px 0px 0px;
  object-fit: fill;
  height: 25px;
  width: 25px;
}

.profile_hr {
  margin: 0px;
  background-color: #f3f3f3;
  opacity: 0.3;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: '10px 0px 0px 25px';
  gap: 10px
}

.profile-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.selected {
  color: #FFFFFF !important;
  /* background: transparent linear-gradient(90deg, #2676E1 0%, #2676E1 71%, #2676E19A 87%, #2676E100 100%) 0% 0% no-repeat padding-box; */
  background: transparent linear-gradient(90deg, #2676E100 0%, #2676E19A 15%, #2676E1 32%, #2676E1 71%, #2676E19A 87%, #2676E100 100%) 0% 0% no-repeat padding-box;
}

.account-profile1,
.account-profile2 {
  padding: 0px 20px;
}

.accountProfile {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.accountProfile>div {
  width: 100px;
  border: 2px solid snow;
  flex-direction: column;
  border-radius: 50%;
  overflow: hidden;
}

.profileNavImage {
  width: 55px;
  margin: auto;
  align-items: flex-start;
  margin: auto;
  padding-left: 5px;
  height: 45px;
  background-color: transparent;
}

.profileNavImage>img,
.accountProfile>div>img {
  border-radius: 50%;
  background-color: snow;
  border: 1px solid snow;
  width: 100%;
}

.accountProfile>div>img {
  border: 2px solid snow;
}

.profileNavCenter>div {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  align-items: center;
  margin: 20px 0px;
  padding: 20px;
}

.profileNavCenter>div>div>span {
  font-size: 18px;
}

.profileNavBottom {
  display: grid;
  grid-template-columns: auto;
  gap: 20px;
}

.account-profile1>div,
.account-profile2>div {
  margin-top: 20px;
}

.account>div {
  margin-top: 30px;
}

.account-profile1>div>span {
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-top: 10px;
  justify-content: flex-start;
  align-items: flex-start;
}

.account-profile1>div>span>hr {
  width: 100%;
}

.account-profile1>div>label,
.account-profile2>div>label {
  font-size: 12px;
  color: #848484;
}

.account-profile1>div>span>span,
.account-profile2>div>div>span {
  font-size: 13px;
  padding: 0px 10px;
}

.account {
  margin-left: -20px;
}

.account>flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex>div>div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.invite {
  width: 100%;
  margin: auto;
  margin-top: 60px;
  padding-left: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.invite>span {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  margin: 20px 0px;
  cursor: pointer;
}

.invite-span {
  display: flex;
  align-items: center;
  color: black;
}

.invite-span img {
  margin: 20px 20px 20px 0px;
}

.postpopup {
  border-radius: 14px;
  padding: 5px 15px;
  background-color: #f3f3f3;
  margin: 10px 0px;
}

.postDelete {
  cursor: pointer;
  position: relative;
  top: 0px;
  z-index: 1;

}

.post_label>div {
  padding: 5px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.post_label>div>label {
  font-size: 12px;
  color: #a6a6a6;
}

.popup_main>span {
  font-size: 12px;
}

.centerBlockImg {
  width: 10px;
  border: 4px solid red;
}

.carousel-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deletePosts {
  cursor: pointer;
  position: absolute;
  top: 7px;
  left: 177px;
}

.postImg {
  position: relative;
  margin: 0px 42px 65px 42px;
  /* display: block; */
  /* width: 65%; */
  /* justify-content: center; */
  /* align-items: center; */
  /* transition: filter 0.3s; */
}

.imgPosted {
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 7px -3px gray;
  object-fit: cover;
}

.imgPosted:hover {
  filter: brightness(70%);
}

.postImg:hover .postLike {
  display: block;
}

.postLike {
  position: absolute;
  bottom: 50%;
  left: 35%;
  font-size: 14px;
  color: snow;
  display: none;
  filter: brightness(100%);
}

.postLike>span {
  margin: 0px 5px;
}

.imageFix,
.imageCarousel,
.memeCarousel {
  height: 750px;
  width: 615px;
  overflow: hidden;
  object-fit: cover;
}

.memeCarousel {
  height: 100%;
}

.memeCarousel .carousel-indicators {
  display: none;
}

.imageContainer .carousel-indicators button {
  box-sizing: unset;
  height: 0px;
  width: 0px;
  border: 5px solid #63A4ED;
  border-radius: 50%;
  /* border-bottom: 5px solid yellow ; */
}

.memeCarousel .carousel-control-next-icon {
  background-color: #d3d3d35e;
  margin-right: 25px;
  border-radius: 5px;
  padding: 15px;
}

.memeCarousel .carousel-control-prev-icon {
  border-radius: 5px;
  background-color: #d3d3d3;
  margin-left: 25px;
  padding: 15px;
}

.CarouselVideo {
  height: 100%;
  width: 200px;
  text-align: center;
  margin: auto;
  display: flex;
}

.imageFix>img {
  height: 100%;
  width: 100%;
}


.createReelPost1>label>button {
  height: 40px;
  width: 278px;
  color: white;
  border-radius: 30px;
  background-color: '#64A6F0';
  border: 'none';
}

.accordion {
  margin: 20px auto;
}



.accordion-body {
  padding: 10px;
}

.accordionButton {
  position: relative;
  display: grid;
  text-align: center;
  align-items: center;
  width: 100%;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  /* background-color: var(--bs-accordion-btn-bg); */
  border: 0;
  padding: 10px;
  justify-content: center;
  font-size: 20px;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  /* height: 60px; */
}

.accordion-button::after {
  /* height: 30px; */
  /* background-color: red; */
  /* content: "^"; */
  height: 16px;
  width: 20px;
  /* background-image: none !important; */
}

.createPV {
  position: relative;
}

.createPVChild {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 5px;
  align-items: center;
}

.createPVChild .carousel {
  max-height: 420px !important;
}

.del_pv,
.add_pv {
  background-color: #d3d3d3;
  padding: 0px 4px 0px 4px;
  border-radius: 50%;
  cursor: pointer;
}

.reel2btn {
  height: 43px;
  width: 450px;
  background-color: #64A6F0;
  text-align: center;
  color: white;
  margin: 10px auto 0px;
  font-size: 20px;
  border-radius: 25px;
  cursor: pointer;
  border: none;
}

.thumbnail {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
}

.thumbnail>img {
  height: 18px;
  width: 18px;
  filter: opacity(84%);
}

.profileReels {
  display: flex;
  flex-flow: wrap;
  width: 70%;
  margin: auto;
  grid-gap: 78px;
  gap: 56px;
  position: relative;
  justify-content: space-between;
}

.profileReels>section>img {
  height: 300px;
  width: 200px;
  border-radius: 10px;
  cursor: pointer;
}

.profileSkeleton {
  height: 300px;
  width: 200px;
  border-radius: 10px;
  cursor: pointer;
}



.profileReels section img:hover {
  filter: brightness(90%) blur(2px);
  z-index: 0;
}

.profileReels {
  position: relative;
}

.profileReels section {
  position: relative;
  cursor: pointer;
}

.reelLike {
  display: none;
  position: absolute;
  font-size: 14px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  color: snow;
  text-align: center;
  /* Center the text horizontally */
  transition: opacity 0.5s ease;
  z-index: 10;
}

.profileReels section span {
  margin: 0px 5px;
}

.profileReels section:hover .reelLike {
  display: block;
  opacity: 1;
  z-index: 100;
}

.reelDetail {
  font-size: 12px;
  color: snow;
  max-width: 180px;
  text-align: start;
  margin: 0 auto;
}

/* Add the following to center the .reelLike vertically */
.profileReels section:hover .reelLike {
  top: 50%;
  transform: translateY(-50%);
  z-index: 101;
}

.cnp-textArea::placeholder,
.cnp-textArea {
  padding-top: 50px;
  text-align: center;
  font-size: 18px;
}


.main-wrap {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}


.image-wrap {
  position: relative;
}

textarea {
  overflow-y: scroll;
  /* For WebKit browsers (Chrome, Safari) */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

textarea::-webkit-scrollbar {
  width: 12px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.image-wrap img {
  height: 100%;
  width: 100%
}

.image-wrap>p {
  position: absolute;
  top: 100px;
  font-size: 100px;
  /* color:white; */
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 900;
  text-align: center;
  width: 100%;
  font-size: 10px;
}

.imgBox video {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.postVideo {
  height: 100%;
  width: 100%;
}

.masonary {
  columns: 3;
  padding: 10px 80px;
  gap: 5px;

}

.grid-wrap {
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
}

.video-wrap {
  height: 749px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.reeelShareVideo {
  background-color: aqua;
  width: 67%;
  height: 723px;
  margin: auto;
  border-radius: 10px;
}

.createMeme1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.createMeme1>img {
  height: 300px;
  width: 300px;
  margin-bottom: 20px;
}


.createMeme1 .browse {
  color: white;
  border: 1px dashed white;
  border-radius: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.createMeme2Post {
  width: 100%;
  margin: 10px auto;

}

.createMeme2Post p {
  width: 150px;
  color: white;
  background-color: #2676E1;
  padding: 10px 60px;
  outline: black !important;
  font-size: 18px;
  border-radius: 15px;
  cursor: pointer;
}

.carousel-inner {
  display: block;
}

.memeDetails {
  width: 100%;
  padding: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.memeDetails>section>video {
  width: 74%;
  height: 671px;
  margin: auto;
  border-radius: 10px
}

.memeDetails .memeImg {
  width: 80%;
  margin: auto;
}

.bestClipDetails {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bestClipDetails>section>video {
  width: 100%;
  border-radius: 15px;

}

.best-video-wrap {
  height: fit-content;
}

.bestClipWrapper {
  height: 100%;
  width: 700px;
  margin: 10px 0px 0px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bestClipVideoWrap {
  height: 300px;
  text-align: center;

}

.bestClipVideoWrap .bestClipVideo {
  width: 100%;
  margin-top: 15px;
  border-radius: 15px;
}

.bestClipCategory {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  font-size: 12px !important;
}

.bestClipCategory .slick-slide {
  text-align: center;
  padding: 10px;
  width: 100px !important;
  margin: auto;
}

.cancel_category {
  background-color: rgb(241, 82, 82);
  color: snow;
  cursor: pointer;
  border-radius: 50%;
  font-size: 10px !important;
  padding: 2px 5px 0px;
}


.bestClipCategory .slick-slide>div>div {
  width: 100px !important;
}

.bestClipCategory .slick-slide span {
  border-radius: 5px;
  padding: 2px 10px;
  border: 1px solid #b8b1b1;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


.bestClipCategory>div {
  position: relative;
  filter: brightness(1.2);
  cursor: pointer;
  margin-bottom: 10px;
}


.bestClipCategory>div>span {
  width: 100%;
  height: 100%;
  padding: 5px;
  font-size: 14px;
}

.uploadBestClips {
  border: 1px solid #e8e2e2;
  height: 250px;
  margin: 0px auto;
  border-radius: 17px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}

.clipMessage {
  color: red;
  text-align: center;
  display: flex;
  justify-content: center;
}

.selectedCategory {
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  filter: brightness(100%);
  border: 3px solid ad9e9e;
  border-radius: 20px;
}



.headerSearch {
  position: relative;
  z-index: 1;
}

.headerSearch .searchList {
  /* border: 1px solid black; */
  border-radius: 10px;
  position: absolute;
  /* height: fit-content; */
  padding: 40px 20px 20px 20px;
  z-index: -1;
  top: 65px;
  width: calc(100% - 30px);
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 5px 0px rgb(192, 192, 192);
}

.searchList a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.notify_dot{
  position: absolute;
  height: 5px;
  width: 5px;
  background-color: red;
}

.searchList .searchImage {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 20px;
}

.userSearchList>div {
  color: rgba(128, 128, 128, 0.733);
  font-size: 14px;
}

/* .suggestions {
  border: 1px solid black;
} */

.suggestion {
  /* border: 1px solid red; */
  padding: 5px;
  height: 575px;
  overflow-y: scroll;
}

.suggestion::-webkit-scrollbar {
  width: 0px;
}

.suggestionImage {
  height: 60px;
  width: 60px;
}

.suggestionImage img {
  outline: 2px solid #0B71F6;
  outline-offset: 2px;
  border-radius: 50%;
  height: 60px;
  width: 60px;
}

.suggestionImage-img {
  border-radius: 50%;
  cursor: pointer;
}

.clearSearch {
  cursor: pointer;
  color: #3975c4;
}

.notify-box .btn-primary {
  background-color: transparent;
  border: none;
}

.header-dd-img {
  height: 20px;
  margin-right: 10px;
}

.show>.btn-primary.dropdown-toggle {
  background-color: transparent;
  border: none;
}

.mainContainer {
  position: relative;
  margin-bottom: 20px;
}

.mainContainer .imageContainer {
  cursor: crosshair;
  width: 96%;
  /* max-width: 350px; */
  margin: auto;
  /* margin-left: 15px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: black; */
  /* border-radius: 17px; */
  overflow: hidden;
  border: 1px solid #f3f3f3;
  border-radius: 15px;
  /* min-height: 328px; */
  /* background-color: black; */
}

.carousel-item {
  text-align: center;
}

.mainContainer .imageContainer img {
  width: 100%;
  z-index: 2;
  /* border-radius: 15px; */
}

.imageContainer .active .carousel-item {
  text-align: center;
}

.create_pv_add {
  position: absolute;
  right: 17%;
  top: 0%;
  cursor: pointer;
  /* background-color: #d3d3d3; */
  border-radius: 50%;
  padding: 0px 4px 2px 4px;
  opacity: .75;
}

.create_pv_add img {
  width: 20px;
}

/* .feelings_head{
  position: absolute;
  top: 16%;
  right: 0%;
}
.activites_head{
  position: absolute;
  top: 15%;
  right: 10%;
} */

.mainContainer .imageContainer video {
  /* height: 400px; */
  width: 55%;
  width: 97%;
  border-radius: 15px;
  margin: auto;
}

.bestClip-selectedVideo {
  width: 100% !important;
  border-radius: 15px;
  margin: auto;
  text-align: center;
  /* height: 200px !important; */
}

.imageContainer .carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.imageContainer .carousel-control-next-icon,
.imageContainer .carousel-control-prev-icon {
  display: none;
}

.createMeme-2-Meme {
  /* border: 1px solid white; */
  /* max-height:250px; */
}

.mainContainer .inputContainer {
  position: absolute;
  top: 5px;
  left: 20px;
  z-index: 100;
  border-radius: 50%;
}

.mainContainer .nextButton,
.prevButton {
  position: absolute;
  top: 40%;
  border: none;
  width: 30px;
  background-color: #ffffff1a;
  color: #d3d3d3;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.mainContainer .nextButton:hover,
.prevButton:hover {
  transition: ease-in 0.2s;
  background-color: #ffffffbd;
  color: #000;

}

.nextButton_story,
.prevButton_story {
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 50%;
  margin: 2px;
}

.mainContainer .carousel-control-next {
  position: absolute;
  right: -200px;
  width: 0px;
  height: 0px;

}


.place-card .place-card-large {
  display: none !important;
}

.dummy_map .gm-inset-light {
  display: none !important;
}

.gmnoprint {
  display: none !important;
}

.mainContainer .carousel-control-prev {
  position: absolute;
  left: -200px;
  height: 0px;
  width: 0px;

}

.mainContainer .nextButton {
  right: 3%;
  z-index: 5;
}

.mainContainer .prevButton {
  left: 3%;
  z-index: 5;
}

.mainContainer .tagHeading {
  border-radius: 10px;
  padding: 2px 5px;
  background-color: transparent;
  margin-left: 30px;
  color: #000000a1;
}

.pv_btn {
  color: #000 !important;
  background-color: transparent;
  padding: 2px 5px;
  border-radius: 10px;
  font-size: 12px;
  border: 1px solid #d3d3d3;
}

.meme_add {
  color: #FFF !important;
  background-color: transparent;
  padding: 2px 5px;
  border-radius: 10px;
  font-size: 12px;
  border: 1px solid #d3d3d3;
}

.pv_dlt {
  color: #000000b9 !important;
  background-color: #e0dfdf;
  padding: 3px 5px;
  border-radius: 50%;
  font-size: 12px;
  border: none;
}

.back_01 {
  background-color: #63A4ED;
  color: white;
  outline: none;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  margin-top: 20px;
  outline: none;
  border: none;
}

.back_01:hover {
  background-color: snow;
  color: #63A4ED;
  border: 1px solid #63A4ED;
  transition: ease-in 0.2s;
}

.activityButton {
  outline: none;
  /* border: 2px solid rgba(128, 128, 128, 0.691); */
  padding: 2px 5px;
  color: black;
  border-radius: 10px;
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;
}

.suggested-flix .slick-slide {
  margin: 0px 5px !important;
}

.activityButton img {
  width: 20px;
}

.sg_filx_play {
  z-index: 1;
  position: absolute;
  top: 11px;
  right: 7px;
  font-size: 17px;
  color: #fff;
  opacity: 0.8;
}

.inputContainer .searchContainerMain {
  position: relative;

}

.tagged_user_head {}

.tagged_users {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  gap: 10px;
  padding: 10px;
  border: 1px solid #dbd9d9;
  margin-top: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.tagged_users span {
  padding: 2px 3px;
  background-color: #f4f4f4;
}

.searchContainer_text {
  border: 1px solid #d3d3d3;
  position: relative;
  border-radius: 15px;
  text-align: center;
  padding: 10px;
  max-width: 90%;
  margin: auto;
  min-height: 300px;
  max-height: 300px;
  overflow-y: scroll;
}

.searchContainer_text::-webkit-scrollbar {
  width: 10px;
}

.searchContainer_text::-webkit-scrollbar-thumb {
  width: 10px;
}

.searchContainer_text input {
  width: 100%;
  padding: 5px;
  border: 1px solid #d3d3d3;
  border-radius: 10px;

}


.inputContainer .searchContainerMain .searchContainer {
  position: absolute;
  top: 37px;
  height: 200px;
  width: 203px;
  background-color: #ffffffe8;
  overflow-y: scroll;
  border-radius: 15px;
  padding: 15px;
}

.searchContainer .searchDiv {
  margin: 10px 0px;
  cursor: pointer;
}

.inputContainer .searchContainerMain .searchContainer::-webkit-scrollbar {
  background-color: transparent;
}

.inputContainer .searchContainerMain .searchContainer input {
  position: sticky;
  width: 168px;
  border: 0.5px solid #d3d3d3;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 20px;
  top: 0px;
  left: 0px;

  /* z-index: 1; */

}

.inputContainer .searchContainerMain .searchContainer input::placeholder {
  font-style: italic;
  padding: 5px;
}

.tagUserLIst {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  margin: 7px 0px;
}

.tagUserLIst img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.tagUserLIst span {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tagUserLIst span small:nth-child(2) {
  color: #9b9898;
}

.searchLoader {
  position: relative;
  top: 90px;
  left: 80px;
}

.searchLoader_story {
  position: absolute;
  top: 90px;
  left: 45%;
}

.taggedContainer>div {
  position: absolute;
  width: fit-content;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
  background-color: #252324;
  color: snow;
  padding: 5px;
  /* z-index:2; */
}

.taggedContainer>div .delTag {
  cursor: pointer;
}



.videoTags {
  display: flex;
  width: calc(100% - 50px);
  margin: auto;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.videoTags>div {
  width: 100%;
  width: fit-content;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
  color: black;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  margin: 10px 0px;
  background-color: snow;
  /* z-index:2; */
}

.videoTags>div .delTag {
  cursor: pointer;
}

.meme-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupButtons {
  position: relative;
}

.popupButtons .popBtnPrev,
.popBtnNext {
  position: absolute;
  top: -500px;
  background-color: #e6dede;
  border-radius: 50%;
  padding: 10px 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  ;
}

.popupButtons .popBtnNext {
  right: -100px;
}

.popupButtons .popBtnPrev {
  left: -100px;
}




/* *** Profile > Memes *** */

.profileMemes {
  /* border: 1px solid black; */
  width: 73%;
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
}

.profileMemes-image {
  /* border: 1px solid red; */
  height: 215px;
  width: 200px;
  margin: 0px 38px 38px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 0px gray;
  cursor: pointer;
}

.profileMemes-video {
  /* border: 1px solid red; */
  height: 215px;
  width: 200px;
  margin: 0px 38px 38px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 0px gray;
  cursor: pointer;
}




/* ***** Profile > Meme > Private ***** */

.profileMemes-private {
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.profileMemes-private-img img {
  height: 75px;
  margin: 75px 0px 15px;
}

.profileMemes-private-head {
  font-size: 17px;
}





.viewPost {
  columns: 1;
  position: relative;
}

.viewPost .tagsPost {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  padding: 10px;
  top: 0px;
  right: 0px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.712);
  cursor: pointer;
  z-index: 1000;
}

.viewPost .tagsPost .icon {
  height: 100%;
  width: 100%;
}


/* *** Profile > Videos *** */

.profile-videos {
  /* border: 1px solid black; */
  width: 73%;
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
}

.profile-videos-div {
  position: relative;
  /* border: 1px solid red; */
  height: 215px;
  width: 200px;
  margin: 0px 38px 38px;
}

.profile-videos video {
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 3px 0px gray;
  object-fit: cover;
}

.profile-videos-delete {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 50px;
}


/* *** Profile > Videos > Popup *** */

.profileVideosPopup {
  /* border: 1px solid black; */
  display: flex;
}


/* *** Profile > Videos > Popup > Video *** */

.profileVideosPopup-video {
  /* border: 1px solid red; */
  border-radius: 10px;
  background-color: black;
  height: 750px;
  width: 50%;
  padding: 5px 5px 5px 7px;
}

.profileVideosPopup-video-slider {
  /* border: 1px solid pink; */
  height: 750px;
}

.profileVideosPopup-video-pav {
  /* border: 1px solid blue; */
  height: 740px;
}

.profileVideosPopup-video-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.profileVideosPopup-video-video {
  height: 100%;
  width: 100%;
}


/* *** Profile > Videos > Popup > Details *** */

.profileVideosPopup-details {
  /* border: 1px solid red; */
  /* border-left: 1px solid gray; */
  width: 50%;
}

.profileVideosPopup-details-user {
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
}

.profileVideosPopup-details-profile {
  /* border: 1px solid black; */
  border-radius: 50%;
  margin: 0px 15px;
}

.profileVideosPopup-details-header {
  /* border: 1px solid black; */
  width: 80%;
}

.profileVideosPopup-details-head {
  display: flex;
}

.profileVideosPopup-details-name {
  font-size: 20px;
  font-weight: 500;
  margin: 0px 10px 0px 0px;
}

.profileVideosPopup-details-activity {
  margin: 5px 0px 0px 20px;
}

.profileVideosPopup-details-location {
  margin: 0px 20px 0px 0px;
}

.profileVideosPopup-details-time {
  margin: 3px 0px 0px;
}

.profileVideosPopup-details-threeDot {
  cursor: pointer;
}

.profileVideosPopup-details-hr {
  background-color: gray;
  width: 97%;
  margin: 10px auto;
}

.profileVideosPopup-details-comments {
  /* border: 1px solid red; */
  height: 415px;
  margin: 0px 0px 0px 15px;
}

.profileVideosPopup-details-comments-head {
  /* border: 1px solid black; */
  text-align: center;
  margin: 0px;
}

.profileVideosPopup-details-comments-show {
  /* border: 1px solid black; */
  height: 325px;
  padding: 10px;
  overflow-y: scroll;
}

.profileVideosPopup-details-comments-show::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.profileVideosPopup-details-comments-create {
  border: 1px solid #c9c7c7;
  height: 50px;
  border-radius: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 7px 0px 0px;
}

.profileVideosPopup-details-comments-create-icon {
  cursor: pointer;
}

.profileVideosPopup-details-comments-create-form input {
  height: 30px;
  width: 375px;
  margin: 7px 0px 0px;
  border: none;
}

.profileVideosPopup-details-comments-create-btn {
  color: rgb(20, 103, 234);
  cursor: pointer;
}

.profileVideosPopup-details-area {
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  margin: 5px 0px;
}

.profileVideosPopup-details-area-div {
  /* border: 1px solid red; */
  margin: 0px 15px 0px;
  display: flex;
  align-items: center;
}

.form-group .accordion-item {
  /* border: none !important; */
  background-color: #f3f3f3 !important;
  padding: 8px 0px;
}

.form-groups .accordion-item {
  background-color: #f3f3f3 !important;
  padding: 8px 0px;
}

.form-group .accordion-header {
  display: flex;
  justify-content: flex-start;
  align-self: center;
  width: 100% !important;
  background-color: transparent !important;
}

.accordion-header {
  width: 100%;
}

.profileVideosPopup-details-area-icon {
  margin: 0px 5px 0px 0px;
  cursor: pointer;
}



.profileVideosPopup-details-area-favourite {
  margin: 0px 25px 0px 135px;
  cursor: pointer;
}

.profileVideosPopup-details-area-download {
  cursor: pointer;
}

.profileVideosPopup-details-description {
  /* border: 1px solid black; */
  margin: 15px 0px 0px 15px;
  height: 100px;
  overflow-y: scroll;
}

.profileVideosPopup-details-description::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}


/* *** Profile > Photos And Videos *** */


.profilePost {
  width: 80%;
  margin: 0px auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* border: 1px solid green; */
}

.profilePost-post {
  /* border: 1px solid black; */
  height: 215px;
  width: 200px;
  margin: 0px 40px 80px;
  cursor: pointer;
  position: relative;
}

.profilePost-post-img {
  /* border: 1px solid red; */
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 0px 3px 0px gray;
}

.profilePost-post-video {
  /* border: 1px solid red; */
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 0px 3px 0px gray;
}

.profilePosts-post-icon {
  height: 23px;
  width: 27px;
  margin: 3px;
  background-color: black;
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: center;
  border-radius: 5px;
  opacity: 25%;
}

.profilePosts-post-icon-img {
  /* border: 1px solid red; */
  height: 15px;
  width: 17px;
  margin-bottom: 5px;
}



/* *** Profile > Photos And Videos > Popup *** */

.profilePostsPopup {
  /* border: 1px solid black; */
  display: flex;
}


/* *** Profile > Photos And Videos > Popup > Posts *** */

.profilePostsPopup-post {
  /* border: 1px solid red; */
  border-radius: 10px;
  background-color: black;
  height: 750px;
  width: 50%;
  padding: 5px 5px 5px 7px;
}

.profilePostsPopup-post-slider {
  /* border: 1px solid pink; */
  height: 750px;
}

.profilePostsPopup-post-pav {
  /* border: 1px solid blue; */
  height: 740px;
}

.profilePostsPopup-post-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.profilePostsPopup-post-video {
  height: 100% !important;
  width: 100% !important;
}


/* *** Profile > Photos And Videos > Popup > Details *** */

.profilePostsPopup-details {
  /* border: 1px solid green; */
  width: 50%;
}

.profilePostsPopup-details-user {
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
}

.profilePostsPopup-details-profile {
  /* border: 1px solid black; */
  border-radius: 50%;
  margin: 0px 15px;
}

.profilePostsPopup-details-header {
  /* border: 1px solid black; */
  width: 80%;
}

.profilePostsPopup-details-head {
  display: flex;
  flex-direction: column;
}

.profilePostsPopup-details-head {
  display: flex;
  flex-direction: column;
}

.profilePostsPopup-details-name {
  font-size: 20px;
  font-weight: 500;
  margin: 0px 30px 0px 0px;
}

.profilePostsPopup-details-activity {
  margin: 5px 0px 0px;
}

.profilePostsPopup-details-location {
  margin: 0px 20px 0px 0px;
  font-size: 15px;
}

.profilePostsPopup-details-time {
  margin: 3px 0px 0px;
  font-size: 15px;
}

.hri {
  height: 1/2px;
  background-color: #00000054;
}

.profilePostsPopup-details-threeDot {
  cursor: pointer;
}

.profilePostsPopup-details-hr {
  background-color: gray;
  width: 97%;
  margin: 10px auto;
}

.profilePostsPopup-details-comments {
  /* border: 1px solid red; */
  height: 415px;
  margin: 0px 0px 0px 15px;
}

.profilePostsPopup-details-comments-head {
  /* border: 1px solid black; */
  text-align: center;
  margin: 0px;
}

.profilePostsPopup-details-comments-show {
  /* border: 1px solid black; */
  height: 325px;
  padding: 10px;
  overflow-y: scroll;
}

.profilePostsPopup-details-replyComments-show {
  margin: 22px 0px 10px 0px;
}

.profilePostsPopup-details-comments-show::-webkit-scrollbar {
  background-color: transparent;
  width: 0px;
}

.profilePostsPopup-details-comments-show-user {
  /* border: 1px solid red; */
  margin: 0px 0px 17px 0px;
  display: flex;
  align-items: flex-start;
  position: relative;
}

.profilePostsPopup-details-replyComments-show-user {
  /* border: 1px solid red; */
  margin: 0px 0px 10px 0px;
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
}

.profilePostsPopup-details-comments-show-profile {
  border-radius: 50%;
  margin: 0px 10px 0px 0px;
  cursor: pointer;
  border: 1px solid gray;
}

.profilePostsPopup-details-comments-show-head {
  /* border: 1px solid green; */
  width: 420px;
}

.profilePostsPopup-details-comments-show-name {
  display: inline;
  margin: 0px 10px 0px 0px;
  cursor: pointer;
  font-size: 14px;
}

.profilePostsPopup-details-comments-show-time {
  margin: 0px 10px 0px 0px;
  font-size: 15px;
}

.profilePostsPopup-details-comments-show-threeDot {
  cursor: pointer;
}


.profilePostsPopup-details-comments-show-comment {
  display: block;
  font-size: 13px;
}

.profilePostsPopup-details-replyComments-show-head .replyView {
  margin-left: 15px;
  cursor: pointer;

}

.profilePostsPopup-details-comments-show-head .viewReply,
.reply {
  margin-top: 15px;
  cursor: pointer;
}

.profilePostsPopup-details-comments-show-head .reply {
  position: absolute;
  right: 50%;
  top: 26px;
  margin: 0px;
}

.profilePostsPopup-details-comments-show-like {
  cursor: pointer;
  display: none;
}

.profilePostsPopup-details-comments-create {
  position: relative;
  border: 1px solid #c9c7c7;
  height: 50px;
  border-radius: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 7px 0px 0px;

}

.profilePostsPopup-details-comments-create-form {
  position: relative;
}

.profilePostsPopup-details-comments-create-form .emoji-select-emilo {
  position: absolute;
  bottom: 48px;
  left: -50px;
}

.profilePostsPopup-details-comments-create-icon {
  cursor: pointer;
}

.profilePostsPopup-details-comments-create-form input {

  height: 30px;
  width: 375px;
  margin: 7px 0px 0px;
  border: none;
}

.profilePostsPopup-details-comments-create-btn {
  color: snow;
  background-color: #73acee;
  outline: none;
  border: none;
  font-size: 12px;
  cursor: pointer;
  border-radius: 15px;
}

.profilePostsPopup-details-area {
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
}

.profilePostsPopup-details-area-div {
  /* border: 1px solid red; */
  margin: 0px 15px;
  display: flex;
  align-items: center;
}

.profilePostsPopup-details-area-icon {
  cursor: pointer;
  width: 18px;
}

.profilePostsPopup-details-area-div img {
  width: 18px !important;
  margin-right: 5px;
}

.profilePostsPopup-details-area-div span {
  font-size: 14px;
  color: #555151;
}

.profilePostsPopup-details-area-favourite {
  margin: 0px 25px 0px 135px;
  width: 18px;
  cursor: pointer;
}

.profilePostsPopup-details-area-download {
  width: 18px;
  cursor: pointer;
}

.profilePostsPopup-details-description {
  /* border: 1px solid black; */
  margin: 15px 0px 0px 15px;
  height: 100px;
  overflow-y: scroll;
}

.profilePostsPopup-details-description::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}



/* *** Profile > Photos And Videos > Private *** */

.profilePost-private {
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.profilePost-private-img img {
  height: 75px;
  margin: 75px 0px 15px;
}

.profilePost-private-head {
  font-size: 17px;
}






/* *** Profile > Block *** */

.profile-block {
  margin: 20% 0px;
  text-align: center;
}




/* *** Profile > Photos And Videos > Block *** */

.profilePost-block {
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.profilePost-block-img img {
  height: 75px;
  margin: 75px 0px 15px;
}

.profilePost-block-head {
  font-size: 17px;
}






/* ***** Profile > Reels > Popup ***** */

.profileReelPopup {
  /* border: 1px solid black; */
  display: flex;
}


/* ***** Profile > Reels > Popup > Reel ***** */

.profileReelPopup-reel {
  /* border: 1px solid red; */
  height: 750px;
  width: 50%;
}

.profileReelPopup-reel-video {
  /* border: 1px solid green; */
  background-color: black;
  padding: 5px;
  height: 100%;
  width: 100%;
}


/* ***** Profile > Reels > Popup > Details ***** */

.profileReelPopup-details {
  /* border: 1px solid green; */
  width: 50%;
}

.profileReelPopup-details-user {
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
}

.profileReelPopup-details-profile {
  /* border: 1px solid black; */
  border-radius: 50%;
  margin: 0px 15px;
}

.profileReelPopup-details-header {
  /* border: 1px solid black; */
  width: 80%;
}

.profileReelPopup-details-head {
  display: flex;
}

.profileReelPopup-details-name {
  font-size: 20px;
  font-weight: 500;
  margin: 0px 10px 0px 0px;
}

.profileReelPopup-details-activity {
  margin: 5px 0px 0px 20px;
}

.profileReelPopup-details-location {
  margin: 0px 20px 0px 0px;
}

.profileReelPopup-details-time {
  margin: 3px 0px 0px;
}

.profileReelPopup-details-threeDot {
  cursor: pointer;
}

.profileReelPopup-details-hr {
  background-color: gray;
  width: 97%;
  margin: 10px auto;
}

.profileReelPopup-details-comments {
  /* border: 1px solid red; */
  height: 415px;
  margin: 0px 0px 0px 15px;
}

.profileReelPopup-details-comments-head {
  /* border: 1px solid black; */
  text-align: center;
  margin: 0px;
}

.profileReelPopup-details-comments-show {
  /* border: 1px solid black; */
  height: 325px;
  padding: 10px;
  overflow-y: scroll;
}

.profileReelPopup-details-comments-show::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.profileReelPopup-details-comments-create {
  border: 1px solid #c9c7c7;
  height: 50px;
  border-radius: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 7px 0px 0px;
}

.profileReelPopup-details-comments-create-icon {
  cursor: pointer;
}

.profileReelPopup-details-comments-create-form input {
  height: 30px;
  width: 375px;
  margin: 7px 0px 0px;
  border: none;
}

.profileReelPopup-details-comments-create-btn {
  color: rgb(20, 103, 234);
  cursor: pointer;
}

.profileReelPopup-details-area {
  /* border: 1px solid green; */
  display: flex;
  margin: 5px 0px;
}

.profileReelPopup-details-area-div {
  /* border: 1px solid red; */
  margin: 0px 15px 0px;
  display: flex;
  align-items: center;
}

.profileReelPopup-details-area-icon {
  margin: 0px 5px 0px 0px;
  cursor: pointer;
}

.profileReelPopup-details-area-favourite {
  margin: 0px 25px 0px 135px;
  cursor: pointer;
}

.profileReelPopup-details-area-download {
  cursor: pointer;
}

.profileReelPopup-details-description {
  /* border: 1px solid black; */
  margin: 15px 0px 0px 15px;
  height: 100px;
  overflow-y: scroll;
}

.profileReelPopup-details-description::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}



/* ***** Profile > Reels > Private***** */

.profileReels-private {
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.profileReels-private-img img {
  height: 75px;
  margin: 75px 0px 15px;
}

.profileReels-private-head {
  font-size: 17px;
}







/* ***** Profile > Best Clips > Popup ***** */

.profileBestClipPopup {
  /* border: 1px solid black; */
  display: flex;
}


/* ***** Profile > Best Clips > Popup > Best Clip ***** */

.profileBestClipPopup-bestClips {
  /* border: 1px solid red; */
  border-radius: 10px;
  background-color: black;
  height: 750px;
  width: 50%;
  padding: 5px 5px 5px 7px;
}

.profileBestClipPopup-bestClip-slider {
  /* border: 1px solid pink; */
  height: 750px;
}

.profileBestClipPopup-bestClip {
  /* border: 1px solid blue; */
  height: 740px;
}

.profileBestClipPopup-bestClip-video {
  height: 100%;
  width: 100%;
}



/* ***** Profile > Best Clips > Popup > Details ***** */

.profileBestClipPopup-details {
  /* border: 1px solid green; */
  width: 50%;
}

.profileBestClipPopup-details-user {
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
}

.profileBestClipPopup-details-profile {
  /* border: 1px solid black; */
  border-radius: 50%;
  margin: 0px 15px;
}

.profileBestClipPopup-details-header {
  /* border: 1px solid black; */
  width: 80%;
}

.profileBestClipPopup-details-head {
  display: flex;
}

.profileBestClipPopup-details-name {
  font-size: 20px;
  font-weight: 500;
  margin: 0px 10px 0px 0px;
}

.profileBestClipPopup-details-activity {
  margin: 5px 0px 0px 20px;
}

.profileBestClipPopup-details-location {
  margin: 0px 20px 0px 0px;
}

.profileBestClipPopup-details-time {
  margin: 3px 0px 0px;
}

.profileBestClipPopup-details-threeDot {
  cursor: pointer;
}

.profileBestClipPopup-details-hr {
  background-color: gray;
  width: 97%;
  margin: 10px auto;
}

.profileBestClipPopup-details-comments {
  /* border: 1px solid red; */
  height: 415px;
  margin: 0px 0px 0px 15px;
}

.profileBestClipPopup-details-comments-head {
  /* border: 1px solid black; */
  text-align: center;
  margin: 0px;
}

.profileBestClipPopup-details-comments-show {
  /* border: 1px solid black; */
  height: 325px;
  padding: 10px;
  overflow-y: scroll;
}

.profileBestClipPopup-details-comments-show::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.profileBestClipPopup-details-comments-create {
  border: 1px solid #c9c7c7;
  height: 50px;
  border-radius: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 7px 0px 0px;
}

.profileBestClipPopup-details-comments-create-icon {
  cursor: pointer;
}

.profileBestClipPopup-details-comments-create-form input {
  height: 30px;
  width: 375px;
  margin: 7px 0px 0px;
  border: none;
}

.profileBestClipPopup-details-comments-create-btn {
  color: rgb(20, 103, 234);
  cursor: pointer;
}

.profileBestClipPopup-details-area {
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  margin: 5px 0px;
}

.profileBestClipPopup-details-area-div {
  /* border: 1px solid red; */
  margin: 0px 15px 0px;
  display: flex;
  align-items: center;
}

.profileBestClipPopup-details-area-icon {
  margin: 0px 5px 0px 0px;
  cursor: pointer;
}

.profileBestClipPopup-details-area-favourite {
  margin: 0px 25px 0px 135px;
  cursor: pointer;
}

.profileBestClipPopup-details-area-download {
  cursor: pointer;
}

.profileBestClipPopup-details-description {
  /* border: 1px solid black; */
  margin: 15px 0px 0px 15px;
  height: 100px;
  overflow-y: scroll;
}

.profileBestClipPopup-details-description::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}




/* ***** Profile > BestClip > Private ***** */

.profileBestClips-private {
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.profileBestClips-private-img img {
  height: 75px;
  margin: 75px 0px 15px;
}

.profileBestClips-private-head {
  font-size: 17px;
}









/* ***** Tag ***** */

.displayTags {
  position: absolute;
  color: #f4f4f4;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.651);
  width: fit-content;
  border-radius: 10px;
}

.displayTags_story {
  position: absolute;
  color: #000000e0;
  padding: 5px 10px;
  background-color: rgba(241, 239, 239, 0.555);
  width: fit-content;
  border-radius: 10px;
  z-index: 999;
  cursor: pointer;
}

.displayTags>a {
  color: snow;
}

.displayTags_story>a {
  color: black;
}

.tagProfilePopUp {
  display: flex;
  justify-content: space-between;
}

.tagProfilePopUp div {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.tagProfilePopUp a {
  color: black;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  font-size: 20px;
  align-items: center;
}

.tagProfilePopUp a img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 10px 0px;
}

.search-from {
  position: relative;
}

.search-box {
  margin-top: 53px;
  min-height: 300px;
}

.search-box .searchList {
  position: relative;
  margin: 12px 0px 7px 20px;
  max-height: 250px;
  overflow-y: scroll;
  min-height: 250px;
  padding: 20px 10px;
}


.search-box .searchList::-webkit-scrollbar {
  width: 10px;
}

.group-final {
  height: fit-content;
  margin-top: 50px;
}

.group-final>form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

}

.group-name,
.group-desc {
  width: 100%;
  margin: auto;
  /* background-color: red; */
  margin-top: 15px;
  position: relative;
}

.group-name .group-name-input {
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: center;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #d3d3d3;
}

.group-desc .group-desc-input {
  padding: 10px;
  width: 100%;
  resize: none;
  border: 1px solid #d3d3d3;
  border-radius: 15px;
}

.group-name>img {
  position: absolute;
  cursor: pointer;
  right: 65px;
  top: 10px;
}

.group-desc>img {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.group-name>input::placeholder,
.group-desc>textarea::placeholder {
  color: #d3d3d3;
}

.emoji-select-group {
  position: relative;
  right: -10%;
  margin: 20px 0px;
}

.select-group-icon {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

}

.select-group-icon-dummy {
  height: 150px;
  width: 150px;
  border: 1px solid black;
  object-fit: cover;
  opacity: 0.1;
  border-radius: 50%;
  padding: 10px;
}

.select-group-icon-selected {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: contain;
  background-color: black;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.search-box .searchList .searchLoaderShare {
  position: absolute;
  right: 50%;
  top: 80px;
}


.selected-mem-list::-webkit-scrollbar {
  background-color: transparent;
}

.selected-mem-list {
  height: 150px;
  overflow-y: scroll;
  background-color: #f3f3f3;
  padding: 10px 15px;
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.selected-mem {
  background-color: #64A6F0;
  color: snow;
  margin: 5px;
  height: fit-content;
  border-radius: 10px;
  padding: 2px 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.selected-mem>span:nth-child(2) {
  color: #0B71F6;
  cursor: pointer;
}

.selected-mem-list span {
  margin: 5px;
}

.search-box-main .modal-header h6 {
  text-align: center;
  width: 100%;
}

.search-box-main .modalBody {
  height: 400px;
  background-color: #d3d3d35e;
  overflow-y: scroll;
}

.search-box-main .modalBody ::-webkit-scrollbar {
  background-color: #252324;
}

.group-create-button {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  background-color: #64A6F0;
  color: snow;
  border-radius: 25px;
  padding: 10px;
}

.search-from input {
  width: 420px;
  margin: auto;
  /* margin-left: 20px; */
}

.input-box .search {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 5;
}

.input-box .search input {
  width: 100%;
  padding: 10px 20px;
  border-radius: 15px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  margin-left: calc(100% - 96%);
}



.search-from button {
  position: absolute;
  top: 0px;

  border-top-left-radius: 40%;
  border-bottom-left-radius: 40%;
}

.searchList .name-img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-area: 20px;
}

.modal-content {
  position: relative;
}

.modal-content .modal-bottom-btn {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: #d3d3d3;
  text-align: center;
}

.modal-content .modal-bottom-btn button {
  padding: 10px auto;
  width: 300px;
  margin: 10px auto;
  font-size: 20px;
  color: white;
  border: none;
  background-color: #64A6F0;
  border-radius: 25px;
  letter-spacing: 1px;
}




/* *** Profile > Post > Popup > Three Dot Popup *** */

.profilePostThreeDotPopup {
  /* border: 1px solid black; */
  padding: 10px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profilePostThreeDotPopup-text {
  /* border: 1px solid red; */
  font-size: 19px;
  cursor: pointer;
}

.profilePostThreeDotPopup-text-1 {
  color: red;
}

.profilePostThreeDotPopup hr {
  /* background-color: red; */
  border: 1px solid gray;
  width: 113%;
  margin: 10px;
}






/* *** Profile > Reel > Popup > Three Dot Popup *** */

.profileReelThreeDotPopup {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profileReelThreeDotPopup-text {
  /* border: 1px solid red; */
  font-size: 20px;
  cursor: pointer;
}

.profileReelThreeDotPopup-text-1 {
  color: red;
}

.profileReelThreeDotPopup hr {
  /* background-color: red; */
  border: 1px solid gray;
  width: 113%;
  margin: 10px;
}

.op-0 {
  opacity: 0;
}

.op-8 {
  opacity: 0.8;
}

.bright-06 {
  filter: brightness(0.6);
}

.insight_cmt {
  position: absolute;
  top: 5px;
  right: 5px;
}

.red_dot {
  top: 0px;
  right: 0px;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  position: absolute;
  background-color: red;
}


.insight_v_l span {
  display: flex;
  gap: 2px;
  color: white !important;
}

.insight_v_l {
  position: absolute;
  width: 100%;
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
  bottom: 2px;
}

.insight_v_l img:nth-child(1) {
  width: 20px;
}

.accordion-button {
  background-color: transparent;
  padding: 0px;
  margin: 0px;
}

.postBox_3 {
  border-radius: 10px !important;
}

.postBox_1 {
  border: 1px solid #d3d3d3;
  border-radius: 10px !important;
}


.postBox .tox-toolbar__primary .tox-toolbar__group:nth-child(2) {
  display: none !important;
}

.postBox .tox .tox-tbtn--bespoke {
  width: 70px !important;
  margin-left: 10px !important;
}

.html_png {
  overflow: hidden;
  /* Hides any content that overflows */
  height: 100%;
  /* Ensure the div takes the full height */
  background-size: contain;
  position: relative;
  z-index: 100000;
}

.scrollbar-hidden {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and Opera */
}

.html_png {
  overflow: hidden;
}

.text_opener::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and Opera */
}

.ins_icon {
  width: 18px;
  opacity: 0.5;
}

.ins_link {
  font-size: 12px;
}


.bg_style {
  background-repeat: no-repeat;
  background-size: cover;
  /* border-radius: 20px; */
  z-index: 2;
  /* filter: brightness(0.8); */
}

:root {
  --jd-color-icon: #000000;
}

.tox .tox-edit-area__iframe {
  background-color: transparent !important;
}

.jodit-ui-group .jodit-ui-group_size_middle {
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
  overflow-y: hidden;
}

.jodit-ui-group .jodit-ui-group_size_middle::-webkit-scrollbar {
  height: 5px;
  background-color: transparent;
  border: none;
}

.jodit-ui-group .jodit-ui-group_size_middle::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
  height: 5px;
}

.jodit-container .jodit .jodit_theme_default .jodit-wysiwyg_mode {
  height: 300px !important;
  border-radius: 15px;
  background-attachment: red;
  border: 1px solid red;
  resize: none;
}

.jodit-ui-group .jodit-ui-group_size_middle {
  background-color: #fff;
}

.bg_image_head {
  /* display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  width: fit-content; */
}

.bg-images {
  width: 78px !important;
  margin-top: 10px;
  cursor: pointer;
}

.bg_image_head .slick-dots {
  display: none !important;
}


.bg-images img {
  max-width: 100%;
  border-radius: 10px;
}

.nav-tabs>.nav-item {
  width: 50%;
}

.nav-tabs>.nav-item>button {
  width: 100%;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:hover {
  border: none !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none !important;
  border-bottom: 3px solid #63A4ED !important;
}

.checked_img {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 24px;
}

.tab-content::-webkit-scrollbar {
  width: 10px !important;
}

.tab-content::-webkit-scrollbar-thumb {
  width: 10px !important;
  background-color: #d3d3d3;
  border-radius: 15px;
}

.createStoriesPopup .modal-body {
  padding: 0px !important;
}

.loader-story {
  height: 300px;
  border: 1px solid;
  width: 100%;
}

.loader-nodata {
  /* border: 1px solid ; */
  width: 50%;
  min-height: 200px;
  margin: auto;
  margin-top: 50px;
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.loader-nodata img {
  max-width: 150px;
  margin-bottom: 20px;
}


@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}



.text-editor,
.text-editor2 {
  position: relative;
  z-index: 3;
  min-height: 30vh;
  max-height: 33vh;
  overflow-x: hidden;
  /* overflow-y: hidden; */
}

.text-editor2 {
  border-radius: 1px;
}

.bg_style_text {
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 15px;
}

.loader-hw {
  position: absolute;
  top: 0px;
  left: 50%;
  z-index: 1;
  min-height: 300px;
}

.page_edit2 {
  border: 1px solid #d3d3d3;
}

.page_edit1::-webkit-scrollbar {
  width: 0px;
}

.page_edit2::-webkit-scrollbar {
  width: 10px;

}

.page_edit2::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #e9e8e8;
}

.text-editor::-webkit-scrollbar {
  width: 0px;
}


.white_nowrap {
  white-space: nowrap;
}

.white_pre {
  white-space: pre-wrap;
}

.text_bg_image {
  opacity: 0.3;
}

.text_opener {
  position: relative;
  z-index: 3;
  /* min-height: 32vh; */
  /* max-height: 36vh; */
  /* width: 100%; */
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  overflow: scroll;
  overflow-x: hidden;
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  overflow-y: hidden;
  /* white-space: pre; */
}

.text_opener pre {
  background-color: transparent !important;
}

.text-tags-container {
  position: absolute;
  min-height: 200px;
  background: rgba(255, 255, 255, 0.644);
  height: 200px;
  z-index: 10;
  top: 41px;
}

.preview_tags {
  position: absolute;
  background-color: rgb(35 33 33);
  padding: 2px 4px;
  border-radius: 4px;
  z-index: 3 !important;
  color: white !important;
  cursor: pointer !important;
}

.search-section {
  position: relative;
}

.search-section input {
  background-color: rgba(255, 255, 255, 0.644);
  border-radius: 5px;
  padding: 2px 3px;
  border: 0px;
}

.text_opener span {
  padding: 10px;
}

.text_opener {
  padding: 10px;
}

.text_opener::-webkit-scrollbar {
  /* background-color: red; */
  width: 0px;
}


/* .text_opener::before{
  content: "write something";
  position: absolute;
  font-size: 18px;
  top: 50%;
  margin: auto;
  left: 35%;
  color: #d3d3d3;
  opacity: 1;
} */

.tox .tox-tbtn--bespoke {
  width: 100px !important;
}



.desp-form textarea {
  background-color: transparent;
  color: snow;
}

.desp-form .tox-toolbar-overlord {
  display: none !important;
}

.desp-form .tox-editor-header {
  display: none !important;
}

.desp-form .tox-editor-footer {
  display: none !important;
}

.desp-form .tox .tox-edit-area::before {
  border: none;
}

.tox-statusbar {
  display: none !important;
}

.desp-form .tox-tinymce {
  border: 2px solid #eee;
}

.footer_htao_des {
  position: absolute;
  width: 99%;
  left: 2px;
  height: 28px;
  bottom: 2px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: white;
}

.footer_htao {
  /* position: absolute; */
  /* width: 99%; */
  left: 2px;
  height: 28px;
  bottom: 4px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  /* background-color: white; */
}

.feelings_nav,
.tab-content {
  max-width: 500px;
  max-height: 500px;
  overflow-x: hidden;
}

p {
  margin-bottom: 0px !important;
}

.feel_list:hover {
  background-color: #64a5f0cc;
  color: white;
  cursor: pointer;
}

.feel_ht {
  max-height: 50vh;
  min-height: 50vh;
  overflow: hidden;
  overflow-y: scroll;
}

.feel_input>span {
  position: absolute;
  right: 20px;
  top: 5px;
  opacity: 0.6;
}

.feel_input input {
  border: 1px solid #e2e1e1;
  border-radius: 15px;
  padding: 6px;
}

.feel_input input::placeholder {
  font-style: italic;
  padding: 10px;
  opacity: 0.7;
}

.cate_btn {
  border: none;
  border-radius: 15px;
  padding: 10px;
  color: #2778d4cc;
  background: #f3f3f394;
  position: relative;
  left: -38%;
  font-size: 12px;
  font-weight: bold;
}

.feel_header {
  /* position: fixed; */
}

.feel_img {
  width: 25px;
}

.image-wrap {
  position: relative;
  z-index: 10;
}



.text_loader {
  position: absolute;
  top: 50%;
  right: 50%;
  z-index: 2;
}

.text_loader {
  width: 25px;
  aspect-ratio: 1;
  display: grid;
  animation: l14 4s infinite;
}

.text_loader::before,
.text_loader::after {
  content: "";
  grid-area: 1/1;
  border: 4px solid;
  border-radius: 50%;
  border-color: red red #0000 #0000;
  mix-blend-mode: darken;
  animation: l14 1s infinite linear;
}

.text_loader::after {
  border-color: #0000 #0000 blue blue;
  animation-direction: reverse;
}

@keyframes l14 {
  100% {
    transform: rotate(1turn)
  }
}



.text-preview {
  margin: 10px 0px;
  padding-bottom: 12px !important;
  border-radius: 20px;
  cursor: pointer;
  transition: 2s ease-in;
  background-color: #f4f4f48f;
}

.text-preview:hover {
  background-color: #f4f4f4;
}

.text_post_btn {
  width: 100%;
}

.text_post>div>div {
  text-align: start !important;
}


/* ***** Like Reel Button 2 ***** */

.likeReelBtn2 {
  /* border: 1px solid black; */
  margin: -3px 0px 0px 0px;
}

.likeReelBtn2-img {
  margin: 0px 5px 0px 0px;
  cursor: pointer;
}

.customBtn {
  padding: 5px 10px;
  background-color: #64A6F0;
  color: snow;
  border-radius: 10px;
}

.likeReelBtn2-count {
  font-size: 13px;
  cursor: pointer;
}

.css-1fdsijx-ValueContainer {}

.css-13cymwt-control {
  border: none;
  border-color: transparent !important;
  outline: none !important;
}

.sc-datePlanner-dates {
  display: flex;
  flex-direction: column;
}

.plan-back-btn {
  display: flex;
  cursor: pointer;
}

.desp-form {
  position: relative;
}

.desp-form textarea {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #d3d3d3;
  width: 94%;
  resize: none;
}

.smiley {
  position: absolute;
  right: 25px;
  top: 0px;
  background-color: transparent;
  border: none;
}

.emoji-select-desp {
  position: absolute;
  right: 97px;
  bottom: 7px;
  z-index: 10;
}







/* ***** Chats ***** */

.chats {
  /* border: 1px solid black; */
  height: 100vh;
  overflow-y: hidden;
}

.chat-header {
  justify-content: flex-end;
}

.offcanvas {
  width: 41% !important;
  background-color: #f1efef;
}

.offcanvas-header {
  position: relative;
}

.chat-filter-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  text-align: center;
  width: 100%;
  font-weight: 600;
  letter-spacing: 1px;
}

.chat-filter-body {
  padding: 0px 20px;
}

.chat-date-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  gap: 10px;
}

.chat-date-header hr {
  width: 36%;
  background-color: #63A4ED;
}

.chat-filter-body li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin: 15px 0px;
  cursor: pointer;
}

.close-btn {
  position: absolute;
  right: 50px;
  top: 45px;
  color: rgba(0, 0, 0, 0.76);
  font-size: 15px;
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid gray;
}


.chatInfo-Header {
  width: 450px;
  margin: auto;
  margin-left: 10%;
  margin-top: 5%;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  position: relative;
  z-index: 100000;
}

.chatInfo-Header .profile_dp {
  max-height: 100px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  border-radius: 50%;
}

.chatInfo-Header .profile_dp img {
  object-fit: contain;

}

.chatInfo-desc {
  color: rgba(0, 0, 0, 0.774);
  font-size: 12px !important;
  padding-top: 20px;
  max-width: 80%;
  margin-left: 10%;
}

.chatprofile-edit {
  position: relative;
  right: -100px;
  opacity: 0.5;
  cursor: pointer;
}


.profile-picture-icon {
  position: absolute;
  bottom: 0px;
  right: 60px;
  font-size: 17px;
  background-color: #ebebeb;
  padding: 5px 10px;
  border-radius: 25px;
}

.chatprofile-add {
  position: relative;
  right: -120px;
  opacity: 0.5;
  cursor: pointer;
}

.chatInfo-hr {
  height: 1px;
  /* margin: 10px 0px; */
  background-color: #d3d3d3;
  width: 100%;
}

.chatInfo-links {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.chatInfo-links button {
  background-color: #d3d3d3;
  border-radius: 10px;
  width: 100%;
  margin: 0px 5px;
}

.chatInfo-links button svg {
  margin: 0px 5px;
}

.chatInfo-links .btn-style {
  background-color: black;
  color: snow;
}

.user-chat-profile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.user-chat-profile img {
  max-width: 15%;
  border-radius: 50%;
}

.chatInfo-body .nav {
  display: flex;
  justify-content: space-around;
}

.chatInfo-body>.nav-tabs>.nav-item {
  width: 25% !important;
}

.chatInfo-body .tab-content {
  max-width: 100%;
  max-height: fit-content;

}

.info_set {
  /* min-width: 100px; */
  /* border: 1px solid ; */
  /* background: black; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start !important;
  max-width: 100px;
  margin: 0px 10px;
}

.max-w-100 {
  max-width: 100px;
}

.leave-group {
  display: flex;
  justify-content: flex-end;
}

.offcanvas-title {
  width: 100%;
}

.leave-group button {
  font-size: 12px !important;
  outline: none !important;
  margin: 0px 5px;
}

.leave-group button:first-child {
  font-size: 12px !important;
  background-color: #63A4ED !important;
}

.leave-group button:nth-child(2) {
  font-size: 12px !important;
  background-color: #ff474c !important;
}

.chats-cb {
  /* border: 1px solid red; */
  margin: 65px 0px 0px;
  padding: 10px;
}

.chats-cb-chat {
  /* border: 1px solid black; */
  height: 85vh;
  width: 90%;
  margin: 10px auto 0px;
  overflow-y: scroll;
}

.chats-cb-chat::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.chats-cb-call {
  /* border: 1px solid black; */
  height: 800px;
  width: 90%;
  margin: 10px auto 0px;
  overflow-y: scroll;
}

.chats-cb-call::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.chats-rb {
  /* border: 1px solid green; */
  margin: 75px 0px 0px;
  box-shadow: 0px 0px 5px 0px rgb(189, 189, 189);
}

.chats-cb-toggle {
  /* border: 1px solid black; */
  width: 90%;
  margin: 0px auto 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.chats-cb-toggle-div {
  /* border: 1px solid black; */
  cursor: pointer;
  padding: 10px;
}

.chats-cb-toggle-div span {
  margin: 0px 0px 0px 10px;
  font-size: 17px;
  font-weight: 500;
  color: black;
}

.chats-cb-chat-search {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 25px 0px;
}

.chats-cb-chat-search-form {
  /* border: 1px solid green; */
  border-radius: 30px;
  height: 40px;
  width: 72%;
  display: flex;
  align-items: center;
  background-color: #f5f4f4;
}

.chats-cb-chat-search-form img {
  margin: 10px 5px 10px 17px;
}

.chats-cb-chat-search-form input {
  height: 35px;
  width: 250px;
  padding: 0px 10px;
  margin: 7px 0px 0px;
  border: none;
  font-size: 17px;
  background-color: transparent;
}

.chats-cb-chat-search-filter {
  color: #2676E1;
  font-weight: 500;
  cursor: pointer;
}

.chats-cb-chat-search-threeDot {
  cursor: pointer;
}

.chats-cb-chat-search-img {
  background-color: #d3d3d375;
  padding: 5px 8px;
  border-radius: 50%;
  cursor: pointer;
  color: rgba(70, 66, 66, 0.555);
}

.chats-cb-chat-pinned-head {
  margin: 0px 0px 20px 0px;
}

.chats-cb-chat-pinned-head span {
  margin: 0px 0px 0px 10px;
  font-weight: 500;
}

.chats-cb-chat-pinned-user {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 20px 0px;
  cursor: pointer;
  color: black;
}

.chats-cb-chat-pinned-user-profile {
  margin: 0px 10px 0px 0px;
}

.chats-cb-chat-pinned-user-nameArea {
  /* border: 1px solid black; */
  width: 330px;
}

.chats-cb-chat-pinned-user-name {
  margin: 0px 10px 0px 0px;
  font-weight: 700;
  font-size: 17px;
}

.chats-cb-chat-all-head {
  margin: 0px 0px 20px 0px;
}

.chats-cb-chat-all-head span {
  font-weight: 500;
}

.gallery_view {
  max-height: 400px;
  overflow: hidden;
  overflow-y: scroll;
}

.gallery_view img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.chats-cb-chat-all-user {
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0px;
  cursor: pointer;
  color: black;
  border-radius: 10px;
  gap: 10px;
}

.chats-cb-chat-all-user-profile {
  margin: 0px 10px 0px 0px;
}

.chats-cb-chat-all-user-nameArea {
  /* border: 1px solid black; */
  width: 330px;
  position: relative;
}

.chats-cb-chat-all-user-nameArea .last-msg {
  position: absolute;
  font-size: 13px;
  /* font-weight: 700; */
  bottom: -20px;
  left: 0px;
  height: 85%;
  width: 85%;
  overflow: hidden;
}

.chats-cb-chat-all-user-name {
  margin: 0px 10px 0px 0px;
  font-weight: 700;
  font-size: 92%;
}

.chats-cb-chat-hr {
  background-color: gray;
}

.chats-cb-chat-pinned-users-profile,
.chats-cb-chat-all-users-profile {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid rgb(205, 205, 205);
}


.message-options-cb {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.add-location-div {
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.hide-users-head {
  position: fixed;
  bottom: 20px;
  background-color: red !important;
}

.rb-name {
  font-size: 15px !important;
  font-weight: bold;
}

.hide-users .accordion-button p {
  color: rgb(107, 106, 106);
  font-size: 15px;
  width: 100%;
  background-color: #E8E8E8;
  padding: 5px 10px;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  font-weight: 700;
}

.hide-body {
  background-color: #f3f3f3;
}



.cb-post-head-aen {
  font-size: 15px;
  color: gray;
}

.hide-users {
  position: fixed;
  bottom: 20px;
  width: 22.5%;
}

.gm-style-cc,
.gmnoprint a,
.gmnoprint div,
.gm-style a img {
  display: none !important;
}

.nameBox {
  font-size: 12px !important;
}


.set_width {
  max-width: 80% !important;
}


.chats-rb-div {
  position: relative;
}

.addLocation {
  max-width: 95%;
}

.addLocation .css-1fdsijx-ValueContainer,
.css-b62m3t-container {
  width: 300px !important;
}

.input-file-cf {
  font-size: 20px;
  list-style: none;
  gap: 20px;
}

.input-file-cf li:first-child {
  color: #23a34a;
}

.input-file-cf li:nth-child(2) {
  color: #63A4ED;
}

.input-file-cf li:nth-child(3) {
  color: #ff474c;
}

.input-file-cf li:nth-child(4) {
  color: #ffa500;
}

.input-file-cf span {
  font-size: 12px;
  color: black;
}


.story-img {
  height: 50px !important;
  width: 50px !important;
}

.story-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
}

.story-profile {
  left: 6px;
  top: -4px;
}

.story-name {
  font-size: 10px !important;
}

.cd_loader {
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.chat-cb-popover {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  margin: auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.media-img>img,
.media-video>video,
.media-file>object,
.media-audio>audio {
  height: 50px;
  max-width: 80px;

}

.media-file>object::-webkit-scrollbar {
  background-color: transparent;
}

.message-options-cb li {
  padding: 5px 20px !important;
  cursor: pointer;
  border-radius: 5px;
}

.message-options-cb li span {
  margin-left: 10px;
}

.message-options-cb li:hover {
  background-color: #63A4ED;
  color: snow;
}



.message-options {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2px;
}



.message-options li {
  padding: 5px !important;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px !important;

}

.message-options .option-del {
  color: #ff474c !important;
}

.message-options .option-del:hover {
  color: #fff !important;
  background-color: #ff474c;
}

.message-options li:hover {
  background-color: #63A4ED;
  color: snow;
}

.chat-popover {
  background-color: #f3f3f3;
}

.chats-rb-chat {
  height: 80vh !important;
}

.chats-rb-chat-t {
  /* border: 1px solid red; */
  height: 750px;
}

.chats-rb-chat-t-header {
  /* border: 1px solid green; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  background-color: white;
}

.chats-rb-chat-t-header-head {
  margin-right: 30%;
}

.chats-rb-chat-t-header-profile {
  cursor: pointer;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}



.chats-rb-chat-t-header-name {
  /* border: 1px solid black; */
  font-size: 20px;
  font-weight: 500;
  /* width: 600px; */
  cursor: pointer;
  position: relative;
  top: -10px;
}

.chat-online {
  position: absolute;
  top: 28px;
  color: #3cb043;
  font-size: 14px;
  left: 0px;
}

.chats-rb-chat-t-header-voice {
  /* border: 1px solid black; */
  padding: 5px 7px;
  background-color: #E8E8E8;
  border-radius: 10px;
  cursor: pointer;
}

.chats-rb-chat-t-header-voice img {
  margin: 0px 0px 3px 0px;
}

.chats-rb-chat-t-header-video {
  /* border: 1px solid black; */
  padding: 5px 7px;
  background-color: #E8E8E8;
  border-radius: 10px;
  cursor: pointer;
}

.chats-rb-chat-t-header-video img {
  margin: 0px 0px 5px 0px;
}

.chats-rb-chat-t-header-threeDot {
  cursor: pointer;
}

.chats-rb-chat-t-box {
  /* border: 1px solid red; */
  height: 688px;
  overflow-y: scroll;
  background-image: url('${BASE_URL_STATIC}/chat-bg.jpg');
  padding: 20px 50px;
}

.img-box {
  /* height: 276px !important; */
  object-fit: contain;
}

/* .img-box img {
max-width: 70% !important;
object-fit: contain;
} */

.onboardingImage-area video {
  width: 50%;
  background-color: black;
}


.no_bg {
  width: 80px !important;
  border-radius: 10px;
}

.no_bg img {
  border-radius: 10px;
}

.form-switch {
  position: relative;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.chats-rb-chat-t-box::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.chats-rb-chat-t-box-left {
  /* border: 1px solid black; */
  margin: 0px 0px 10px 0px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  max-width: 58%;
  right: -42%;
}

.chats-rb-chat-t-box-left-msg {
  position: relative;
  /* border: 1px solid black; */
  /* background-color: rgb(232, 232, 232); */
  min-width: 90px;
  background-color: #E8E8E8;
  padding: 5px 10px 5px 15px;
  /* display: flex; */
  /* align-items: flex-end; */
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
}

.emoji_reaction {
  display: none;
  position: absolute;
  left: -25px;
  top: 20%;
  cursor: pointer;
  opacity: 0.5;
}

.emoji_reaction_right {
  display: none;
  position: absolute;
  right: -8px;
  top: 20%;
  cursor: pointer;
  opacity: 0.5;
}

.show_em_right {
  padding-right: 20px;
}

.show_em:hover .emoji_reaction {
  display: block;
}

.show_em_right:hover .emoji_reaction_right {
  display: block;
}

.chats-rb-chat-t-box-right {
  /* border: 1px solid green; */
  margin: 0px 0px 10px 0px;
  cursor: pointer;
  width: fit-content;
  max-width: 58%;
  padding-right: 26px;
}

.chats-rb-chat-t-box-right-msg {
  /* border: 1px solid black; */
  position: relative;
  display: flex;
  /* flex-direction: column; */
  align-items: flex-end;
  background-color: #E8E8E8;
  padding: 5px 10px 5px 15px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
}

.chat-rb-msg-left {
  display: flex;
  flex-direction: column;
}

.chat-rb-msg-left>small {
  color: #63A4ED;
  font-size: 10px;
}

.chat-rb-msg-left>span {
  padding: 0px 10px 0px 0px;
  border-radius: 10px;
  font-size: 12px;
}

.chat-rb-msg-right {
  display: flex;
  flex-direction: column;
}

.chat-rb-msg-right>small {
  color: #858282;
  font-size: 10px;

}

.chat-rb-msg-right>span {
  padding: 0px 10px 0px 0px;
  border-radius: 10px;
  font-size: 12px;
}



.chats-rb-chat-t-box-left-msg .deliver-time,
.chats-rb-chat-t-box-right-msg .deliver-time {
  /* position: absolute; */
  color: #1b191969;
  bottom: 2px;
  right: 4px;
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
}

.deliver-time img {
  max-width: 14px;
}


.deliver-time time {
  color: rgba(0, 0, 0, 0.623);
  font-size: 10px ;
}

.deliver-time img {
  width: 28%;
}

.chat-rb-msg-left .reply-box,
.chat-rb-msg-left .reply-box-1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.chat-reply-content {
  display: flex;
}

.chat-reply-content .reply-box-1 {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 5px;

}

.chat-reply-content .reply-box-1 img {
  width: 50px;
  object-fit: contain;
}

.download_btn {
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #94a6f8;
  color: #5b75e9;
  margin-left: 10px;
  padding: 4px 12px;

}


.chats-rb-chat-t-footer {
  /* border: 1px solid pink; */
  padding: 15px 20px;
  box-shadow: 0px 0px 3px 0px gray;
  position: absolute;
  background-image: url(${BASE_URL_STATIC}/chat-bg.jpg);
  bottom: 30px;
  width: 100%;
  z-index: 5;
}

.chats-rb-chat-t-footer-area {
  position: relative;
  border: 1px solid #BABABA;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 30px;
  z-index: 6;
}

.typeStyle {
  position: absolute;
  background: #63A4ED;
  padding: 15px 20px;
  border-radius: 12px 12px 12px 0px;
  font-size: 14px;
  max-width: 38%;
  top: -70px;
  left: 10px;
}


.chats-rb-chat-t-footer-area .emoji-select {
  position: absolute;
  bottom: 70px;
  left: 0px;
}

.chats-rb-chat-t-footer-area-emoji {
  cursor: pointer;
}

/* .chats-rb-chat-t-footer-area-form {
  margin: 7px 0px 0px;
} */

/* .chats-rb-chat-t-footer-area-form input {
  width: 600px;
  background-color: transparent;
  font-size: 17px;
  border: none;
} */

.chats-rb-chat-t-footer-area-input {
  border: 1px solid black;
  width: 55%;
  background-color: transparent;
  font-size: 17px;
  border: none;
}

.chats-rb-chat-t-footer-area-camera {
  cursor: pointer;
}

.chats-rb-chat-t-footer-area-mic {
  /* border: 1px solid black; */
  padding: 5px 11px;
  border-radius: 50%;
  background-color: #64A6F0;
  cursor: pointer;
}

.chats-rb-chat-t-footer-area-mic img {
  margin: 0px 0px 3px 0px;
}

.chats-rb-chat-t-footer-area-send {
  /* border: 1px solid black; */
  padding: 10px;
  border-radius: 50%;
  background-color: #2676E1;
  margin: 3px 0px;
  cursor: pointer;
  outline: none;
  border: none;
}

.chat-reply {
  position: absolute;
  min-height: 50px;
  max-height: fit-content;
  width: 98%;
  background-color: #f4f4f4f5;
  border-radius: 15px;
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
  bottom: 58px;
  padding: 10px 40px 10px 10px;
  z-index: 2;
  box-shadow: 0px -25px 20px -125px rgba(0, 0, 0, 0.45),
    2px -30px 157px -46px rgba(0, 0, 0, 0.45);
  filter: brightness(0.95);
  background-image: url("${BASE_URL_STATIC}/chat-bg.jpg");
  border: 2px solid #8abbf3;
  border-bottom: none;
  -webkit-animation: chat-reply 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
  animation: chat-reply 0.6s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

@keyframes chat-reply {
  0% {
    -webkit-transform: rotateX(-70deg);
    transform: rotateX(-70deg);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    opacity: 1;
  }
}


.chat-media-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 15px;
  align-items: center;
  margin: 8px;
}

.media-div {
  position: relative;
}

.chat-senderFname {
  position: relative;
}

.fwd-symbol {
  position: absolute;
  right: 8px;
  font-size: 12px !important;
}

.media-div .close-media {
  position: absolute;
  font-size: 10px;
  padding: 1px 3px;
  border-radius: 50%;
  top: -10px;
  right: -10px;
  background-color: #e4e2e2;
  cursor: pointer;
}

.add-media {
  font-size: 20px;
  border: 2px solid #a19e9e;
  opacity: 0.5;
  padding: 2px 6px;
  border-radius: 50%;
}

.chat-reply-close {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
  opacity: 0.7;
}

.chats-rb-chat-f {
  /* border: 1px solid red; */
  height: 100%;
}

.chats-rb-chat-f-img {
  /* border: 1px solid green; */
  height: 100%;
  width: 100%;
}

.chats-rb-call-t-box {
  /* border: 1px solid red; */
  height: 808px;
  overflow-y: scroll;
  background-image: url('${BASE_URL_STATIC}/chat-bg.jpg');
  padding: 20px 50px;
}

.chats-rb-call-t-box::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.chats-rb-call-t {
  /* border: 1px solid red; */
  height: 876px;
}

.chats-rb-call-t-header {
  /* border: 1px solid green; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
}

.chats-rb-call-t-header-profile {
  cursor: pointer;
}

.chats-rb-call-t-header-name {
  /* border: 1px solid black; */
  font-size: 20px;
  font-weight: 500;
  width: 600px;
  cursor: pointer;
}

.chats-rb-call-t-header-voice {
  /* border: 1px solid black; */
  padding: 5px 7px;
  background-color: #E8E8E8;
  border-radius: 10px;
  cursor: pointer;
}

.chats-rb-call-t-header-voice img {
  margin: 0px 0px 3px 0px;
}

.chats-rb-call-t-header-video {
  /* border: 1px solid black; */
  padding: 5px 7px;
  background-color: #E8E8E8;
  border-radius: 10px;
  cursor: pointer;
}

.chats-rb-call-t-header-video img {
  margin: 0px 0px 5px 0px;
}

.chats-rb-call-t-header-threeDot {
  cursor: pointer;
}

.chats-rb-call-t-footer {
  /* border: 1px solid pink; */
  padding: 15px 20px;
  box-shadow: 0px 0px 3px 0px gray;
}

.chats-rb-call-t-footer-area {
  border: 1px solid #BABABA;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 30px;
}

.chats-rb-call-t-footer-area-emoji {
  cursor: pointer;
}

.chats-rb-call-t-footer-area-form {
  margin: 7px 0px 0px;
}

.chats-rb-call-t-footer-area-form input {
  width: 600px;
  background-color: transparent;
  font-size: 17px;
  border: none;
}

.chats-rb-call-t-footer-area-camera {
  cursor: pointer;
}

.chats-rb-call-t-footer-area-mic {
  /* border: 1px solid black; */
  padding: 5px 11px;
  border-radius: 50%;
  background-color: #64A6F0;
  cursor: pointer;
}

.chats-rb-call-t-footer-area-mic img {
  margin: 0px 0px 3px 0px;
}

.chats-rb-call-t-footer-area-send {
  /* border: 1px solid black; */
  padding: 10px;
  border-radius: 50%;
  background-color: #2676E1;
  margin: 3px 0px;
  cursor: pointer;
}

.canvas-groupMembers {
  /* border: 1px solid #000; */
  width: 83%;
  margin: 20px auto 0px;
}

.canvas-groupMember {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.canvas-groupMember-profile {
  border: 1px solid gainsboro;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.canvas-groupMember-profile img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.canvas-groupMember-name {
  margin: 0px 15px;
  width: 50%;
  /* background-color: red; */
  cursor: pointer;
  color: black;
  position: relative;
}

.canvas-groupMember-threeDot {
  cursor: pointer;
}

.canvas-groupMember-admin {
  position: absolute;
  right: 0px;
  color: white;
  background-color: #64a6f0;
  padding: 3px 13px;
  border-radius: 5px;
  margin: 2px 15px 0px 0px;
  font-size: 13px;
}

.jodit-wysiwyg {
  min-height: 400px !important;
}

.textpop-btn {
  padding: 5px 10px;
  background-color: #64A6F0;
  color: seashell;
  text-align: center;
  width: 30%;
  border-radius: 10px;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.fwd_msg_modal_body {
  max-height: 400px;
  overflow-y: auto;
}

.sticky-btn-container {
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 10px;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
}

.fwd-msg-btn {
  position: relative;
  width: 100%;
  background-color: #2c8ef0;
  border-radius: 50%;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  max-width: 50px;
}


.fwd_msg_modal_body::-webkit-scrollbar {
  width: 10px;
}

.fwd_msg_modal_body::-webkit-scrollbar-thumb {
  width: 20px;
  background-color: #d3d3d3;
}

.fwd-msg-btn {
  position: sticky;
  right: 0px;
  top: 0px;
  box-shadow: rgba(37, 142, 240, 0.35) 0px 5px 10px;
}

.h-80vh {
  height: 80vh;
}

.w-80vw {
  width: 80vw;
  margin: auto;
}


/* .canvas-groupMember-threeDot-popup {
  border: 1px solid #000;
} */

.canvas-groupMember-threeDot-popup span {
  /* border: 1px solid #000; */
  margin-bottom: 5px;
  font-size: 12px;
  cursor: pointer;
  display: block;
  padding: 2px 8px;
  border-radius: 5px;
}

.canvas-groupMember-threeDot-popup .group-del-leave {
  color: red;
}

.canvas-groupMember-threeDot-popup .group-del-leave:hover {
  background-color: rgb(253, 88, 88);
  color: snow;
}

.canvas-groupMember-threeDot-popup span:hover {
  /* border: 1px solid #000; */
  background-color: #63A4ED;
  color: snow;
}

.msg_modal .modal-content {
  background-color: rgba(0, 0, 0, 0.603) !important;
}

.btns {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 50%;
  left: 2%;
  width: 95%;
  margin: auto;
}

.btns .btn-prev,
.btns .btn-next {
  background-color: transparent;
  border: 2px solid snow I !important;
  border-radius: 50%;
  opacity: 0.9;
  padding: 3px 11px;
}

.msg_pop .img_box {
  max-width: 80% !important;
  margin: auto;
}


















/* ***** Notifications ***** */

.notifications-block {
  /* border: 1px solid black; */
  height: 90vh;
  overflow: hidden;
  overflow-y: scroll;
  /* margin: 75px 0px 0px; */
}

.nb-notifications {
  /* border: 1px solid red; */
  height: 100%;
  width: 40%;
  margin: 0px auto;
  overflow-y: scroll;
}

.nb-notifications::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.nb-n-nortification {
  /* border: 1px solid red; */
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
  padding: 5px;
  cursor: pointer;
}

.nb-n-nortification:hover {
  background-color: rgb(250, 249, 249);
  box-shadow: 0px 0px 3px 0px rgb(146, 146, 146);
}

.nb-n-nortification-profile {
  /* border: 1px solid black; */
  height: 50px;
  border-radius: 50%;
  margin: 0px 10px 0px 0px;
}

.nb-n-nortification-profile-t {
  /* border: 1px solid black; */
  border-radius: 50%;
  margin: 0px 0px 0px 5px;
  filter: blur(5px);
}

.nb-n-nortification-header {
  /* border: 1px solid black; */
  width: 63%;
}

.nb-n-nortification-name {
  margin: 0px 10px 0px 0px;
  font-size: 17px;
  font-weight: 700;
  color: black;
}

.nb-n-nortification-image {
  /* border: 1px solid black; */
  height: 50px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nb-n-nortification-image img {
  /* border: 1px solid black; */
  height: 50px;
  width: 50px;
}

.oldNotification {
  padding: 0px 10px;
  border-radius: 10px;
}

.oldNotification:hover {
  background-color: #d9e1eb;
  transition: 0.5s;
  cursor: pointer;
}

.oldNotification-spinner {
  margin-top: 60%;
  text-align: center;
}

.notification_head {
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.notification_head::-webkit-scrollbar-thumb {
  background-color: #84b4eb;
  border-radius: 10px;
}

.notification_head::-webkit-scrollbar {
  width: 10px;
}

.notification-spinner {
  width: 100%;
  text-align: center;
}

/* .like-notification {
  display: none;
} */





/* ***** Notifications > Name Popup ***** */

.notificationNamePopup {
  /* border: 1px solid black; */
  max-height: 80vh;
}

.nnp-users {
  /* border: 1px solid red; */
  padding: 0px 10px;
  max-height: 80vh;
  overflow-y: scroll;
}

.nnp-users::-webkit-scrollbar {
  width: 0px;
}

.nnp-user {
  /* border: 1px solid green; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.nnp-user-profile {
  border-radius: 50%;
  height: 45px;
  width: 45px;
}

.nnp-user-profile img {
  border-radius: 50%;
}

.nnp-user-header {
  /* border: 1px solid black; */
  width: 57%;
  display: flex;
  flex-direction: column;
}

.nnp-user-name {
  font-size: 17px;
  font-weight: 700;
  cursor: pointer;
  color: black;
}

.nnp-user-username {
  cursor: pointer;
  color: black;
}


.nnp-user-btn {
  color: white;
  background-color: #64a6f0;
  border-radius: 7px;
  cursor: pointer;
}




/* ***** Notifications > Stop ***** */

.notifications-stop {
  width: 25%;
  margin: 400px auto 0px;
  text-align: center;
}

.notifications-stop img {
  margin-bottom: 10px;
}

.notifications-stop span {
  cursor: pointer;
  color: #2676E1;
  font-weight: 600;
}










.commentLoader {
  width: 50px;
  --b: 8px;
  margin-top: 30%;
  margin-left: 45%;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#f3f3f3 10%, #64A6F0) content-box;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg),
    radial-gradient(farthest-side, #0000 calc(100% - var(--b) - 1px), #000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: l4 1s infinite steps(10);
}

@keyframes l4 {
  to {
    transform: rotate(1turn)
  }
}



/* ***** Follower > Popup ***** */

.pfp-search {
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}


.pfp-search-form {
  display: flex;
  align-items: center;
  width: 85%;
  border-radius: 30px;
  padding: 0px 10px;
  background-color: #f6f6f6;
}

.pfp-search-img {
  padding: 7px 10px;
}

.pfp-search-form label {
  margin: 0px;
}

.pfp-search-form input {
  height: 40px;
  width: 157%;
  /* margin: 7px 0px 0px; */
  background-color: transparent;
  border: none;
  padding: 0px 7px;
}

.pfp-search-filter {
  color: #2676E1;
  cursor: pointer;
}

.create-group-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  margin-left: 50px;
  height: 50px;
}




.reaction_picker {
  position: absolute;
  right: 10%;
  z-index: 1000;
}

a {
  text-decoration: none !important;
}

.reaction_picker_right {
  position: absolute;
  left: 10%;
  top: 0px;
  z-index: 1000;
}

.reactions {
  position: relative;
  display: flex;
  justify-content: flex-end;
  right: 0px;
  top: -15px;
  padding: 2px;
  font-size: 12px;
  border-radius: 20px;
  margin-left: 80%;
  cursor: pointer;
}

.reactions_right {
  margin-bottom: 5px;
  padding: 2px;
  font-size: 12px;
  border-radius: 20px;
  z-index: 20;
  cursor: pointer;
}

.reaction_count {
  padding: 0px 4px;
  opacity: 0.5;
  font-size: 10px;
  border-radius: 20px;
  background-color: #0B71F6;
  color: white;
}

.reaction_popup {
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}




/* ***** Profile > Share ***** */

.profile-share {
  /* border: 1px solid black; */
  margin-bottom: 100px;
}

.ps-qrCode-img {
  box-shadow: 0px 0px 5px rgb(209, 209, 209);
  padding: 10px;
  border-radius: 10px;
}

.ps-sml {
  display: flex;
  justify-content: center;
}

.ps-sml-div {
  border: 1px solid #F2F2F2;
  padding: 10px 10px;
  border-radius: 50%;
  margin: 0px 10px;
  cursor: pointer;
}

.ps-sml-img {
  height: 25px;
  width: 25px;
}

.profile-share-copy-input {
  height: 50px;
  width: 500px;
  border-radius: 30px;
  border: none;
  padding: 10px 30px 10px 20px;
  font-size: 16px;
  box-shadow: gray 2px 2px 5px;
}



/* ***** Follow Unfollow Button ***** */

.followUnfollowBtn {
  font-size: 10px;
  height: 25px;
  width: 60px;
  color: #fff;
  border-radius: 7px;
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: #64A6F0;
  align-items: center;
  transition: all;
}



.followUnfollowBtn2{
  font-size: 10px;
  height: 25px;
  width: 60px;
  color: #fff;
  border-radius: 7px;
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: #64A6F0;
  align-items: center;
  transition: all;

}
.followUnfollowBtn3{
  font-size: 10px;
  height: 25px;
  width: 60px;
  color: #000000;
  border-radius: 7px;
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: #b0c0c4cc;
  align-items: center;
  transition: all;

}




.followUnfollowBtn_flix {
  color: snow;
  border: 1px solid snow;
  border-radius: 30px;
  padding: 5px 10px;
  background-color: transparent;
}

.flix_details {
  justify-content: flex-end;
}

.flix_details .profile_flix {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  overflow: hidden;
}

.flix_details .profile_flix img {
  height: 100%;
  width: 100%;
  object-fit: fill;
}







/* ***** Voice Call Going Popup ***** */

/* .voiceCallPopup-modal-body {
  border: 1px solid red;
} */

.voiceCallPopup {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.voiceCallPopup-logo img {
  height: 30px;
  margin: 0px 0px 50px;
}

.voiceCallPopup-profile {
  border-radius: 50%;
  box-shadow: 0px 0px 5px 1px gray;
}

.voiceCallPopup-profile-img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.voiceCallPopup-btns {
  height: 100px;
  width: 100%;
  margin: 75px 0px 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.voiceCallPopup-mute {
  padding: 20px 23px;
  cursor: pointer;
  background-color: gray;
  border-radius: 50%;
  box-shadow: 0px 0px 3px 0px gray;
}

.voiceCallPopup-mute img {
  height: 25px;
}

.voiceCallPopup-unmute {
  padding: 20px 17px;
  cursor: pointer;
  background-color: gray;
  border-radius: 50%;
  box-shadow: 0px 0px 3px 0px gray;
}

.voiceCallPopup-unmute img {
  height: 25px;
}

.voiceCallPopup-cut {
  padding: 13px;
  cursor: pointer;
  background-color: #FF6969;
  border-radius: 50%;
  box-shadow: 0px 0px 3px 0px gray;
}

.voiceCallPopup-cut img {
  height: 40px;
}





/* ***** Voice Call Coming Popup ***** */

.voiceCallComingPopup {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.voiceCallComingPopup-logo img {
  height: 30px;
  margin: 0px 0px 50px;
}

.voiceCallComingPopup-profile {
  border-radius: 50%;
  box-shadow: 0px 0px 5px 1px gray;
}

.voiceCallComingPopup-profile-img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.voiceCallComingPopup-btns {
  height: 100px;
  width: 100%;
  margin: 75px 0px 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.voiceCallComingPopup-attend {
  padding: 22px;
  cursor: pointer;
  background-color: #00B5AA;
  border-radius: 50%;
  box-shadow: 0px 0px 3px 0px gray;
}

.voiceCallComingPopup-attend img {
  height: 25px;
}

.voiceCallComingPopup-cut {
  padding: 15px;
  cursor: pointer;
  background-color: #FF6969;
  border-radius: 50%;
  box-shadow: 0px 0px 3px 0px gray;
}

.voiceCallComingPopup-cut img {
  height: 40px;
}








/* ***** Video Call Going Popup ***** */

.videoCallGoingPopup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vcgp-logo img {
  height: 30px;
  margin: 0px 0px 50px;
}

.vcgp-person {
  height: 350px;
  width: 700px;
  display: flex;
}

.vcgp-person-1 {
  width: 50%;
}

.vcgp-person-1-video {
  height: 100%;
  width: 100%;
  background-color: #2B2B2B;
  border-radius: 0px;
  border-right: 5px solid white;
}

.vcgp-person-2 {
  width: 50%;
}

.vcgp-person-2-video {
  height: 100%;
  width: 100%;
  background-color: #2B2B2B;
  border-radius: 0px;
  border-left: 5px solid white;
}

.vcgp-btns {
  width: 40%;
  margin: 50px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.vcgp-btn-video {
  height: 60px;
  width: 60px;
  background-color: #454545;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vcgp-btn-video img {
  height: 27px;
  width: 27px;
}

.vcgp-btn-cut {
  height: 60px;
  width: 60px;
  background-color: #FF6969;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vcgp-btn-mute {
  height: 60px;
  width: 60px;
  background-color: gray;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vcgp-btn-mute img {
  height: 30px;
  width: 30px;
}





/* ***** Video Call Coming Popup ***** */

.videoCallComingPopup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vccp-logo img {
  height: 30px;
  margin: 0px 0px 50px;
}

.vccp-person {
  height: 350px;
  width: 700px;
  display: flex;
}

.vccp-person-1 {
  width: 50%;
  position: relative;
}

.vccp-person-1-image {
  height: 100%;
  width: 100%;
  border-radius: 0px;
  border-right: 1px solid white;
}

.vccp-person-1-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.vccp-person-1-name {
  position: absolute;
  bottom: 3%;
  left: 40%;
  color: white;
  background-color: #454545;
  padding: 5px 10px;
  border-radius: 30px;
  font-size: 13px;
}

.vccp-person-2 {
  width: 50%;
}

.vccp-person-2-video {
  height: 100%;
  width: 100%;
  background-color: #2B2B2B;
  border-radius: 0px;
  border-left: 1px solid white;
}

.vccp-btns {
  width: 30%;
  margin: 50px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vccp-btn-attend {
  height: 60px;
  width: 60px;
  background-color: #00B5AA;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 3px 0px gray;
}

.vccp-btn-cut {
  height: 60px;
  width: 60px;
  background-color: #FF6969;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 3px 0px gray;
}

.vccp-btn-mute img {
  height: 30px;
  width: 30px;
}





/* ***** Edit Post Popup *****  */

.editPostPopup-head {
  text-align: center;
  margin: 0px 0px 50px;
}

.editPostPopup-form input {
  width: 400px;
  margin: 0px 20px;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid gray;
}

.editPostPopup-form textarea {
  margin: 0px 20px;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid gray;
  resize: none;
}

.editPostPopup-btn {
  color: white;
  background-color: #64A6F0;
  padding: 7px 75px;
  border-radius: 30px;
  margin: 10px 0px 0px 125px;
  cursor: pointer;
}





/* ***** Edit Reel Popup *****  */

.editReelPopup-head {
  text-align: center;
  margin: 0px 0px 50px;
}

.editReelPopup-form input {
  width: 400px;
  margin: 0px 20px;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid gray;
}

.editReelPopup-form textarea {
  margin: 0px 20px;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid gray;
  resize: none;
}

.editReelPopup-btn {
  color: white;
  background-color: #64A6F0;
  padding: 7px 75px;
  border-radius: 30px;
  margin: 10px 0px 0px 125px;
  cursor: pointer;
}





/* ***** Edit BestClip Popup *****  */

.editBestClipPopup-head {
  text-align: center;
  margin: 0px 0px 50px;
}

.editBestClipPopup-form input {
  width: 400px;
  margin: 0px 20px;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid gray;
}

.editBestClipPopup-form textarea {
  margin: 0px 20px;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid gray;
  resize: none;
}

.editBestClipPopup-btn {
  color: white;
  background-color: #64A6F0;
  padding: 7px 75px;
  border-radius: 30px;
  margin: 10px 0px 0px 125px;
  cursor: pointer;
}





/* ***** Edit Meme Popup *****  */

.editMemePopup-head {
  text-align: center;
  margin: 0px 0px 50px;
}

.editMemePopup-form input {
  width: 400px;
  margin: 0px 20px;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid gray;
}

.editMemePopup-form textarea {
  margin: 0px 20px;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid gray;
  resize: none;
}

.editMemePopup-btn {
  color: white;
  background-color: #64A6F0;
  padding: 7px 75px;
  border-radius: 30px;
  margin: 10px 0px 0px 125px;
  cursor: pointer;
}











/* ***** Visibility Post Popup *****  */

.visibilityPostPopup-form input {
  margin: 0px 10px 20px 70px;
  cursor: pointer;
}

.visibilityPostPopup-form label {
  font-size: 17px;
  cursor: pointer;
}

.visibilityPostPopup-form hr {
  margin: 0px;
}

.visibilityPostPopup-done {
  display: block;
  width: 50%;
  margin: 10px auto 0px;
  text-align: center;
  color: white;
  background-color: #64A6F0;
  border-radius: 10px;
  cursor: pointer;
  padding: 5px 0px;
}






/* ***** Visibility Reel Popup *****  */

.visibilityReelPopup-form input {
  margin: 0px 10px 20px 70px;
  cursor: pointer;
}

.visibilityReelPopup-form label {
  font-size: 17px;
  cursor: pointer;
}

.visibilityReelPopup-form hr {
  margin: 0px;
}




/* ***** Visibility BestClip Popup *****  */

.visibilityBestClipPopup-form input {
  margin: 0px 10px 20px 70px;
  cursor: pointer;
}

.visibilityBestClipPopup-form label {
  font-size: 17px;
  cursor: pointer;
}

.visibilityBestClipPopup-form hr {
  margin: 0px;
}




/* ***** Visibility Meme Popup *****  */

.visibilityMemePopup-form input {
  margin: 0px 10px 20px 70px;
  cursor: pointer;
}

.visibilityMemePopup-form label {
  font-size: 17px;
  cursor: pointer;
}

.visibilityMemePopup-form hr {
  margin: 0px;
}








/* ***** Invite Friend Via Email Popup *****  */

/* .inviteFriendViaEmailPopup {
  border: 1px solid black;
} */

.ifvep-head {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-size: 20px;
}

.ifvep-form label {
  margin: 0px;
}

.ifvep-form input {
  border: 1px solid rgb(215, 215, 215);
  height: 30px;
  width: 330px;
  padding: 15px 10px;
  border-radius: 10px;
}

.ifvep-add {
  color: white;
  background-color: #64A6F0;
  padding: 4px 20px;
  border-radius: 10px;
  margin: 0px 0px 0px 20px;
  cursor: pointer;
}

.ifvep-show {
  border: 1px solid rgb(215, 215, 215);
  padding: 5px;
  margin: 10px 0px;
  border-radius: 10px;
}

.ifvep-show-email {
  position: relative;
  display: inline;
}

.ifvep-show-email-name {
  margin: 5px;
  padding: 3px 7px;
  border-radius: 15px;
  color: white;
  background-color: #64A6F0;
}

.ifvep-selected-remove {
  position: absolute;
  top: -10px;
  right: 0px;
  cursor: pointer;
}

.ifvep-send {
  color: white;
  background-color: #64A6F0;
  padding: 7px 75px;
  margin: 10px 0px 0px 125px;
  cursor: pointer;
  border-radius: 30px;
}

.ifvep-send-disabled {
  color: white;
  background-color: #7db1eb;
  padding: 7px 75px;
  margin: 10px 0px 0px 125px;
  cursor: not-allowed;
  border-radius: 30px;
}









/* -------------------- After Google Popup -------------------- */

.afterGooglePopup-head {
  text-align: center;
}

.afterGooglePopup-form-gender {
  display: flex;
  justify-content: space-evenly;
  margin: 20px 0px;
}

.afterGooglePopup-form-gender span {
  border: 1px solid gray;
  color: gray;
  padding: 5px 9px;
  cursor: pointer;
  border-radius: 20px;
}

.afterGooglePopup-form-gender span:hover {
  border: 1px solid #C3E9FF;
  background-color: #2676E1;
  color: white;
}

.afterGooglePopup-form-gender span label {
  margin: 0px 7px;
  cursor: pointer;
}


.afterGooglePopup-form-dob {
  text-align: center;
  margin: 30px;
}

.afterGooglePopup-form-dob input {
  border: 1px solid #d3d3d3;
  padding: 5px 10px;
  opacity: 0.8;
  border-radius: 20px;
  cursor: pointer;
  width: 84%;
}

.afterGooglePopup-form-dob input::placeholder {
  opacity: 0.5 !important;
}

.afterGooglePopup-form-mobile {
  /* border: 1px solid gray; */
  /* padding: 5px 10px; */
  border-radius: 10px;
  width: 90%;
  margin: auto;
}

.mobile_label {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.afterGooglePopup-form-sendOtp {
  background-color: #64A6F0;
  cursor: pointer;
  color: #fff;
  padding: 10px 10px;
  border-radius: 20px;

}

.profile-user-3-profile {
  position: relative;
}


.afterGooglePopup-pin {
  height: 40px;
  width: 45px !important;
  border: 1px solid #EBEBEB;
  border-radius: 15px;
  font-size: 25px;
  font-weight: 250;
  text-align: center;
  color: gray;
  margin-bottom: 10px;
}

.afterGooglePopup-form-pin {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 0px 20px;
}

.afterGooglePopup-form-done {
  border: 1px solid black;
  padding: 7px 100px;
  color: white;
  background-color: #64A6F0;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  margin: 0px 0px 0px 100px;
}

.reply_box_reply {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  font-size: 10px !important;
  gap: 5px;
  filter: brightness(0.9);
  box-shadow: rgba(179, 179, 235, 0.25) 0px 30px 60px -12px inset, rgba(87, 83, 83, 0.3) 0px 18px 36px -18px inset;
}

.reply_box_reply img {
  object-fit: cover;
}









/* ---------- Profile > Setting > Close Friends ---------- */

.closeFriend {
  height: 870px;
  margin-top: 70px;
}

.closeFriend-head {
  text-align: center;
  margin: 20px 0px 30px;
  font-weight: 900;
}

.closeFriend-users {
  height: 90%;
  width: 80%;
  margin: 0px auto;
  overflow-y: scroll;
}

.closeFriend-users::-webkit-scrollbar {
  width: 0px;
}

.closeFriend-user {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.closeFriend-user-profile {
  height: 7%;
  width: 7%;
  border-radius: 50%;
  margin-right: 15px;
  cursor: pointer;
}

.closeFriend-user-head {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.closeFriend-user-name {
  font-size: 18px;
  font-weight: 550;
  color: #4B4B4B;
  cursor: pointer;
}

.closeFriend-user-username {
  color: #848484;
  cursor: pointer;
}

.closeFriend-user-remove {
  padding: 5px 15px;
  color: white;
  background-color: #64A6F0;
  border-radius: 7px;
  cursor: pointer;
}

.closeFriend-zero {
  height: 90%;
  width: 80%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.closeFriend-zero-img {
  height: 150px;
  margin-bottom: 20px;
  opacity: 0.3;
}

.closeFriend-zero-msg {
  color: #848484;
}






/* ---------- Profile > Setting > Close Friends ---------- */

.favouritePeople {
  height: 870px;
  margin-top: 70px;
}

.favouritePeople-head {
  text-align: center;
  margin: 20px 0px 30px;
  font-weight: 900;
}

.favouritePeople-users {
  height: 90%;
  width: 80%;
  margin: 0px auto;
  overflow-y: scroll;
}

.favouritePeople-users::-webkit-scrollbar {
  width: 0px;
}

.favouritePeople-user {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.favouritePeople-user-profile {
  height: 7%;
  width: 7%;
  border-radius: 50%;
  margin-right: 15px;
  cursor: pointer;
}

.favouritePeople-user-head {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.favouritePeople-user-name {
  font-size: 18px;
  font-weight: 550;
  color: #4B4B4B;
  cursor: pointer;
}

.favouritePeople-user-username {
  color: #848484;
  cursor: pointer;
}

.favouritePeople-user-remove {
  padding: 5px 15px;
  color: white;
  background-color: #64A6F0;
  border-radius: 7px;
  cursor: pointer;
}

.favouritePeople-zero {
  height: 90%;
  width: 80%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.favouritePeople-zero-img {
  height: 150px;
  margin-bottom: 20px;
  opacity: 0.3;
}

.favouritePeople-zero-msg {
  color: #848484;
}





/* ---------- Profile > Setting > Block ---------- */

.blockedUsers {
  height: 870px;
  margin-top: 70px;
}

.blockedUsers-head {
  text-align: center;
  margin: 20px 0px 30px;
  font-weight: 900;
}

.blockedUsers-users {
  height: 90%;
  width: 80%;
  margin: 0px auto;
  overflow-y: scroll;
}

.blockedUsers-users::-webkit-scrollbar {
  width: 0px;
}

.blockedUsers-user {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.blockedUsers-user-profile {
  height: 7%;
  width: 7%;
  border-radius: 50%;
  margin-right: 15px;
  cursor: pointer;
}

.blockedUsers-user-head {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.blockedUsers-user-name {
  font-size: 18px;
  font-weight: 550;
  color: #4B4B4B;
  cursor: pointer;
}

.blockedUsers-user-username {
  color: #848484;
  cursor: pointer;
}

.blockedUsers-user-remove {
  padding: 5px 15px;
  color: white;
  background-color: #64A6F0;
  border-radius: 7px;
  cursor: pointer;
}

.blockedUsers-zero {
  height: 90%;
  width: 80%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blockedUsers-zero-img {
  height: 150px;
  margin-bottom: 20px;
  opacity: 0.3;
}

.blockedUsers-zero-msg {
  color: #848484;
}











/* ---------- Report Post ---------- */

.reportPostPopup-head {
  text-align: center;
}

.reportPostPopup-textarea {
  border: 1px solid gray;
  height: 200px;
  width: 100%;
  margin: 10px 0px;
  border-radius: 10px;
  padding: 10px;
  font-size: 20px;
}

.reportPostPopup-report {
  padding: 7px 75px;
  color: white;
  background-color: #64A6F0;
  margin-left: 30%;
  border-radius: 30px;
  cursor: pointer;
}



/* ---------- Report Service Post ---------- */

.servicePostPopup-head {
  text-align: center;
}

.servicePostPopup-textarea {
  border: 1px solid gray;
  height: 200px;
  width: 100%;
  margin: 10px 0px;
  border-radius: 10px;
  padding: 10px;
  font-size: 20px;
}

.servicePostPopup-report {
  padding: 7px 75px;
  color: white;
  background-color: #64A6F0;
  margin-left: 23%;
  border-radius: 30px;
  cursor: pointer;
}

.fav_movie_1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  background-color: #e7e7e7;
  font-size: 14px !important;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 10px;
  margin-top: 20px;
}

.fav_movie_2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.fav_movie_2 input {
  width: 80%;
  border: none;
  padding: 0px 10px;
  border-bottom: 1px solid #d3d3d3;
}

.fav_movie_2 textarea {
  width: 80%;
  padding: 10px;
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  resize: none;
}

.fav_movie_btn {
  background-color: #3C3C3C;
  border: 1px solid #3C3C3C;
  font-size: 15px;
  line-height: 30px;
  border-radius: 6px;
  width: 30%;
  margin-top: 20px;
  color: snow;
}







/* ----------  Terms And Conditions ---------- */

.termsAndConditions {
  height: 100vh;
  padding: 10px;
  overflow-y: scroll;
}

/* .termsAndConditions::-webkit-scrollbar {
  width: 0px;
} */

.termsAndConditions-header {
  text-align: center;
  margin-bottom: 10px;
}

.termsAndConditions-msg {
  margin-bottom: 25px;
}

/* HTML: <div class="loader"></div> */
.loaderMain {
  width: 30px;
  aspect-ratio: 1/1;
  display: grid;
  animation: l14 4s infinite;
  opacity: 0.6;
}

.loaderMain::before,
.loaderMain::after {
  content: "";
  grid-area: 1/1;
  border: 4px solid;
  border-radius: 50%;
  border-color: red red #0000 #0000;
  mix-blend-mode: darken;
  animation: l14 1s infinite linear;
}

.loaderMain::after {
  border-color: #0000 #0000 blue blue;
  animation-direction: reverse;
}

@keyframes l14 {
  100% {
    transform: rotate(1turn)
  }
}

.scrollbar-hidden::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}

.cursor-pointer {
  cursor: pointer;
}

.wobble-hor-bottom {
  animation: wobble-hor-bottom 0.8s infinite;
}

@keyframes wobble-hor-bottom {

  0%,
  100% {
    transform: translateX(0%);
  }

  25% {
    transform: rotate(-30deg);
  }

  75% {
    transform: rotate(30deg);
  }

}

/* HTML: <div class="loader"></div> */
.ping {
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  /* background: #000; */
  box-shadow: 0 0 0 0 #2b2929;
  animation: ping-ani 1s infinite;
}

@keyframes ping-ani {
  100% {
    box-shadow: 0 0 0 8px #db4b4b00
  }
}

.cp {
  cursor: pointer;
}

.cd {
  cursor: default;
}

.chatusers {
  position: absolute;
  background-color: #fbfbfb;
  padding: 5px 10px;
  border-radius: 5px;
  bottom: 50px;
  box-shadow: rgba(141, 210, 241, 0.35) 4px 7px 4px;
}

.SC-right-Chat-L {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.SC-right-Chat-R {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.SCRply {
  position: absolute;
  top: -51px;
  display: flex;
  flex-direction: row-reverse;
  width: 96%;
  padding: 11px;
  background-color: #fafafa;
  border-top: 3px solid #ffa8b0;
  border-left: 3px solid #ffa8b0;
  border-right: 3px solid #ffa8b0;
  justify-content: space-between;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  z-index: -1;
  align-items: center;
}

.font14 {
  font-size: 14px;
}

.font18 {
  font-size: 18px;
}

.borderSec {
  border: 1px solid #f4f4f4;
}



.chart-container {
  position: relative;
  height: 150px;
  background: linear-gradient(to top, white, rgba(0, 0, 0, 0));
  overflow: hidden;
}