.username {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.username section:first-child img {
  margin-left: -30px;
}

.usernameForm {
  position: relative;
  /* width: 50%; */
  margin: auto;
}

.usernameForm>img {
  margin: 0px 0px 100px 110px;
}

.e01, .e02, .e03 {
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
}


.usernameForm>h6 {
  margin-bottom: 15px;
}

.usernameForm div {
  /* position: relative; */
}

/* .usernameForm>div>p {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.input_head{
  width: 100%;
  margin: auto;
  /* background-color: red; */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.usernameForm>div>p>input,
.PhoneInputInput {
  /* margin-bottom: 30px; */
  padding: 26px 20px;
  border-radius: 30px;
}

.input_head>input::placeholder{
  font-size: small;
}

.usernameForm>button {
  width: 100%;
  padding: 15px 0px;
  border-radius: 30px;
  margin-top: 40px;
}

.emiloLogo-username {
  margin: 50px;
  display: flex;
  justify-content: center;
}

.signupProfilePic {
  width: 50%;
  margin: 10px auto 20px auto;
}

.signupProfilePic .profile_img {
  position: relative;
  /* background-color: #d3d3d3; */
  height: 250px;
  width: 250px;
  margin: auto;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

.form-grp {
  position: relative;
}

.form-grp .check {
  position: absolute;
  right: 0px;
  left: 90%;
  bottom: 10px;
}


.signupProfilePic .profile_img img {
  height: 250px;
  width: 250px;
  border-radius: 50%;
  object-fit: contain;
  object-fit: cover;
}


.signupProfilePic .fa-camera {
  position: absolute;
  font-size: 25px;
  bottom: 0px;
  right: 40px;
  background-color: #e9e9e9;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.signupProfilePic>div>span {
  height: 80px;
  width: 80px;
  margin: 5px;
}


.signupProfilePic>div>span>img {
  border-radius: 15px;
  cursor: pointer;
}

.signupProfilePic h6 {
  margin-bottom: 15px;
}

.genderReg {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 11px;

}

.genFormGroup,
.gendRadioBtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0px;
  border-radius: 30px;

}

.require {
  position: absolute;
  color: rgb(190, 7, 7);
  left: 60px;
  top: -5px;
}

.mark {
  position: absolute;
  color: red;
  background-color: transparent;
  font-size: 14px;
  top: -20px;
  left: 10px;
}

.usernamespan {
  position: absolute;
  bottom: 15px;
  left: 20px;
  display: flex;
}

.PhoneInput {
  position: relative;

}

.PhoneInput .PhoneInputCountry {
  position: absolute;
  bottom: 10px;
  left: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.PhoneInputInput {
  border: 1px solid #d3d3d3;
  width: 100%;
  padding: 16px 90px 16px 100px;
  font-size: 13px;
}

.PhoneInputCountrySelect,
.PhoneInputCountryIcon {
  width: 20px;
  border: none;
  margin: 0px 5px;
}

.verticalLine {
  position: absolute;
  background-color: #d3d3d3;
  height: 40px;
  width: 2px;
  top: 10px;
  right: -100px;
}

.sendotp {
  position: absolute;
  right: 20px;
  bottom: 65px;
  color: #64a6f0;
  cursor: pointer;
}

.code-verify {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.code-verify img {
  margin-bottom: 100px;
}

.code-verify button {
  width: 30%;
  padding: 15px 0px;
  border-radius: 30px;
  margin-top: 40px;
}

.resendOTP {
  margin-top: 30px;
  font-size: 14px;
}

.resendOTP b {
  color: #2676e1;
}

.registration {
  height: fit-content;
  width: fit-content;
  border-radius: 15px;
  margin: 20px auto;
  color: rgb(63, 64, 65);
  padding: 20px;
  background-color: #d3d3d3;
  background-image: linear-gradient(315deg, #d3d3d3 0%, #7bed9f 74%);
}

.flexRadioBtn,
.dobForm input {
  /* border: 1px solid rgba(128, 128, 128, 0.418); */
  padding: 7px 11px;
  /* border-radius: 30px; */

}

.usernameForm> .div_logo{
  width: 100%;
  margin: auto;
  text-align: center;
}

.div_logo>h2{
  margin: 32px 0px;
}

.usernameForm> div > img {
width: 100%;

}

.flexRadioBtn label {
  padding: 0px 0px 0px 10px;
  margin: 0px;
  cursor: pointer;
  color: rgba(128, 128, 128, 0.904);
}

.flexRadioBtn input {
  padding: 0px 10px;
  cursor: pointer;
}

.dobForm {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  padding: 0px 21px;
}

.dobForm .dateOfBirth {
  padding: 30px 20px;
  width: 368px;
  border-radius: 30px;
}








/* ***** Login ***** */

.login_right {
  /* border: 1px solid black; */
  /* height: 75vh; */
}

/* .login_right input {
  border-radius: 30px;
  font-size: 20px;
  padding: 15px 25px;
} */

.login_right_section_eye {
  position: absolute;
  cursor: pointer;
  top: 33%;
  right: 7%;
}

.form_check input {
  padding: 0;
  margin: 5px;
}



/* ***** Signup ***** */

/* ***** Signup > Name Registraion ***** */


.nameReg {
  width: 100vw;
}

.nameReg-left {
  height: 100vh;
  width: 100vw;
}

.nameReg-left img {
  height: 100vh;
  width: 100vw;
}

.nameReg-input {
  min-width: 300px;
  max-width: 450px;
}

.emilo-login-input {
  border-radius: 30px;
}




/* ***** Email And Mobile ***** */

.emailAndMobile {
  height: 100vh;
  width: 100vw;
  padding: 0px;
}

.emailAndMobile-lb {
  height: 100vh;
  width: 100vw;
}

.eam-rb-email {
  height: 50px;
  border-radius: 30px;
  margin: 30px 0px 0px;
  padding: 30px;
}

.input_fp::placeholder{
  font-weight: 400;
  opacity: 0.8;

}

.eam-rb-phone {
  min-width: 300px;
  max-width: 450px;
  width: 100%;
  margin: auto;
}


.eam-rb-phone-otp,.eam-rb-mobile-otp-verified {
  position: absolute;
  right: 27%;
  bottom: 27%;
  font-size: 12px;
}

.eam-rb-mobile-otp-verified {
  color: #31b95c;
}

.eam-rb-email-otp,.eam-rb-email-otp-verified {
  position: absolute;
  right: 27%;
  top: 20px;
  font-size: 12px;
}

.eam-rb-email-otp-verified {
  color: #31b95c;
}






/* ***** Create Password ***** */

.createPassword {
  /* border: 1px solid black; */
  height: 100vh;
  padding: 0px;
}

.createPassword-lb {
  height: 100vh;
  text-align: center;
}

.createPassword-lb img {
  height: 100vh;
}

.cp-rb-header {
  margin: 0px 0px 15px 0px;
}

.cp-rb-ul {
  list-style-type: disc;
  margin: 0px 0px 20px 20px;
}

.password_info{
  width: 50%;
    text-align: start;
    margin: auto;
    font-size: 12px;
}

/* .cp-rb-input {
  height: 50px;
  margin: 30px 0px;
  border-radius: 30px;
  padding: 20px;
} */

.usernameForm>div>span {
  width: 0px;
}

.show1,.show2 {
  right: 28%;
  position: absolute;
  top: 25%;
  cursor: pointer;
}

.show_pass{
  position: absolute;
  right: 10%;
  opacity: 0.7;
  top: 20%;
}

.emilo-login-logo {
  width: 25%;
  margin: auto;
}

.show2{
  top: 25%;
}

.cp-rb-eye1,
.cp-rb-eye2,
.cp-rb-eye3 {
  position: absolute;
  top: 20%;
  right: 5%;
  opacity: 0.7;
}

/* .cp-rb-eye2 {
  border: 1px solid black;
  top: 71%;

}

.cp-rb-eye3 {
  border: 1px solid black;
  top: 83%;
} */

.e0_name{
position: relative;
}

.e01,
.e03,
.e02 {
  width: 100% !important;
  position: absolute;
  font-size: 11px;
  bottom: -20px;
  left: 2%;
  color: red;
  width: 180px;
  opacity: 0.8;
}









/* ***** Code Verification ***** */

.codeVerification {
  /* border: 1px solid black; */
  height: 100vh;
  width: 100vw;
  padding: 0px;
}

.codeVerification-lb {
  /* border: 1px solid red; */
  height: 100vh;
  width: 100vw;
}

.codeVerification-lb img {
  /* border: 1px solid red; */
  height: 100vh;
  width: 100vw;
}









/* ***** User Name ***** */

.userName {
  height: 100vh;
  width: 100vw;
  padding: 0px;
}

.userName-lb {
  height: 100vh;
  width: 100vw;
}

.un-rb-input {
  padding: 25px;
  border-radius: 30px;
  font-size: 15px;
}

.error_message{
  width: 100%;
  margin: auto;
  text-align: center;
  font-size: 12px;
}

.usernameForm .check {
  position: absolute;
  right: 11px;
  top: 10px;
}

.forgot-otp {
  width: 100%;
  margin: auto;
  text-align: center;
  margin-top: 40px;

}

.forgot-otp span {
  color: #64A6F0;
}

.forgot-otp b {
  color: #2d84e7;
}

.forgotpass-code-otp {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.forgotpass-select {
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  padding: 20px;
  margin: auto;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  align-items: flex-start;
  cursor: pointer;
}

.forgotpass-select img {
  height: 40px;
  width: 25px;
}

.forgotpass-select div {
  display: flex;
  flex-direction: column;
}

.forgotpass-select div span:nth-child(1) {
  color: #a19f9f;
}

.forgotpass-select div span:nth-child(2) {
  font-size: 18px;
  font-weight: 600;
}

.select-color,
.select-color span {
  background-color: #79B5F8;
  color: snow !important;
}