.reel4body {
  /* position: fixed; */
  /* height: 1000px;
  overflow-y: scroll;
  width: 70%;
  margin: auto; */
}

.custom-vertical-carousel {
  /* display: flex;
  justify-content: center;
  align-items: center;
  height: 99%;
  margin: 64px 0px 50px;
  width: 100%; */
}

.carousel {
  display: flex;
  flex-direction: column;
  width: 430px;
}

.feedBox>video {
  height: 700px;
  width: 92%;
  border-radius: 10px;
  display: flex;
  margin: auto;
}

.videoBox {
  height: 68%;
  width: 62%;
  margin: auto;
  margin-left: 100px;
}


.carouselImg {
  width: 91%;
  height: 697px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  background-color: black;
  border-radius: 20px;
  filter: brightness(95%);
}

.carouselImg:hover {
  filter: brightness(50%);
  cursor: pointer;
}

.carousel:hover .content-area {
  display: block;
}


.carousel-control {
  background: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 16px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.carousel-control.next {
  right: 0;
}

.carousel-control.prev {
  left: 0;
}

.leftArea {
  position: absolute;
  top: 46%;
  left: 25px;
  /* z-index: -1; */
  display: grid;
  grid-template-columns: auto;
  grid-gap: 35px;
  gap: 20px;
}

.leftArea span {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leftArea span img {
  cursor: pointer;
}

.content-area {
  z-index: 0;
  display: none;
  position: absolute;
  bottom: 115px;
  color: snow;
  left: -22px;
  padding: 0px 10px;
}

.content-area>div {
  display: flex;
  justify-content: space-between;
  padding: 0px 40px;
  align-items: center;
}

.carousel-slide {
  position: relative;
}

.content-area button {
  padding: 4px 8px;
  background-color: transparent;
  border-radius: 40px;
  font-size: 10px;
  border: 1px solid snow;
  color: snow;
  opacity: 0.8;
}

.reelsVideo {
  height: 692.5px;
  width: 100%;
  background-color: black;
  border-radius: 10px;
  margin: 100px auto 50px auto;
  display: flex;
  justify-content: center;
}

.personDetails {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.personDetails span {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 0px 5px;
}

.imgArea {
  width: 50px;
  background-color: snow;
  padding: 2px;
  border-radius: 100%;
  overflow: hidden;
}

.imgArea img {
  width: 50px;
  border-radius: 100%;
  object-fit: cover;
}

.artistArea {
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.nameBox>p {
  font-size: 15px;
  color: snow;
}

.artistArea>small>i {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.col-lg-6 {
  padding: 0;
}

/* @media only screen and (max-width: 768px) {
  .carouselImg {
    width: 100%;
    height: 520px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    background-color: black;
    border-radius: 20px;
    filter: brightness(95%);
  }

  .leftArea {
    display: flex;
    position: initial;
    align-items: center;
    gap: 38px;
    justify-content: center;
  }

  .content-area {
    z-index: 0;
    position: absolute;
    bottom: 220px;
    color: snow;
    left: -37px;
    padding: 0px 10px;
  }

  .personDetails span {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0px;
  }

  .carousel {
    display: flex;
    flex-direction: column;
    width: 400px;
  }
} */

.profileReelVideo {
  background-color: black;
  border-radius: 10px;
  height: 36%;
  width: 35%;
  object-fit: contain;
}

.reel-section {
  height: 100vh;
  width: 72vw;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  display: flex;
  justify-content: center;
}


.carouselSlider .main {
  position: relative;
  /* height: 80vh; */
  /* margin-top: 80px; */
  scroll-snap-align: start;
  display: grid;
  place-items: center;
  width: 390px;
}

.reel ::-webkit-scrollbar {
  background-color: transparent;
}

.carouselSlider .main:hover .reel-details {
  display: block;
}

.carouselSlider .main video:hover {
  transition: .3s ease-in;
  filter: brightness(0.5);
}

.carouselSlider .main:hover .leftArea {
  filter: brightness(1);
  z-index: 100;
}


.reel-details {
  /* display: none; */
  width: 90% !important;
  position: absolute;
  bottom: 50px;
  right: 10px;
  padding: 20px;
}


.reel-details-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: rgba(240, 248, 255, 0.733);
  gap: 15px;
  font-size: 12px;
}

.reel-details-name img {
  height: 30px;
  width: 30px;
  border-radius: 50%;

}

.reel-details-desp {
  color: rgba(240, 248, 255, 0.733);
  font-size: 12px;
  text-align: start;
}