.output {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.output>img {
  height: 40px;
  cursor: pointer;
  width: 50px;
}

.container>span {
  font-size: small;
  text-align: center;
  width: 100%;
}

.shadowe {
  border: 2px solid;
  border-image: linear-gradient(to right, red, blue);
  border-image-slice: 1;
  border-radius: 10px;
  box-shadow: 0px -23px 25px 0px rgba(0, 0, 0, 0.17) inset, 0px -36px 30px 0px rgba(0, 0, 0, 0.15) inset, 0px -79px 40px 0px rgba(0, 0, 0, 0.1) inset, 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09);
}