@media only screen and (min-width: 576px) {
  /* ---------- Login ---------- */

  .login-logo {
    height: 40px;
  }

  .continue-right-select {
    width: 50%;
  }





  /* ---------- Notifications ---------- */

  .notifications {
    /* border: 1px solid red; */
    height: 80vh;
    overflow-y: scroll;
    width: 75%;
    margin-inline: auto;
  }

  .oldNotification-follow-1-img {
    height: 40px;
    width: 40px;
  }

  .oldNotification-like-1-profile {
    height: 40px;
    width: 40px;
  }

  .oldNotification-comment-1-profile {
    height: 40px;
    width: 40px;
  }


  .pu-p-icon {
    left: 60%;
    bottom: 5%;
  }



  /* ---------- Profile > Follower > Popup ---------- */

  .pfp-profile {
    height: 35px;
    width: 35px;
  }

  .pfp-name {
    font-size: 15px;
  }



  /* ---------- Chat ---------- */

  .ch-vc-icon {
    height: 35px;
    width: 35px;
  }







  /* ---------- Suggested Flix ---------- */

  .suggested-flix-img {
    height: 125px;
  }



/*----------- check-in Popup----------- */

.current_location{
  height: 52px;
  width: 20px;
}

.place_ht{
  height: 18px;
}





  /* ----------- Softcorner > Center Block > User Card ----------- */

  .sc-cb-user-cards {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
    margin-inline: 20px;
  }





  /* ----------- Softcorner > Notifications ----------- */

  .sc-notifications {
    width: 90%;
  }



  /* ----------- Softcorner > Gift ----------- */

  .sc-products {
    grid-template-columns: auto auto auto;
    gap: 15px;
  }

  .productSlider .slick-dots li {
    height: 10vh;
  }

  .product-image-selected {
    height: 35vh;
  }

  .product-image-option {
    height: 10vh;
  }


  /* ---------- Explore ---------- */

  .explore-sec {
    grid-template-columns: auto auto;
    padding-inline: 20px;
  }


  /* ----------- Business > Home ----------- */

  .business-banner-slider .slick-dots li {
    height: 33px;
    width: 33px;
  }

  .business-banner-profile {
    top: -40px;
    left: 25px;
  }

  .business-banner-profile-img {
    height: 100px;
    width: 100px;
  }

  .business-home-tagged {
    grid-template-columns: auto auto auto;
  }

  .business-home-reviews {
    grid-template-columns: auto auto;
  }

  .business-home-product-service-slick-other .slick-slide {
    padding-right: 13px;
  }








  /* ----------- Business > Account Setting ----------- */

  .as-gs-pr-users {  /* Account Setting > General Setting > Page Roles > users  */
    grid-template-columns: auto auto;
  }




  /* ----------- Business > Dashboard ----------- */

  .dashboard-overview-slider .slick-track {
    gap: 10px;
  }




  /* ----------- Business > Ads > Create Post ----------- */

  .business-create-post {
    grid-template-columns: auto auto;
    gap: 20px;
  }





  /* ----------- Business > Insights ----------- */

  .insights-page-overview {
    grid-template-columns: auto auto;
    gap: 20px;
  }

  .business-insights-posts {
    grid-template-columns: auto auto;
    gap: 20px;
  }






  /* ----------- Business > Events ----------- */

  .business-events {
    grid-template-columns: auto auto;
    gap: 20px;
  }
  


  




  


  
}

