@media only screen and (min-width: 992px) {
  /* ---------- Login ---------- */

  .login-logo {
    height: 50px;
  }


  /* ---------- Home ---------- */

  /* ---------- Home > Left Block ---------- */

  .lb-ffp {
    width: 70px;
  }

  /* ---------- Home > Center Block ---------- */

  .cb-p-name {
    font-size: 20px;
  }

  .cb-p-afn {
    font-size: 15px;
  }

  .cb-p-location {
    font-size: 15px;
  }



  /* ---------- Notifications ---------- */

  .notifications {
    /* border: 1px solid red; */
    height: 90vh;
    overflow-y: scroll;
    width: 50%;
    margin: 75px auto 0px;
  }

  .oldNotification-follow-1-img {
    height: 45px;
    width: 45px;
  }

  .oldNotification-like-1-profile {
    height: 45px;
    width: 45px;
  }

  .oldNotification-like-1-post {
    height: 45px;
    width: 45px;
  }

  .oldNotification-comment-1-profile {
    height: 45px;
    width: 45px;
  }

  .oldNotification-comment-1-post {
    height: 45px;
    width: 45px;
  }



  /* ---------- Profile ---------- */

  /* .profile {
    margin-top: 125px;
  } */

  .pu-p-img {
    height: 150px;
    width: 150px;
  }

  .pu-p-icon {
    left: 67%;
    bottom: 5%;
  }

  .pu-name {
    font-size: 27px;
  }

  .pn-img {
    height: 30px;
    width: 30px;
  }

  .pn-text {
    font-size: 15px;
  }


  /* ---------- Profile > Share ---------- */

  .ps-form-div {
    width: 25%;
  }


  /* ---------- Profile > Follower > Popup ---------- */

  .pfp-profile {
    height: 40px;
    width: 40px;
  }

  .pfp-name {
    font-size: 17px;
  }







  /* ---------- Chat ---------- */

  /* .chat {
    margin-top: 75px;
  } */

  .ch-profile {
    height: 40px;
    width: 40px;
  }

  .chat-rb-box {
    height: 73vh;
  }

  .call-rb-box-call-img {
    height: 27px;
    width: 27px;
    padding: 7px;
  }

  .online-status {
    height: 9px;
    width: 9px;
    left: -23px;
    top: 3px;
    border-radius: 50%;
    position: absolute;
    background-color: #a6ddaa;
  }

  .chat-header {
    top: 75px;
  }

  .chat-box {
    padding-top: 150px;
  }

  .selected-user {
    margin: 5px 0px;
    padding: 10px 5px !important;
    transition: 0.3s ease-in;
    background-color: #f3f1f1;
    border-radius: 15px;
    box-shadow: #93c0f3 0px 3px 8px !important;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important; */
  }

  .chat-footer {
    width: 43.7%;
  }






  /* ---------- Profile > Setting  ---------- */

  .ps-profile {
    height: 100px;
    width: 100px;
  }


  /* ---------- Suggested Flix ---------- */

  .suggested-flix-img {
    height: 125px;
  }






  /* ----------- Softcorner > Navbar ----------- */

  .sc-nav-logo {
    width: 115px;
  }



  /* ----------- Softcorner > Left Block ----------- */

  .sc-lb-div-1 {
    width: 90%;
  }

  .sc-lb-div-3 {
    width: 70%;
  }




  /* ----------- Softcorner > Center Block > User Card ----------- */

  .sc-cb-user-cards {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
    margin-inline: 20px;
  }



  /* ----------- Softcorner > Profile ----------- */

  .selected-sc {
    /* transition: 0.3s ease-in; */
    color: #ffffffd8 !important;
    /* background: transparent linear-gradient(90deg, #2676E1 0%, #2676E1 71%, #2676E19A 87%, #2676E100 100%) 0% 0% no-repeat padding-box; */
    background: transparent linear-gradient(90deg, #2676E100 0%, #2676E19A 15%, #2676E1 32%, #2676E1 71%, #2676E19A 87%, #2676E100 100%) 0% 0% no-repeat padding-box;
  }






  /* ----------- Softcorner > Notifications ----------- */

  .sc-notifications {
    width: 70%;
  }





  /* ----------- Softcorner > Gift ----------- */

  .sc-products {
    grid-template-columns: auto auto auto;
  }

  .product-detail {
    height: 100%;
  }

  
  .product-image-selected {
    height: 50vh;
  }




  /* ----------- Softcorner > Chat ----------- */

  .sc-chat-header {
    top: 73px;
    width: 75%;
  }

  .sc-chat-box {
    padding-top: 140px;
  }

  .sc-chat-footer {
    width: 75%;
  }








  /* ----------- Business > Basic Info ----------- */

  .business-basicInfo-lb {
    width: 50%;
  }

  .business-basicInfo-rb {
    width: 50%;
  }


  /* ----------- Business > Home ----------- */

  .business-lb {
    width: 30%;
    display: block;
  }

  .business-rb {
    width: 70%;
  }

  .business-home-photos {
    grid-template-columns: auto auto;
  }

  .business-home-tagged {
    grid-template-columns: auto auto auto;
  }

  .business-home-reviews {
    grid-template-columns: auto auto;
  }






  /* ----------- Business > Ads > Create Post ----------- */

  .business-create-post {
    grid-template-columns: auto auto;
    gap: 20px;
  }





  /* ----------- Business > Insights ----------- */

  .insights-page-overview {
    grid-template-columns: auto auto;
    gap: 20px;
  }








}
