  .card{
    background-color: #fff;
    border:none !important;
  }
  
  .input-box{
    position: relative;
  }
  
  .input-box i {
    position: absolute;
    right: 13px;
    top:15px;
    color:#ced4da;
  }

  .list-body{
    position: absolute;
    background-color: rgb(253, 253, 253);
    width: 100%;
    right: 0px;
    top:60px;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    padding: 0px 10px;
  }
  
  .form-control{
    height: 50px;
    background-color:#eeeeee69;
  }
  
  .form-control:focus{
    background-color: #eeeeee69;
    box-shadow: none;
    border-color: #eee;
  }
  
  
  .list{
    padding-top: 20px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .border-bottom{
    border-bottom: 2px solid #eee;
  }
  
  .list i{
    font-size: 19px;
    color: red;
  }
  
  .list small{
    color:#837e7e;
  }