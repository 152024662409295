.reel4Body{
display: flex;
justify-content: space-around;
align-items: flex-start;
gap: 20px;
}

.input ,button {
  border: 2px solid #e4f6f8;
  border-radius: 5px;
  outline: none;
padding: 5px 10px;  
margin: 5px;
}


.select{
  width: fit-content;
}

select{
  font-size: 5px;
  border:0px;
  border-bottom: 2px solid #a6a6a659;
  margin-top: 12px;
  padding: 10px;
}

.input::placeholder {
  color: #999; 
  font-style: italic; 
  font-size: 14px;
}

.inputList{
  width: 250px;
  background-color: #f3f3f3;
  padding:10px;
}

.inputList>ul{
display: flex;
flex-flow: wrap;
gap: 10px;
}

.inputList>ul>li{
font-size: 12px;
border: 1px solid gray;
border-radius: 5px;
padding: 5px;
color: #a7a7a7 ;
}

.reel4section textarea {
  border-radius: 15px;
  width: 100%;
  padding: 10px 20px;
resize: none;
border:2px solid rgba(128, 128, 128, 0.459);

}